import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type { ModelFor } from 're-client/utils/route-model'
import type StorylandsLessonRoute from 're-client/routes/storylands/lesson'
import type RouterService from '@ember/routing/router-service'
import type StorylandsService from 're-client/services/storylands'

/**
 * The lesson index route
 *
 * @class LessonIndexRoute
 */
export default class StorylandsLessonIndexRoute extends Route {
  @service
  declare router: RouterService

  @service
  declare storylands: StorylandsService

  override beforeModel() {
    const lesson = this.modelFor(
      'storylands.lesson',
    ) as ModelFor<StorylandsLessonRoute>

    if (lesson) {
      const lessonId = lesson.id
      const activityId = this.storylands.currentActivityForLesson(lessonId)

      this.router.transitionTo(
        'storylands.lesson.activity',
        lessonId,
        activityId,
      )
    }
  }
}
