import { action } from '@ember/object'
import { underscore } from '@ember/string'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'

export default class DrivingTestsIndexController extends Controller {
  @service
  declare router: RouterService

  @action
  startQuiz(quizType: string, quizId: string) {
    const type = underscore(quizType)
    this.router.transitionTo('driving-tests.quiz', type, quizId)
  }
}
