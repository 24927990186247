import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type LocationTrackerService from 're-client/services/location-tracker'
import type UrlMakerService from 're-client/services/url-maker'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'

export default class PlayIndexRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare locationTracker: LocationTrackerService

  override async model() {
    const variables = { student: this.user.student }
    const manifests = [this.urlMaker.urlForInteractive('reading', 'playroom')]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }

  override afterModel() {
    this.locationTracker.setCurrentRoute('play', [])
  }
}
