import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { toDateString } from 're-client/utils/format-date'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type ThemeService from 're-client/services/theme'
import type WeeklyPollService from 're-client/services/weekly-poll'
import type CaperLoaderService from 're-client/services/caper-loader'

interface RouteModel {
  interactiveConfig: unknown
}

interface RouteParams {
  day?: string | undefined
}

export default class PlazaRoute extends Route<RouteModel, RouteParams> {
  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare theme: ThemeService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare weeklyPoll: WeeklyPollService

  override queryParams = {
    day: { refreshModel: true },
  }

  day = null

  override async model({ day }: RouteParams) {
    const theme = this.theme.currentTheme

    const urls = [
      this.urlMaker.urlForInteractive('reading', `plaza_${theme}`),
      this.urlMaker.urlForInteractive('avatar'),
    ]

    // Pass in date object to change the current week of the poll
    const currentDate = day ? new Date(day) : new Date()
    const date = toDateString(currentDate)
    const weeklyPollData = await this.weeklyPoll.getWeeklyPoll({
      date,
    })

    // In the past, we may've locked the Plaza Shop based on the student's progress, but this
    // no longer happens. As such, these values are no longer computed - they're static values
    // that always unlock the shop.
    // Note that the variable name `areasUnlocked` clashes with the boolean values, so the fact
    // that unlocked `false` causes an area to be unlocked in confusing.
    // A rename was avoided since this variable name is depended-upon by the plaza Caper activity,
    // and any refactoring would need to take place there first.
    const areasUnlocked = {
      avatarShop: false,
      houseShop: false,
      bookOfTheDay: false,
    }

    // add the variables data which we will add to the interactive
    const variables = {
      student: this.user.student,
      areasUnlocked,
      weeklyPollData,
      currentDate,
    }

    const interactiveConfig = await this.caperLoader.load(urls, variables)

    return {
      interactiveConfig,
    }
  }
}
