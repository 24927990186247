import { inject as service } from '@ember/service'
import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'

export default class HouseRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare store: Store

  override async model() {
    const items = await this.store.findAll('item', { reload: true })
    const studentItems = items.map((item) => ({
      id: item.id,
      code: item.code,
      department: item.department,
      state: item.state,
      imageUrl: item.imageUrl,
      animationUrl: item.animationUrl,
      animationJson: item.animationJson,
    }))

    const variables = { student: this.user.student, studentItems }

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'my_house'),
      this.caperLoader.avatarJsonRequest(),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
