import config from 're-client/config/environment'

/**
 * Returns the accurate spelling based on the configuration. It is possible for the
 * current map to be 13 (backend will return an increment).
 * This will correct the number to be 12 as this is the maximum possible for spelling.
 *
 * @export
 * @param {number} currentMap the student's current spelling map number
 * @returns {Number}
 */
export function correctSpellingMap(currentMap) {
  const lastSpellingMap = config.studentProgress.progress.spelling.lastMap
  if (currentMap > lastSpellingMap) return lastSpellingMap
  return currentMap
}

/**
 * Determines whether a student is on the final lessons map
 *
 * @export
 * @param {number} currentMap the student's current map number
 * @param {number} [lastMap=config.APP.lastMap] the app's final lesson map
 * @returns {Boolean}
 */
export function isOnFinalLessonsMap(currentMap, lastMap = config.APP.lastMap) {
  return parseInt(currentMap, 10) === lastMap
}

/**
 * Returns the id of the previous map
 *
 * @export
 * @param {number} mapId a map id integer
 * @param {number} [lastMap=config.APP.lastMap] the app's final lesson map
 * @returns {number}
 *
 * This function ensures the returned id is within the valid range for
 * lesson maps. It's always higher than zero, and never higher than
 * the `lastMap` param.
 */
export function previousLessonMapId(mapId, lastMap = config.APP.lastMap) {
  mapId = parseInt(mapId, 10)

  if (mapId > 1) {
    // Ensures the previous map is always within a valid range
    return Math.min(mapId - 1, lastMap)
  }
  return 1
}

/**
 * Calculate the map which a lesson belongs to
 *
 * @param {Number | String} lesson
 * @param {Number} lessonsPerMap
 * @returns {Number}
 */
export function calculateMap(lesson, lessonsPerMap) {
  const lessonId = Math.max(1, lesson)
  return Math.ceil(lessonId / lessonsPerMap)
}
