import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import type StoryFactoryService from 're-client/services/story-factory'
import type { Entry } from 're-client/services/story-factory'

export default class StoryFactoryFloorController extends Controller {
  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  @service
  declare router: RouterService

  @service
  declare storyFactory: StoryFactoryService

  @action
  saveStoryEntry(entry: Entry) {
    void this.storyFactory.saveStoryEntry(entry)
  }

  @action
  async loadEntry(id: string) {
    const data = await this.storyFactory.loadEntry(id)
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  async newEntry() {
    const data = await this.storyFactory.newEntry()
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  async loadContest(id: string) {
    const data = await this.storyFactory.loadContest(id)
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  returnToLobby() {
    void this.router.transitionTo('story-factory')
  }

  @action
  enterShowRoom() {
    void this.router.transitionTo('story-factory.showroom')
  }

  @action
  returnToHouse() {
    void this.router.transitionTo('house')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'story-factory/floor': StoryFactoryFloorController
  }
}
