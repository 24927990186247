import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/puzzles/puzzle-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/puzzles/puzzle-game.hbs"}});
import Component from '@glimmer/component'

export default class PuzzleGame extends Component {
  /**
   * Puzzle has completed and returned a score
   * @param {Number} score The student's score from this attempt
   */
  saveScore(score) {
    this.args.saveScoreAction(score)
  }

  /**
   * Activity has signalled to go back to the lobby
   */
  goBackToLobby() {
    this.args.goBackToLobbyAction()
  }
}
