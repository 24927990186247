/* eslint-disable ember/no-jquery */
import { assert } from '@ember/debug'
import { modifier } from 'ember-modifier'
import $ from 'jquery'

export default modifier(function sidebarActivityListScroll(
  element,
  [currentActivityId]: [number],
) {
  scrollToItem(currentActivityId, element)

  const scrollUp = () => {
    scrollToItem(itemClosestToCenterOfList(element) - 1, element)
  }
  const scrollDown = () => {
    scrollToItem(itemClosestToCenterOfList(element) + 1, element)
  }

  document.addEventListener('activity-sidebar-up', scrollUp)
  document.addEventListener('activity-sidebar-down', scrollDown)

  return () => {
    document.removeEventListener('activity-sidebar-up', scrollUp)
    document.removeEventListener('activity-sidebar-down', scrollDown)
  }
})

function outerHeightWithMargin(element: Element) {
  const height = element.getBoundingClientRect().height
  const style = getComputedStyle(element)
  const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom)

  return height + margin
}

function scrollToItem(id: number, element: Element) {
  const list = $(element).find('ul')
  list.stop()
  const listHeight = list.height()
  const item = element.querySelector(`[data-item="${id.toString()}"]`)

  if (!item || !listHeight) return
  const itemHeight = outerHeightWithMargin(item)
  const offset = Math.max((id - 1) * itemHeight, 0)
  const scrollTop = offset - listHeight / 2 + itemHeight / 2

  list.animate({ scrollTop }, 400) // TODO: find a replacement for jQuery animate!
}

function itemClosestToCenterOfList(element: Element): number {
  const listItems = element.querySelectorAll('ul li')
  const listRect = element
    .querySelector('ul')
    ?.parentElement?.getBoundingClientRect()

  assert('list must exist', listRect)
  assert('list must contain items', listItems[0])

  const itemHeight = Math.round(listItems[0].getBoundingClientRect().height)

  const listHeight = listRect.height

  const centerOfList = Math.round(listHeight / 2 + listRect.top)

  const padding = 10

  const topOfRange = centerOfList - itemHeight - padding
  const bottomOfRange = centerOfList + itemHeight + padding

  const centerItem = [...listItems].find((item) => {
    const rect = item.getBoundingClientRect()
    const top = Math.round(rect.top)
    const bottom = Math.round(rect.bottom)

    return top >= topOfRange && bottom <= bottomOfRange
  })

  if (!centerItem) throw new Error('cant find center item')
  const itemId = centerItem.attributes.getNamedItem('data-item')?.value
  if (!itemId) throw new Error('item missing data-item attribute')
  return parseInt(itemId, 10)
}
