import { action, set } from '@ember/object'
import Controller from '@ember/controller'

export default class SampleLessonActivityController extends Controller {
  // Set to true if window.history.back() has been called once already, to avoid repeated calls
  isReturning = false

  @action
  next() {
    if (!this.isReturning) {
      set(this, 'isReturning', true)
      window.history.back()
    }
  }

  @action
  incrementScore() {}

  @action
  saveProgress() {
    /*
     * Our sample lessons are designed to, once completed, return the user to
     * the previous page in their browser history. Sample lessons are
     * accessed via country-specific marketing sites like readingeggs.cn,
     * readingeggs.com.au, etc, or the RE parent dashboard Sample Lessons
     * page.
     */
    if (!this.isReturning) {
      set(this, 'isReturning', true)
      window.history.back()
    }
    return Promise.resolve()
  }
}
