import { assert } from '@ember/debug'
import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'
import config from 're-client/config/environment'
import { getMonth, isValidDate } from 're-client/utils/format-date'

/**
 * @class ThemeService
 */
export default class ThemeService extends Service {
  @tracked date: Date | null = null

  /**
   * returns the currently set date
   */
  get now() {
    if (this.date === null)
      assert('Please use `setDate` to set the current date')
    return this.date
  }

  /**
   * svgjar works with id names. These names correlate to the file names within the public directory
   * It does not matter if these files are in sub directories.
   */
  get bannerName() {
    return `${this.currentTheme}-month`
  }

  /**
   * All themes based on the environment config mapped to an array of objects
   */
  themes = config.themesByMonth.map((theme, index) => ({
    value: index.toFixed(),
    label: `${getMonth(index)} - ${theme}`,
  }))

  /**
   * Returns the current theme object
   * @type {{month: String, name: String, date: String}}
   */
  get currentThemeObject() {
    return this.themes[this.currentMonth]
  }

  /**
   * Return the name of a them which can be used for selecting the correct images or selecting items for the
   * monthly bundle.
   * @property {String}
   * @type {String}
   */
  get currentTheme() {
    return config.themesByMonth[this.currentMonth] ?? ''
  }

  /**
   * 0 index based month number
   * @property {Number}
   * @type {Number} - returns a number between 0 - 11
   */
  get currentMonth() {
    return this.now.getMonth()
  }

  /**
   * Returns an object of hours and days until the next month.
   * This can be useful for the content team to display on the catalogue for how many days / hours remaining
   *
   * @returns {{hours: *, days: *}}
   */
  timeToNextTheme() {
    // Clone the current date in `this.now`, and increment it to the start of next month.
    // The timezone will remain the same as the cloned date
    const startOfNextMonth = new Date(this.now.getTime())
    startOfNextMonth.setMonth(startOfNextMonth.getMonth() + 1)
    startOfNextMonth.setDate(1)
    startOfNextMonth.setHours(0)
    startOfNextMonth.setMinutes(0)
    startOfNextMonth.setSeconds(0)
    startOfNextMonth.setMilliseconds(0)

    const millisecondsToNextMonth =
      startOfNextMonth.getTime() - this.now.getTime()
    const hours = millisecondsToNextMonth / 1000 / 60 / 60
    const days = hours / 24

    return { days, hours }
  }

  /**
   * Sets the date which is the basis for all theme selection
   */
  setDate(date: Date) {
    assert('Please make sure the passed in date is valid', isValidDate(date))
    // To maintain parity with the legacy moment-based qa_time query param,
    // we reset the date to the start of the local day. Moment does this implicitly,
    // native JS Dates do not.
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.date = date
  }
}
