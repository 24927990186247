import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import config from 're-client/config/environment'
import type { Log } from '@blakeelearning/log'
import type { MinigameAuthService } from '@blakeelearning/minigame-auth'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import ky from 'ky'

const { apiEndpoint, apiNamespace } = config.APP

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class GamesIndexController extends Controller {
  @service
  declare log: Log

  @service
  declare minigameAuth: MinigameAuthService

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @action
  async purchaseGamePlay(gameId: string) {
    try {
      const response = await ky
        .post(`arcade_games/${gameId}/purchase`, {
          credentials: 'include',
          prefixUrl,
        })
        .json<{ purchased: boolean }>()
      const hasPurchasedGamePlay = response.purchased

      if (hasPurchasedGamePlay) {
        this.minigameAuth.enableGameNamed(gameId)

        await this.user.fetch()

        void this.router.transitionTo('games.game', gameId)
      }
    } catch (error) {
      this.log.error(error as Error)
    }
  }
}
