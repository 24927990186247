import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import type SessionService from 're-client/services/session'
import config from 're-client/config/environment'

const {
  APP: { loginWarning },
} = config

interface ModelForRoute {
  warning: string | undefined
}

export default class LoginRoute extends Route<ModelForRoute> {
  @service
  declare session: SessionService

  override model() {
    return {
      warning: loginWarning,
    }
  }

  /**
   * This will be called by Ember after the model has been resolved.
   */
  override redirect(): void {
    this.session.signIn()
  }
}
