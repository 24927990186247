import { WeekId } from 're-client/utils/weekly-poll/week-id'
import type { CaperPoll } from 're-client/services/weekly-poll'

export function fixedResponseFirstWeekVoted(): CaperPoll {
  const id = new WeekId(1)
  return {
    studentVotedToday: true,
    studentVotedAnswer: 1,
    results: [
      {
        id: id.current(),
        totalVotesCount: 111,
        votedAnswersCount: [1, 2, 3, 4],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [100, 200, 300, 400],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [200, 300, 400, 500],
      },
    ],
  }
}

export function fixedResponseLastWeekVoted(): CaperPoll {
  const id = new WeekId(48)
  return {
    studentVotedToday: true,
    studentVotedAnswer: 2,
    results: [
      {
        id: id.current(),
        totalVotesCount: 111,
        votedAnswersCount: [1, 2, 3, 4],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [100, 200, 300, 400],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [200, 300, 400, 500],
      },
    ],
  }
}

export function fixedResponseNotVoted(): CaperPoll {
  const id = new WeekId(5)
  return {
    studentVotedToday: false,
    studentVotedAnswer: null,
    results: [
      {
        id: id.current(),
        totalVotesCount: 111,
        votedAnswersCount: [1, 2, 3, 4],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [100, 200, 300, 400],
      },
      {
        id: id.prev(),
        totalVotesCount: 111,
        votedAnswersCount: [200, 300, 400, 500],
      },
    ],
  }
}

export function fixedResponseZeroVotes(): CaperPoll {
  const id = new WeekId(5)
  return {
    studentVotedToday: false,
    studentVotedAnswer: null,
    results: [
      { id: id.current(), totalVotesCount: 0, votedAnswersCount: [0, 0, 0, 0] },
      { id: id.prev(), totalVotesCount: 0, votedAnswersCount: [0, 0, 0, 0] },
      { id: id.prev(), totalVotesCount: 0, votedAnswersCount: [0, 0, 0, 0] },
    ],
  }
}

export function fixedResponseNoPreviousWeekData(): CaperPoll {
  const id = new WeekId(5)
  return {
    studentVotedToday: false,
    studentVotedAnswer: null,
    results: [
      {
        id: id.current(),
        totalVotesCount: 10,
        votedAnswersCount: [1, 2, 3, 4],
      },
      { id: id.prev(), totalVotesCount: 0, votedAnswersCount: [0, 0, 0, 0] },
      { id: id.prev(), totalVotesCount: 0, votedAnswersCount: [0, 0, 0, 0] },
    ],
  }
}

export const fixtureFunctions = {
  fixedResponseZeroVotes,
  fixedResponseNotVoted,
  fixedResponseFirstWeekVoted,
  fixedResponseLastWeekVoted,
  fixedResponseNoPreviousWeekData,
}

export type FixtureFunctionKey = keyof typeof fixtureFunctions
