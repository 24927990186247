import { bind } from 'bind-event-listener'
import { modifier } from 'ember-modifier'

interface WindowResizeSignature {
  Element: HTMLElement
  Args: {
    Positional: [callback: (element: HTMLElement) => void]
  }
}

export default modifier<WindowResizeSignature>(function windowResize(
  element,
  [callback],
) {
  const debounced = debounce(callback)

  debounced(element)

  const unbind = bind(window, {
    type: 'resize',
    listener() {
      debounced(element)
    },
  })

  return () => {
    debounced.cancel()
    unbind()
  }
})

function debounce<T extends (...args: never[]) => void>(callback: T) {
  let timeout: number | undefined

  function cancel() {
    if (typeof timeout === 'number') {
      window.cancelAnimationFrame(timeout)
    }
    timeout = undefined
  }

  const debounced = (...args: Parameters<T>) => {
    cancel()

    timeout = window.requestAnimationFrame(() => {
      callback(...args)
    })
  }

  return Object.assign(debounced, { cancel })
}
