import { service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { debugAction } from 're-client/utils/debug'

export default class StoryFactoryIndexController extends Controller {
  @service
  declare router: RouterService

  @action
  @debugAction()
  enterFactoryFloor() {
    void this.router.transitionTo('story-factory.floor')
  }

  @action
  @debugAction()
  enterShowRoom() {
    void this.router.transitionTo('story-factory.showroom')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'story-factory/index': StoryFactoryIndexController
  }
}
