import { assert } from '@ember/debug'
import { modifier } from 'ember-modifier'

interface LoadingSpinnerModifierNamedArgs {
  size?: number
}

/**
 * A modifier to add a loading spinner on an image element until the image is fully loaded.
 * For best results, the image's parent element should have the following styles:
 * ``` css
 *  display: flex;
 *  align-items: center;
 *  justify-content: center;
 * ```
 * Example usage:
 * ```hbs
 * <img {{loading-spinner size=50}} src="path/to/your/image.jpg" alt="Descriptive text for the image" />
 * ```
 */
export default modifier<
  HTMLImageElement,
  never,
  LoadingSpinnerModifierNamedArgs
>(function loadingSpinner(element, _params, { size = 36 }) {
  assert(
    'element must be an HTMLImageElement',
    element instanceof HTMLImageElement,
  )
  assert('element must have a parent', element.parentElement)
  element.style.visibility = 'hidden'

  const spinner = document.createElement('div')
  spinner.style.fontSize = `${(size / 5.2).toString()}px`
  spinner.style.position = 'absolute'
  spinner.classList.add('loading-spinner')

  element.parentElement.style.position = 'relative'
  element.parentElement.appendChild(spinner)

  // Show spinner until the image is loaded
  element.onload = () => {
    element.style.visibility = 'visible'
    spinner.remove()
  }

  // If the image is already cached by the browser
  if (element.complete) {
    element.style.visibility = 'visible'
    spinner.remove()
  }
})
