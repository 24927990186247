import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import { debugAction } from 're-client/utils/debug'
import type UserService from 're-client/services/user'
import type StoryFactoryService from 're-client/services/story-factory'

export default class StoryFactoryShowroomController extends Controller {
  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  @service
  declare storyFactory: StoryFactoryService

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @action
  @debugAction({
    id: { type: 'text' },
    rating: { type: 'text' },
  })
  async voteForEntry(
    id: string | { id: string; rating: string },
    rating: string,
  ) {
    if (typeof id === 'object') {
      rating = id.rating
      id = id.id
    }

    const data = await this.storyFactory.voteForEntry(id, rating)

    await this.user.fetch()

    this.interactive.callInteractionMethod('nextable', {
      story_factory_rating: data,
    })
  }

  @action
  @debugAction()
  returnToLobby() {
    void this.router.transitionTo('story-factory')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'story-factory/showroom': StoryFactoryShowroomController
  }
}
