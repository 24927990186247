import type {
  ApolloCache,
  BaseMutationOptions,
  DefaultContext,
  DocumentNode,
  MutationOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client'
import { cell, resource, use } from 'ember-resources'
import type { Reactive } from 'ember-resources'

export interface MutationResult<
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
> {
  result: TData | undefined
  mutate(
    options: BaseMutationOptions<TData, TVariables, TContext, TCache>,
  ): Promise<TData | null | undefined>
}

function Mutation<
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
): MutationResult<TData, TVariables, TContext, TCache> {
  return resource(({ owner }) => {
    const apollo = owner.lookup('service:apollo')
    const result = cell<TData | undefined>()

    return () => ({
      result: result.current,
      async mutate(options) {
        const clientOptions = { ...options, mutation } as MutationOptions<TData>
        const mutateResult = await apollo.mutate(clientOptions)

        if (mutateResult.data) {
          result.set(mutateResult.data)
        }

        return mutateResult.data
      },
    })
  })
}

/**
 * A resource that can perform the provided mutation.
 *
 * @returns a resource that will update with the latest mutation result,
 * and a method to perform the mutation.
 */
export function useMutation<TData = unknown, TVariables = OperationVariables>(
  parent: object,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
): Reactive<MutationResult<TData, TVariables>> {
  return use(parent, Mutation(mutation))
}
