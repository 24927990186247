import Service, { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import type Store from '@ember-data/store'
import type ModelRegistry from 'ember-data/types/registries/model'

class TomfooleryUrlBuilder {
  base: URL

  constructor({
    assetHost,
    locale,
    releaseSha,
  }: {
    assetHost: string
    locale: string
    releaseSha: string
  }) {
    this.base = new URL(
      `readingeggs/releases/${releaseSha}/data/${locale}/`,
      assetHost,
    )
  }

  forManifestBundle(path: string) {
    return new URL(`${path}.json`, this.base)
  }
}

/**
 * Handles logic for constructing all urls in the app. Will automatically
 * handle any logic to do with the currently logged in user.
 */
export default class UrlMakerService extends Service {
  @service declare store: Store

  @service declare user: UserService

  /**
   * Generates a url for a caper interactive manifest from the tomfoolery standalone deploy
   */
  urlForInteractive(route: keyof ModelRegistry, interactiveId?: unknown) {
    const adapter = this.store.adapterFor('caper-activity')

    return adapter.buildURL(route, interactiveId)
  }

  urlForManifestBundle(path: string) {
    const { assetHosts, releaseSha } = config.contentLoader.caper
    const { locale } = this.user

    const urlBuilder = new TomfooleryUrlBuilder({
      assetHost: assetHosts.code,
      locale,
      releaseSha,
    })
    return urlBuilder.forManifestBundle(path)
  }
}

declare module '@ember/service' {
  interface Registry {
    'url-maker': UrlMakerService
  }
}
