import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import config from 're-client/config/environment'
import type StudentOperationsService from '@blakeelearning/student-operations/operations/service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type { ModelFor } from 're-client/utils/route-model'
import type SpellingMapQuizRoute from 're-client/routes/spelling/map/quiz'
import { debugAction } from 're-client/utils/debug'

interface MapQuizResults {
  correctCount: number
  totalCount: number
  correctAnswers: string[]
  incorrectAnswers: Record<string, string>
}

/**
 * End-of-map quiz activity, not to be confused with the end-of-spelling-lesson
 * quiz activity
 */
export default class SpellingMapQuizController extends Controller {
  @service declare operations: StudentOperationsService

  @service declare studentProgress: StudentProgressService

  @service declare user: UserService

  @service declare router: RouterService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  declare model: ModelFor<SpellingMapQuizRoute>

  get currentMap() {
    return this.model.mapId
  }

  get studentContext() {
    return {
      precinct: 'spelling',
      product: config.APP.product,
      remoteId: this.user.student.remoteId,
    }
  }

  async completeMapQuiz(results: MapQuizResults) {
    await this.operations.completeMapQuiz(
      this.studentContext,
      this.currentMap,
      this._toSnakeCase(results),
    )
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: 'Pass Quiz', value: 'pass' },
        { label: 'Fail Quiz', value: 'fail' },
      ],
      values: {
        pass: {
          correctCount: 10,
          totalCount: 10,
          correctAnswers: [
            'debug_spelling_map_quiz_1',
            'debug_spelling_map_quiz_2',
            'debug_spelling_map_quiz_3',
            'debug_spelling_map_quiz_4',
            'debug_spelling_map_quiz_5',
            'debug_spelling_map_quiz_6',
            'debug_spelling_map_quiz_7',
            'debug_spelling_map_quiz_8',
            'debug_spelling_map_quiz_9',
            'debug_spelling_map_quiz_10',
          ],
          incorrectAnswers: {},
        },
        fail: {
          correctCount: 0,
          totalCount: 10,
          correctAnswers: [],
          incorrectAnswers: {
            debug_spelling_map_quiz_1: 'wrong_1',
            debug_spelling_map_quiz_2: 'wrong_2',
            debug_spelling_map_quiz_3: 'wrong_3',
            debug_spelling_map_quiz_4: 'wrong_4',
            debug_spelling_map_quiz_5: 'wrong_5',
            debug_spelling_map_quiz_6: 'wrong_6',
            debug_spelling_map_quiz_7: 'wrong_7',
            debug_spelling_map_quiz_8: 'wrong_8',
            debug_spelling_map_quiz_9: 'wrong_9',
            debug_spelling_map_quiz_10: 'wrong_10',
          },
        },
      },
    },
  })
  async completeQuiz({ results }: { results: MapQuizResults }) {
    await this.completeMapQuiz(results)
    await this.next()
  }

  // Saves the quiz results as a map quiz complete event
  @action
  async quizCompleted(results: MapQuizResults) {
    await this.completeMapQuiz(results)
    const data = { reward: { eggs: 0 } }
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  @debugAction()
  async next() {
    await this.studentProgress.fetchProgress()

    if (
      this.studentProgress.spellingCurrentMap >
      config.studentProgress.progress.spelling.lastMap
    ) {
      void this.router.transitionTo('spelling.finished-re-spelling')
    } else {
      void this.router.transitionTo('spelling.map', this.model.nextMapId)
    }
  }

  /**
   * Adds one to the student's eggs count
   */
  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    let amount

    if (typeof args === 'number') {
      amount = args
    } else {
      amount = args.amount
    }

    this.user.incrementEggs(amount)
  }

  _toSnakeCase(results: MapQuizResults) {
    return {
      correct_count: results.correctCount,
      total_count: results.totalCount,
      correct_answers: results.correctAnswers,
      incorrect_answers: results.incorrectAnswers,
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'spelling/map/quiz': SpellingMapQuizController
  }
}
