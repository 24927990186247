import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.inAssignmentMode}}\n  {{#let (unique-id) as |titleId|}}\n    <Dialog\n      @open={{this.showQuestAlert}}\n      class=\"pointer-events-auto aria-hidden:pointer-events-none aria-hidden:opacity-0\"\n      role=\"alertdialog\"\n      aria-labelledby={{titleId}}\n      aria-hidden={{if this.showQuestAlert \"false\" \"true\"}}\n      data-test-quest-notification\n    >\n      <Dialog::Overlay\n        class=\"flex items-center justify-center\"\n        role=\"document\"\n        tabindex=\"0\"\n        {{content-interactive\n          this.jesterLoader.loader\n          externalController=this\n          manifests=this.jesterManifests\n          onError=this.onContentError\n        }}\n      />\n    </Dialog>\n  {{/let}}\n{{/unless}}", {"contents":"{{#unless this.inAssignmentMode}}\n  {{#let (unique-id) as |titleId|}}\n    <Dialog\n      @open={{this.showQuestAlert}}\n      class=\"pointer-events-auto aria-hidden:pointer-events-none aria-hidden:opacity-0\"\n      role=\"alertdialog\"\n      aria-labelledby={{titleId}}\n      aria-hidden={{if this.showQuestAlert \"false\" \"true\"}}\n      data-test-quest-notification\n    >\n      <Dialog::Overlay\n        class=\"flex items-center justify-center\"\n        role=\"document\"\n        tabindex=\"0\"\n        {{content-interactive\n          this.jesterLoader.loader\n          externalController=this\n          manifests=this.jesterManifests\n          onError=this.onContentError\n        }}\n      />\n    </Dialog>\n  {{/let}}\n{{/unless}}","moduleName":"re-client/components/quest/notification.hbs","parseOptions":{"srcName":"re-client/components/quest/notification.hbs"}});
import { action } from '@ember/object'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import type QuestService from 're-client/services/quest'
import type AssignmentsService from 're-client/services/assignments'
import type JesterLoaderService from 're-client/services/jester-loader'
import type UserService from 're-client/services/user'
import type ErrorHandlerService from 're-client/services/error-handler'
import type Owner from '@ember/owner'

interface QuestNotificationComponentArgs {
  alertAnytime?: boolean
}
export default class QuestNotificationComponent extends Component<QuestNotificationComponentArgs> {
  @service declare assignments: AssignmentsService

  @service declare quest: QuestService

  @service declare jesterLoader: JesterLoaderService

  @service declare user: UserService

  @service declare errorHandler: ErrorHandlerService

  constructor(owner: Owner, args: QuestNotificationComponentArgs) {
    super(owner, args)
    if (this.args.alertAnytime) this.quest.displayQuestAlertIfRequired()
  }

  get showQuestAlert() {
    return this.quest.showQuestAlert
  }

  get questAlertMessage() {
    return this.quest.notificationMessage
  }

  get questGoalForNotification() {
    return this.quest.questGoalForNotification
  }

  get inAssignmentMode() {
    return this.assignments.hasTask
  }

  get jesterManifests() {
    return [['index', 'essentials-quest-reward']]
    // return [['map-certificate', `maps/001`]]
  }

  @action
  acknowledgeQuestGoalAlert() {
    if (!this.questGoalForNotification) return

    void this.quest.acknowledgeQuestGoalAlert({
      goalId: this.questGoalForNotification.id,
    })
  }

  @action
  awarded() {
    this.acknowledgeQuestGoalAlert()
  }

  @action
  close() {
    this.quest.showQuestAlert = false
  }

  @action
  onContentError(error: Error) {
    // console.log(error)
    this.errorHandler.handleContentUnhandledError(error)
  }
}
