import Model, { hasMany } from '@ember-data/model'
import type { SyncHasMany } from '@ember-data/model'
import type BookCoverModel from 're-client/models/book-cover'
import toJSON from 're-client/utils/to-json'
import type { ToJSONOptions } from 're-client/utils/to-json'

export default class BookshelfModel extends Model {
  @hasMany('book-cover', { async: false, inverse: null })
  declare bookCovers: SyncHasMany<BookCoverModel>

  override toJSON(options?: ToJSONOptions): Record<string, unknown> {
    return toJSON(this, options)
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    bookshelf: BookshelfModel
  }
}
