import { action } from '@ember/object'
import Controller from '@ember/controller'
import { capitalize } from '@ember/string'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'

export default class BookshelfController extends Controller {
  @service
  declare router: RouterService

  @service
  declare user: UserService

  get studentDisplayName() {
    return capitalize(this.user.student.firstName)
  }

  @action
  goToBooks(bookId: string) {
    this.router.transitionTo('reader.book.index', bookId)
  }
}
