import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

export default class SongsIndexRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model() {
    const manifests = [
      this.urlMaker.urlForInteractive('music-cafe/activities/juke-box'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests)

    return { interactiveConfig }
  }
}
