import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/driving-tests/racing-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/driving-tests/racing-game.hbs"}});
import Component from '@glimmer/component'

export default class RacingGame extends Component {
  incrementScore(eggs) {
    this.args.incrementScoreAction(eggs)
  }

  exit() {
    this.args.exitAction()
  }
}
