import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type RouterService from '@ember/routing/router-service'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

export default class StorylandsFinishedReStorylandsRoute extends Route {
  @service
  declare router: RouterService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override beforeModel() {
    // guard against kids finding the route by "accident"
    if (this.studentProgress.isAreaLocked('storylands')) {
      void this.router.transitionTo('index')
      return
    }

    if (
      this.studentProgress.storylandsCurrentMap <=
      config.studentProgress.progress.storylands.lastMap
    ) {
      void this.router.transitionTo(
        'storylands.map',
        this.studentProgress.storylandsCurrentMap,
      )
    }
  }

  override async model() {
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('clinker-castle', 'finished_clinker'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return { interactiveConfig }
  }
}
