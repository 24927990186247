import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type QuestService from 're-client/services/quest'
import type RouterService from '@ember/routing/router-service'
import type StudentProgressService from 're-client/services/student-progress'

export default class RecommendedRoute extends Route {
  @service declare studentProgress: StudentProgressService

  @service declare quest: QuestService

  @service declare router: RouterService
  override beforeModel() {
    if (this.studentProgress.showLessonsPlacementTest) {
      this.router.replaceWith('lessons.placement-test')
      return
    }
    if (this.quest.recommendedTargetRoute) {
      // @ts-expect-error router params type no bueno
      this.router.replaceWith(...this.quest.recommendedTargetRoute)
      return
    }
    this.router.replaceWith('/')
  }
}
