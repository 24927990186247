import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import { underscore } from '@ember/string'
import type RouterService from '@ember/routing/router-service'

export default class PuzzlesIndexController extends Controller {
  @service
  declare router: RouterService

  /**
   * Load and show the specified puzzle
   * @param {String} puzzleName The name of the puzzle to load
   */
  @action
  showPuzzle(puzzleName: string) {
    this.router.transitionTo('puzzles.puzzle', underscore(puzzleName))
  }
}
