import type Owner from '@ember/owner'
import Service from '@ember/service'
import { ContentLoader, activityAccent } from '@blakeelearning/content-loader'
import type { Manifest } from '@blakeelearning/content-loader-core'
import config from 're-client/config/environment'

export default class JesterLoaderService extends Service {
  loader: ContentLoader

  constructor(owner: Owner) {
    super(owner)

    this.loader = new ContentLoader({
      framework: 'jester',
      config: config.contentLoader.jester,
    })
  }

  setAccent(accent: string) {
    const variant = activityAccent(accent)

    if (this.loader.variant !== variant) {
      this.loader = new ContentLoader({
        variant,
        framework: 'jester',
        config: config.contentLoader.jester,
      })
    }
  }

  load(manifests: Manifest[], variables?: Record<string, unknown>) {
    return this.loader.load(manifests, variables)
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:jester-loader')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('jester-loader') declare altName: JesterLoaderService;`.
declare module '@ember/service' {
  interface Registry {
    'jester-loader': JesterLoaderService
  }
}
