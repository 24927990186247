import { service } from '@ember/service'
import ApplicationAdapter from 're-client/adapters/application'
import type UserService from 're-client/services/user'

export default class ReadingBookAdapter extends ApplicationAdapter {
  @service
  declare user: UserService

  override get namespace() {
    const { locale } = this.user
    return `api/data/${locale}`
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'reading/book': ReadingBookAdapter
  }
}
