import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { sample } from 'lodash'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type { FeatureService } from '@blakeelearning/features'
import type RouterService from '@ember/routing/router-service'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'
import type LessonsService from 're-client/services/lessons'
import type StudentProgressService from 're-client/services/student-progress'
import type CaperLoaderService from 're-client/services/caper-loader'
import type CertificateService from 're-client/services/certificate'
import type ActivityService from 're-client/services/activity'
import { ReadingActivityEnum } from 're-client/graphql/graphql'

interface LessonMapQuizModel {
  interactiveConfig: InteractiveConfig
  mapId: number
  caperPopupActivityManifests?: URL[] | undefined
  jesterPopupActivityManifests?: string[][] | undefined
}

export default class LessonsMapQuizRoute extends Route<LessonMapQuizModel> {
  @service declare certificate: CertificateService

  @service declare studentProgress: StudentProgressService

  @service declare lessons: LessonsService

  @service()
  declare caperLoader: CaperLoaderService

  @service declare user: UserService

  @service declare urlMaker: UrlMakerService

  @service declare features: FeatureService

  @service declare router: RouterService

  @service declare activity: ActivityService

  override beforeModel() {
    const { canPlayQuizForMap } = this.lessons.setCurrentLesson('quiz')

    if (!canPlayQuizForMap) {
      void this.router.transitionTo(
        'lessons.map.index',
        this.studentProgress.lessonsCurrentMap,
      )
    }
  }

  override async model(): Promise<LessonMapQuizModel> {
    const mapId = this.lessons.currentMap

    const certificates = await this.certificate.getCertificates()

    const previousBestScorePercentage = certificates?.find(
      (certificate) => certificate.map === mapId,
    )?.scorePercentage

    const variables = {
      student: this.user.student,
      previousBestScorePercentage,
    }

    let caperPopupActivityManifests

    const caperPopupActivity = sample(
      this.activity.activities.filter(
        (activity) => activity.activityType === ReadingActivityEnum.Video,
      ),
    )

    const manifests = [
      this.urlMaker.urlForInteractive('reading/quizzes', mapId),
    ]

    if (caperPopupActivity) {
      const manifestBundleUrl = this.urlMaker.urlForManifestBundle(
        caperPopupActivity.manifestBundle,
      )

      manifestBundleUrl.hash = caperPopupActivity.manifestKey.join('.')

      caperPopupActivityManifests = [manifestBundleUrl]
    }

    const jesterPopupActivityManifests = [
      ['map-certificate', `maps/${mapId.toString().padStart(3, '0')}`],
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      caperPopupActivityManifests,
      jesterPopupActivityManifests,
      interactiveConfig,
      mapId,
    }
  }
}
