export default {
  spelling_results: {
    all_maps: {
      best_lesson_quiz_number: 1,
      best_lesson_quiz_score: 9,
      first_lesson_quiz_completed_at: '01/01/2018',
      first_lesson_quiz_number: 1,
      lesson_quizzes_average_score: '6.33',
    },
    map: {
      best_lesson_quiz_score: [
        {
          score: 9,
          lesson_position: 1,
        },
        {
          score: 5,
          lesson_position: 2,
        },
      ],
      best_map_quiz_percent: 70,
      position: 1,
    },
  },
}
