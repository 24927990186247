import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type Store from '@ember-data/store'

export default class BookshelfRoute extends Route {
  @service
  declare store: Store

  /*
   * We get bookshelf api from api/v1/bookshelves/bookshelf and it loads
   * book-covers into the store.
   */
  override async model() {
    const bookshelf = await this.store.findRecord('bookshelf', 'bookshelf')
    const books = bookshelf.bookCovers.map((bookCoverData) => ({
      id: bookCoverData.id,
      coverUrl: bookCoverData.coverUrl,
    }))
    return { books }
  }
}
