import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { get } from 'lodash'
import type StudentProgressService from 're-client/services/student-progress'
import type LessonsService from 're-client/services/lessons'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type { GqlStudent } from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import type ActivityService from 're-client/services/activity'
import { Framework } from 're-client/graphql/graphql'
import type { ModelFor } from 're-client/utils/route-model'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'
import type LessonsLessonRoute from 're-client/routes/lessons/lesson'
import type AssignmentsService from 're-client/services/assignments'
import type { SideBarActivityData } from 're-client/components/activity-sidebar/activity-item'

export interface Params extends Record<string, unknown> {
  activity_id: string
}

interface CaperModel {
  interactiveConfig: InteractiveConfig
  activityId: number
  lessonId: string
  sideBarActivityData?: SideBarActivityData[]
  isCaperActivity: true
  isAssignmentMode?: boolean
  lessonInPrecinct?: number
}

interface Variables {
  student: GqlStudent
  isAssignment: boolean
}

interface JesterModel {
  activityId: number
  lessonId: string
  sideBarActivityData?: SideBarActivityData[]
  isCaperActivity?: false
  isAssignmentMode?: boolean
  jesterManifests?: string[][]
  variables?: Variables
  lessonInPrecinct?: number
}

type Model = CaperModel | JesterModel

export default class LessonsLessonActivityRoute extends Route<Model, Params> {
  @service declare studentProgress: StudentProgressService

  @service('lessons') declare progress: LessonsService

  @service declare assignments: AssignmentsService

  @service() declare caperLoader: CaperLoaderService

  @service declare user: UserService

  @service declare activity: ActivityService

  @service declare router: RouterService

  override buildRouteInfoMetadata() {
    return {
      willHideLoadingScreenManually: true,
    }
  }

  getManifestBundle(
    framework: Framework,
    manifestBundles: Map<Framework, unknown>,
  ) {
    return manifestBundles.get(framework)
  }

  override async model(params: Params): Promise<Model> {
    const activityId = Number(params.activity_id)
    const { currentLesson } = this.progress.setCurrentActivity(activityId)

    const lessonModel = this.modelFor(
      'lessons.lesson',
    ) as ModelFor<LessonsLessonRoute>

    const { activitiesForLesson, manifestBundles, sideBarData } = lessonModel
    const activity = activitiesForLesson.find(
      ({ activityInLesson }) => activityInLesson === activityId,
    )

    const sideBarActivityData = sideBarData.map((sideBarActivity) => {
      let status: SideBarActivityData['status'] = 'locked'

      if (sideBarActivity.id === activityId) {
        status = 'unlocked'
      } else if (this.progress.canPlayActivity(sideBarActivity.id)) {
        status = 'complete'
      }

      return { ...sideBarActivity, status }
    })

    let isCaperActivity = false
    let isJesterActivity = false

    if (!activity) {
      void this.router.replaceWith(
        'lessons.lesson.index',
        this.studentProgress.lessonsCurrentLessonNumber,
      )
    } else {
      isCaperActivity = activity.framework === Framework.Caper
      isJesterActivity = activity.framework === Framework.Jester
      const { lessonInPrecinct } = activity

      if (isCaperActivity) {
        const manifestBundle = this.getManifestBundle(
          activity.framework,
          manifestBundles,
        )

        const manifests = [
          get(manifestBundle, activity.manifestKey),
        ] as string[]

        const isAssignment = this.assignments.canCompleteLessonAssignmentTask(
          currentLesson,
          activityId,
        )
        const variables = { student: this.user.student, isAssignment }

        const interactiveConfig = await this.caperLoader.load(
          manifests,
          variables,
        )

        return {
          interactiveConfig,
          activityId,
          lessonId: currentLesson,
          sideBarActivityData,
          isCaperActivity,
          isAssignmentMode: isAssignment,
          lessonInPrecinct,
        }
      }

      if (isJesterActivity) {
        const jesterManifests = [
          [...activity.manifestKey, activity.manifestBundle],
        ] // [['readingeggs-1-end-of-lesson-skills-quiz', 'lessons/001']]

        const isAssignment = this.assignments.canCompleteLessonAssignmentTask(
          currentLesson,
          activityId,
        )
        const variables = { student: this.user.student, isAssignment }

        return {
          activityId,
          lessonId: currentLesson,
          sideBarActivityData,
          isCaperActivity,
          jesterManifests,
          variables,
          lessonInPrecinct,
          isAssignmentMode: isAssignment,
        }
      }
    }

    throw new Error('Unknown activity framework')
  }

  /**
   * Access control to activity based on the student's progress, executed after the model loads.
   *
   * Allows access if:
   *  - `shouldForceAccess()` evaluates to `true`
   *  - the authorisation policy allows access, based on student progress
   *
   * @instance afterModel
   * @memberOf LessonActivityRoute
   * @param {Object} params The url params
   */
  override afterModel({ activityId }: Model) {
    if (this.assignments.hasTask) {
      return
    }

    if (activityId !== this.progress.currentActivity) {
      void this.router.transitionTo(
        'lessons.lesson.index',
        this.progress.currentLesson,
      )
    }
  }
}
