import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FullViewport\n  {{content-interactive\n    this.loader\n    externalController=this.externalController\n    manifests=@manifests\n    variables=this.variables\n    onError=this.onError\n  }}\n  class=\"{{style-namespace 'content-interactive'}}\"\n  ...attributes\n  data-test-content-interactive\n/>", {"contents":"<FullViewport\n  {{content-interactive\n    this.loader\n    externalController=this.externalController\n    manifests=@manifests\n    variables=this.variables\n    onError=this.onError\n  }}\n  class=\"{{style-namespace 'content-interactive'}}\"\n  ...attributes\n  data-test-content-interactive\n/>","moduleName":"re-client/components/content-interactive.hbs","parseOptions":{"srcName":"re-client/components/content-interactive.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import type { ExternalController, Logger } from '@blakeelearning/messenger'
import {
  createExternalController,
  assertContentActions,
  assertContentSpec,
} from '@blakeelearning/messenger'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import type { FeatureService } from '@blakeelearning/features'
import type { Log } from '@blakeelearning/log'
import type CaperLoaderService from 're-client/services/caper-loader'
import type JesterLoaderService from 're-client/services/jester-loader'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'

interface Args {
  contentActions: unknown
  contentSpec: unknown
  framework: 'jester' | 'caper'
  variables: Record<string, unknown>
}

export default class ContentInteractiveComponent extends Component<Args> {
  @service declare user: UserService

  @service declare features: FeatureService

  @service declare caperLoader: CaperLoaderService

  @service() declare jesterLoader: JesterLoaderService

  @service declare router: RouterService

  @service declare log: Log

  get loader() {
    if (this.args.framework === 'jester') {
      return this.jesterLoader.loader
    }

    return this.caperLoader.loader
  }

  get externalController(): ExternalController {
    const { contentActions, contentSpec } = this.args

    assertContentSpec(contentSpec)
    assertContentActions(contentActions, contentSpec)

    let logger: Logger | undefined
    if (config.APP.enableMessengerLogging)
      logger = (name, event, ...args) => {
        console.debug(
          '%c[%s :: %s]',
          'color: black; background-color: #aeca36; font-weight: bold',
          name,
          event,
          ...args,
        )
      }

    return createExternalController(contentActions, contentSpec, {
      // onUnrecoverableError: (error) => this.errorHandler.handleUnrecoverableError(error),
      logger,
    })
  }

  get variables() {
    // const client = this.features.getClient()
    // const features = client.getObjectValue<string[]>('jester-feature-flags', [])
    const features = []

    for (const flag of ['jesterCssFontLoadingFF', 'jesterLoaderRetryFF']) {
      if (this.features.isEnabled(flag)) {
        features.push(flag)
      }
    }

    return {
      ...this.args.variables,
      features,
    }
  }

  @action
  onError(error: Error) {
    this.log.error(error)
    void this.router.transitionTo('application-error')
  }
}
