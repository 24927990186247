import camelize from 'camelize2'
import { camelize as camelizeAsString } from '@ember/string'

/**
 * Camelize objects and strings.
 * To camelize the keys of an object we are using `camelize2`
 *
 * @see https://github.com/DenysIvko/camelize2
 * @param obj
 * @returns {Object}
 */
export default function camelizeKeys(obj) {
  if (typeof obj === 'string') return camelizeAsString(obj)
  return camelize(obj)
}
