import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'

export default class CrittersCritterController extends Controller {
  @service
  declare router: RouterService

  @action
  next() {
    void this.router.transitionTo('critters')
  }
}
