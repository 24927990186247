import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'

export default class UnauthorisedController extends Controller {
  @service
  declare router: RouterService

  @action
  login() {
    void this.router.replaceWith('login')
  }
}
