import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"nav-item-simple text-center {{this.lowercaseItemName}}\"\n  ...attributes\n>\n  <button\n    data-test-nav-item-wrapper\n    data-test-fullscreen-nav-item={{this.lowercaseItemName}}\n    class=\"nav-item-wrapper\"\n    type=\"button\"\n    aria-label=\"{{@title}}\"\n    disabled={{@disabled}}\n    {{hover-wiggle \"svg\"}}\n    {{on \"click\" @onClick}}\n    {{play-sound-on \"click\" \"snap\"}}\n  >\n    {{svg-jar @itemName}}\n    <div class=\"label-back\">{{@title}}</div>\n    {{yield}}\n  </button>\n</div>", {"contents":"<div\n  class=\"nav-item-simple text-center {{this.lowercaseItemName}}\"\n  ...attributes\n>\n  <button\n    data-test-nav-item-wrapper\n    data-test-fullscreen-nav-item={{this.lowercaseItemName}}\n    class=\"nav-item-wrapper\"\n    type=\"button\"\n    aria-label=\"{{@title}}\"\n    disabled={{@disabled}}\n    {{hover-wiggle \"svg\"}}\n    {{on \"click\" @onClick}}\n    {{play-sound-on \"click\" \"snap\"}}\n  >\n    {{svg-jar @itemName}}\n    <div class=\"label-back\">{{@title}}</div>\n    {{yield}}\n  </button>\n</div>","moduleName":"re-client/components/home-nav/nav-item-simple.hbs","parseOptions":{"srcName":"re-client/components/home-nav/nav-item-simple.hbs"}});
import Component from '@glimmer/component'

interface NavItemSimpleSignature {
  Args: {
    disabled?: boolean
    itemName: string
    title: string
  }
  Element: HTMLDivElement
}

export default class NavItemSimple extends Component<NavItemSimpleSignature> {
  get lowercaseItemName() {
    return this.args.itemName.toLowerCase()
  }
}
