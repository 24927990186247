import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ContentSpec } from '@blakeelearning/messenger'

interface GoToQuizMessage {
  quizId?: string | undefined
}

export default class MyReadingGoalsIndexController extends Controller {
  @service
  declare errorHandler: ErrorHandlerService

  @service
  declare loadingUi: LoadingUiService

  @service
  declare router: RouterService

  get contentSpec(): ContentSpec {
    return {
      goHome: {
        type: 'message',
      },
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
      goToQuiz: {
        type: 'message',
      },
      goToMilestones: {
        type: 'message',
      },
    }
  }

  @action
  unhandledError({ error }: { error: { name?: string; message?: string } }) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  readyForUserInput() {
    this.loadingUi.hide()
  }

  @action
  goHome() {
    void this.router.transitionTo('/')
  }

  @action
  goToQuiz({ quizId }: GoToQuizMessage) {
    // fallback to default quiz for dev purpose
    void this.router.transitionTo(
      'my-reading-goals.quiz',
      quizId ? quizId : 'mrg-quiz',
    )
  }

  @action
  goToMilestones() {
    void this.router.transitionTo('my-reading-goals.milestones')
  }
}
