import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<full-nav id=\"navigation\" ...attributes>\n  <div\n    class=\"nav-item-list\"\n    data-nav-items-list\n    data-test-nav-items-list\n    {{window-resize this.resize}}\n  >\n    {{yield}}\n  </div>\n</full-nav>", {"contents":"<full-nav id=\"navigation\" ...attributes>\n  <div\n    class=\"nav-item-list\"\n    data-nav-items-list\n    data-test-nav-items-list\n    {{window-resize this.resize}}\n  >\n    {{yield}}\n  </div>\n</full-nav>","moduleName":"re-client/components/home-nav.hbs","parseOptions":{"srcName":"re-client/components/home-nav.hbs"}});
import gsap from 'gsap'
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import type { FeatureService } from '@blakeelearning/features'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'

interface HomeNavSignature {
  Element: HTMLDivElement
}

const baseWidth = 1024
const baseHeight = 768

const firstRowItemsSelector = '#first-row > div'

const secondRowItemsSelector = '#second-row > div'

export default class HomeNavComponent extends Component<HomeNavSignature> {
  @service
  declare features: FeatureService

  @service('device/detection')
  declare device: DeviceDetection

  /**
   * Target of the first curve tween.
   * Tracks the current position, so we set it initially to the first node in the path
   */
  firstCurve = {
    x: 0,
    y: 280,
    rotation: 0,
  }

  /**
   * Target of the second curve tween.
   * Tracks the current position, so we set it initially to the first node in the path
   */
  secondCurve = {
    x: 0,
    y: 580,
    rotation: 0,
  }

  firstTween = gsap.to(this.firstCurve, {
    motionPath: {
      curviness: 0.6,
      path: [
        { x: 0, y: 280 },
        { x: 512, y: 330 },
        { x: 1024, y: 280 },
      ],
    },
    ease: 'none',
  })

  secondTween = gsap.to(this.secondCurve, {
    motionPath: {
      autoRotate: true,
      curviness: 0.6,
      path: [
        { x: 0, y: 580 },
        { x: 512, y: 515 },
        { x: 1024, y: 580 },
      ],
    },
    ease: 'none',
  })

  _alignItems(navList: HTMLElement) {
    this._alignItemsAlongCurve(
      navList,
      firstRowItemsSelector,
      this.firstCurve,
      this.firstTween,
    )
    this._alignItemsAlongCurve(
      navList,
      secondRowItemsSelector,
      this.secondCurve,
      this.secondTween,
    )
  }

  /**
   * This uses Tweenmax to create a tween (animation) which follows a bezier curve.
   * Now we are able to position of each element based on its index along the line
   * @param itemSelector - jQuery collection of elements
   * @param path
   * @param autoRotate
   * @see https://greensock.com/forums/topic/7251-plot-elements-and-or-draw-along-a-bezier-path/ for inspiration
   */
  _alignItemsAlongCurve(
    navList: HTMLElement,
    itemSelector: string,
    curve: { x: number; y: number; rotation: number },
    tween: gsap.core.Tween,
  ) {
    const items = navList.querySelectorAll<HTMLElement>(itemSelector)
    tween.duration(items.length)

    const firstRow = itemSelector === firstRowItemsSelector
    /**
     * These values are are times on the tween timeline and represent the positions that the icons will be placed along the path.
     * We are manually declaring them for the first row as the spacing is not even since the addition of the 'Reading Journal' and the desire to keep 'Lessons' placed in the centre.
     */
    let firstRowPlacement = [0.6, 1.8, 3, 3.83, 4.63, 5.43]

    if (this.features.isEnabled('my_reading_goals')) {
      firstRowPlacement = [0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5] // 7 items in total
    }

    for (const [i, item] of items.entries()) {
      const iconPlacement = firstRow ? firstRowPlacement[i] : i + 0.5
      if (typeof iconPlacement === 'number') {
        // jumps to the appropriate time in the tween, causing position.x and position.y to be updated accordingly.
        tween.time(iconPlacement)
      }

      gsap.set(item, {
        left: curve.x - item.offsetWidth / 2,
        top: curve.y - item.offsetHeight,
        rotation: curve.rotation,
      })
    }
  }

  /**
   * Using the width and height of the window, this function determines how a 4:3 ratio rectangle would best fit within
   * it (portrait or landscape - ie whether it should be width or height constrained), and calculates the amount
   * of X or Y offset in order to center that rectangle.
   *
   * !isLandscape
   *  _________
   * |         |
   * |         |                   isLandscape
   * |_________|            ____________________________
   * |         |           |       |            |       |
   * | 4:3 nav |    OR     |       |   4:3 nav  |       |
   * |         |           |       |            |       |
   * |_________|           |_______|____________|_______|
   * |         |^                                <----->
   * |         || y offset                        x offset
   * |_________|v
   *
   * @returns {object} returns an object with x and y offset pixel values as integers
   *
   */
  get offset() {
    const h = window.innerHeight
    const w = window.innerWidth

    const isLandscape = w / h > 4 / 3
    let x = 0
    let y = 0

    if (isLandscape) {
      x = (w - (4.0 * h) / 3.0) / 2.0
    } else {
      y = (h - (3.0 * w) / 4.0) / 2.0
    }
    return { x, y }
  }

  get scale() {
    const h = window.innerHeight / baseHeight
    const w = window.innerWidth / baseWidth

    return Math.min(w, h)
  }

  @action
  resize(navList: HTMLElement) {
    this.resizeNavItemsList(navList)
    this._alignItems(navList)
  }

  resizeNavItemsList(navList: HTMLElement) {
    navList.style.transform = `scale(${this.scale.toFixed(3)})`
    navList.style.left = `${this.offset.x.toFixed(3)}px`
    navList.style.top = `${this.offset.y.toFixed(3)}px`
  }
}
