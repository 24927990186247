import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type Transition from '@ember/routing/transition'
import type RouterService from '@ember/routing/router-service'
import Route from '@ember/routing/route'
import type Store from '@ember-data/store'
import { allowedDepartment } from 're-client/utils/department'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type { ParamsFor } from 're-client/utils/route-model'
import type ShopDepartmentRoute from 're-client/routes/shop/department'

export default class ShopRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare store: Store

  @service
  declare router: RouterService

  override async model() {
    const { department } = this.paramsFor(
      'shop.department',
    ) as ParamsFor<ShopDepartmentRoute>
    const validDepartment = allowedDepartment(department)
    const items = await this.store.findAll('item')
    const studentItemsEmberArray = items.map((item) => item.get('code'))
    const studentItems = [...studentItemsEmberArray]

    const variables = {
      student: this.user.student,
      studentItems,
      department: validDepartment,
    }

    const manifests = [this.urlMaker.urlForInteractive('main/shop')]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      department,
      interactiveConfig,
    }
  }

  /**
   * When we come back from the catalogue we need to refresh the shop model in order to update
   * the egg count, as the interactive variables are buried deep in the config data which wont trigger the model
   * at all.
   *
   * todo: [tj] consider splitting the interactive variables into its own property which gets passed to the interactive
   *       component. IN that way the component could rerender when the eggs change.
   *
   * @param transition
   * @returns {boolean}
   */
  @action
  override willTransition(transition: Transition) {
    const willGotoShopPage = transition.to?.name === 'shop.department.index'
    const isOnCatalogueRoute =
      transition.from?.name === 'shop.department.catalogue'
    if (willGotoShopPage && isOnCatalogueRoute) {
      void this.router.refresh()
    }
    return true
  }
}
