import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type LogoutService from 're-client/services/logout'

export default class ApplicationErrorController extends Controller {
  @tracked showErrorMessage = false

  @service
  declare logout: LogoutService

  @action
  toggleErrorMessage() {
    this.showErrorMessage = !this.showErrorMessage
  }

  @action
  bye(event: Event) {
    event.preventDefault()

    this.logout.doLogout()
  }
}
