import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ContentSpec } from '@blakeelearning/messenger'

export default class MyReadingGoalsMilestonesController extends Controller {
  @service
  declare errorHandler: ErrorHandlerService

  @service
  declare loadingUi: LoadingUiService

  get contentSpec(): ContentSpec {
    return {
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
    }
  }

  @action
  unhandledError({ error }: { error: { name?: string; message?: string } }) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  readyForUserInput() {
    this.loadingUi.hide()
  }
}
