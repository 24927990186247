export default {
  items: [
    {
      id: 1,
      code: 'head_40',
      department: 'head',
      state: null,
      image_url: null,
      animation_url: null,
      animation_json: null,
    },
    {
      id: 2,
      code: 'arms_54',
      department: 'arms',
      state: null,
      image_url: null,
      animation_url: null,
      animation_json: null,
    },
    {
      id: 3,
      code: 'floor_sofa_glove_black',
      department: 'furniture',
      state: null,
      image_url:
        'https://assets.readingeggs.com/shop/readingeggs/items/images/floor_sofa_glove_black.png',
      animation_url: null,
      animation_json: null,
    },
  ],
}
