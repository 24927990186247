import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import config from 're-client/config/environment'
import type { Log } from '@blakeelearning/log'
import ky from 'ky'

const { apiEndpoint, apiNamespace } = config.APP

interface SaveGameScore {
  name: string
  score: number
}

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class GamesGameController extends Controller {
  @service
  declare log: Log

  @service
  declare router: RouterService

  @action
  async saveGameScore({ name, score }: SaveGameScore) {
    let response

    try {
      response = await ky
        .post('arcade_game_scores', {
          credentials: 'include',
          json: { name, score },
          prefixUrl,
        })
        .json()
    } catch (error) {
      this.log.error(error as Error)
    }

    return response
  }

  @action
  next() {
    void this.router.transitionTo('games')
  }
}
