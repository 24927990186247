import { runInDebug } from '@ember/debug'
import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'

/**
 * A service to hold the loading state used by the loading-screen component to determine if it should be visible.
 *
 * In the application route listeners are attached to the router.
 *
 * For each `routeWillChange` event the show() method is called (excluding debug mode). This can be overidden per route by adding metadata to the routeInfo object as follows:
 * @example
 * ```
 * buildRouteInfoMetadata(){
 *  return {
 *    optOutLoadingScreen: true,
 *  }
 * }
 * ```
 * On a `routeDidChange` event we traverse the list of routeInfo objects from the top down, checking if any resolved model contains an `interactiveConfig` or a `loader`.
 * The existance of these keys indicate that a route is loading an interactive and will handle clearing the loading screen manually. If neither of these keys are found we clear the loading screen automatically.
 *
 * For Jester a `readyForUserInput` content action should call `hide()` on this service.
 *
 * For Caper we are listening for a `LOAD_COMPLETE` event via the `caper-load-complete` modifier which is applied directly to the `loading-screen` component.
 *
 * @class LoadingUiService
 */
export default class LoadingUiService extends Service {
  @tracked isLoading = false

  show() {
    runInDebug(() => {
      console.debug(`%cshow loading screen`, 'color: red; font-size: 1.1rem')
    })
    this.isLoading = true
  }

  hide() {
    runInDebug(() => {
      console.debug(`%chide loading screen`, 'color: red; font-size: 1.1rem')
    })
    this.isLoading = false
  }
}

declare module '@ember/service' {
  interface Registry {
    'loading-ui': LoadingUiService
  }
}
