import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/shop-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/shop-game.hbs"}});
import Component from '@glimmer/component'

export default class ShopGame extends Component {
  async purchaseItem(item) {
    const result = await this.args.purchaseAction(item)
    this.interactive.callInteractionMethod('completeItemPurchase', {
      id: item.id,
      result,
    })
  }

  changeDepartment(department) {
    this.args.changeDepartmentAction(department)
  }

  gotoCatalogue() {
    this.args.gotoCatalogueAction()
  }
}
