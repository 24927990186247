import type Transition from '@ember/routing/transition'

export default function transitionToArray(transition: Transition) {
  const urlParams = _getParamValuesFromRouteInfo(transition.to)

  return [transition.targetName, ...urlParams]
}

function _getParamValuesFromRouteInfo(
  routeInfo: Transition['to'],
): (string | undefined)[] {
  if (!routeInfo) {
    return []
  }

  const parts = _getParamValuesFromRouteInfo(routeInfo.parent)

  const rest = routeInfo.paramNames.map(
    (paramName) => routeInfo.params[paramName],
  )

  return [...parts, ...rest]
}
