import Model, { attr } from '@ember-data/model'
import toJSON from 're-client/utils/to-json'
import type { ToJSONOptions } from 're-client/utils/to-json'

export default class PuzzleScoreModel extends Model {
  @attr('string')
  declare puzzleName: string

  @attr('number')
  declare score: number

  override toJSON(options?: ToJSONOptions): Record<string, unknown> {
    return toJSON(this, options)
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'puzzle-score': PuzzleScoreModel
  }
}
