import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\">\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\">\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/storylands/map-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/storylands/map-game.hbs"}});
import Component from '@glimmer/component'

export default class MapGame extends Component {
  playLesson(lessonId) {
    this.args.playLessonAction(lessonId)
  }

  playQuiz() {
    this.args.playQuizAction()
  }

  prevMap() {
    this.args.prevMapAction()
  }

  nextMap() {
    this.args.nextMapAction()
  }

  playBonusGame(gameId) {
    this.args.playBonusGameAction(gameId)
  }
}
