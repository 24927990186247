import { modifier } from 'ember-modifier'
import { assert, runInDebug } from '@ember/debug'

/**
 * Modifier which calls a listener function when a `LOAD_COMPLETE` event is fired by a caper activity.
 */
export default modifier(function caperLoadComplete(
  _element,
  [listener]: [EventListener],
  _named,
) {
  assert('listener must be a function', typeof listener === 'function')

  document.addEventListener(
    'LOAD_COMPLETE',
    (e) => {
      runInDebug(() => {
        console.debug(
          `%ccaper-load-complete-modifier clearing loading screen`,
          'color: red; font-size: 1.1rem',
        )
      })

      listener(e)
    },
    false,
  )

  return () => {
    document.removeEventListener('LOAD_COMPLETE', listener, false)
  }
})
