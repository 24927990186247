import Service, { service } from '@ember/service'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import { calculateMap } from 're-client/utils/progress-tools'

export default class StorylandsService extends Service {
  @service declare studentProgress: StudentProgressService

  get currentMap() {
    return this.studentProgress.storylandsCurrentMap
  }

  get currentLesson() {
    return this.studentProgress.storylandsCurrentLesson
  }

  get currentActivity() {
    return this.studentProgress.storylands.currentActivity
  }

  canPlayMap(map: number) {
    return this.currentMap >= map
  }

  currentActivityForLesson(lessonId: string) {
    if (parseInt(lessonId, 10) === this.currentLesson) {
      return this.currentActivity
    }
    return 1
  }

  canPlayLessonActivity(lesson: string | number, activity: string | number) {
    const { currentLesson, currentMap, currentActivity } = this

    const { lessonsPerMap } = config.studentProgress.progress.storylands
    if (typeof lesson === 'string') {
      lesson = Number(lesson)
    }
    if (typeof activity === 'string') {
      activity = Number(activity)
    }
    const lessonMap = calculateMap(lesson, lessonsPerMap)

    // Later Map
    if (lessonMap > currentMap) {
      return false
    }

    // Earlier map
    if (lessonMap < currentMap) {
      return true
    }

    // From here on, we're on the same map the lesson is progress-wise
    // Later lesson
    if (lesson > currentLesson) {
      return false
    }

    // Earlier lesson
    if (lesson < currentLesson) {
      return true
    }

    // Earlier or current activity
    if (activity <= currentActivity) {
      return true
    }

    // Only option left is to be a later activity
    return false
  }

  isQuizComplete(map: number) {
    return this.currentMap > map
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    storylands: StorylandsService
  }
}
