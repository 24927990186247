import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/house-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/house-game.hbs"}});
import Component from '@glimmer/component'

export default class HouseGame extends Component {
  updateItem(item) {
    this.args.updateItemAction(item)
  }

  goToChangeRoom() {
    this.args.showChangeRoomAction()
  }

  goToCritterBook() {
    this.args.showCrittersAction()
  }

  goToBookCase() {
    this.args.showBookCaseAction()
  }

  goToTrophies() {
    this.args.showAwardsAction()
  }

  goToGamesMachine() {
    this.args.showArcadeAction()
  }
}
