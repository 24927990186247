import Service from '@ember/service'
import config from 're-client/config/environment'
import ky from 'ky'

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export interface Entry {
  id?: string | undefined
  pages: unknown
}

export default class StoryFactoryService extends Service {
  async saveStoryEntry(entry: Entry) {
    const storyEntry = {
      story_factory_entry_pages_attributes: entry.pages,
      ...entry,
    }
    if (entry.id) {
      await ky.put(`story_factory_entries/${entry.id}`, {
        credentials: 'include',
        json: { story_factory_entry: storyEntry },
        prefixUrl,
      })
    } else {
      await ky.post('story_factory_entries', {
        credentials: 'include',
        json: { story_factory_entry: storyEntry },
        prefixUrl,
      })
    }
  }

  loadEntry(id: string) {
    return ky
      .get(`story_factory_entries/${id}`, {
        credentials: 'include',
        prefixUrl,
      })
      .json()
  }

  newEntry() {
    return ky
      .get('story_factory_entries/new', {
        credentials: 'include',
        prefixUrl,
      })
      .json()
  }

  loadContest(id: string) {
    return ky
      .get(`story_factory_contests/${id}`, {
        credentials: 'include',
        prefixUrl,
      })
      .json()
  }

  async voteForEntry(id: string, rating: string) {
    return ky
      .post(`story_factory_entries/${id}/story_factory_ratings`, {
        credentials: 'include',
        json: { rating },
        prefixUrl,
      })
      .json()
  }
}

declare module '@ember/service' {
  interface Registry {
    'story-factory': StoryFactoryService
  }
}
