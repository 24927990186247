import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type Store from '@ember-data/store'
import ky from 'ky'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'

interface UnknkownManifest {
  content: {
    variables: Record<string, unknown>
  }
}

export default class GamesIndexRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare store: Store

  get adapter() {
    return this.store.adapterFor('application')
  }

  override async model() {
    const personalBestUrl = this.adapter.buildURL(
      'arcade-game-score',
      'personal_bests',
    )
    const highScoresUrl = this.adapter.buildURL('arcade-game-score')

    const gamesUrl = this.urlMaker.urlForInteractive('reading', 'arcade')

    // Fetch all three manifests, and extract variable overrides from the second two.
    const [
      gamesManifest,
      {
        content: {
          variables: { personal_bests },
        },
      },
      {
        content: {
          variables: { high_scores },
        },
      },
    ] = await Promise.all([
      ky.get(gamesUrl).json<string>(),
      ky
        .get(personalBestUrl, {
          credentials: 'include',
        })
        .json<UnknkownManifest>(),
      ky
        .get(highScoresUrl, {
          credentials: 'include',
        })
        .json<UnknkownManifest>(),
    ])

    const interactiveConfig = await this.caperLoader.load([gamesManifest], {
      student: this.user.student,
      personal_bests,
      high_scores,
    })

    return {
      interactiveConfig,
    }
  }
}
