import Model, { attr } from '@ember-data/model'
import toJSON from 're-client/utils/to-json'
import type { ToJSONOptions } from 're-client/utils/to-json'

/**
 * Ember Data model which represents a shop item. Some items are simple images where other can
 * have complex animation which are usually achieved through dragonbones animations
 * For more information please consult the content team.
 *
 * @class ItemModel
 *
 * @property {String} code - unique item code like head_43
 * @property {String} state - tbd
 * @property {String} department - can be something like head, or arms
 * @property {String} animationJson - bones js for the caper activity
 * @property {String} animationUrl - sprite map image url needed for caper activity
 * @property {String} free - indicates if an item is free of change eg awarded as a gift
 * @property {StudentModel} - hasOne relationship to the StudentModel
 */
export default class ItemModel extends Model {
  @attr('string')
  declare code: string

  @attr('string')
  declare state: string

  @attr('string')
  declare department: string

  @attr('string')
  declare animationJson: string

  @attr('string')
  declare animationUrl: string

  @attr('string')
  declare imageUrl: string

  @attr('string')
  declare free: string

  override toJSON(options?: ToJSONOptions): Record<string, unknown> {
    return toJSON(this, options)
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    item: ItemModel
  }
}
