import Service, { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type { Log } from '@blakeelearning/log'
import type { ErrorLike } from 're-client/utils/errors'
import { isActionableError, isErrorLike } from 're-client/utils/errors'

export type UnhandledError = Partial<ErrorLike>

export default class ErrorHandlerService extends Service {
  @service declare router: RouterService

  @service declare log: Log

  /**
   * This is a handler for any time Content code calls
   * `externalController.unhandledError`, indicating that it has encountered an
   * error it cannot handle.  It:
   *
   * 1. logs the error to rollbar
   * 2. transitions to an error route
   *
   * Because these calls come from Content, we can't depend on any instanceof
   * checks.
   */
  handleContentUnhandledError({
    name = 'Error',
    message = 'Unknown Error',
  }: UnhandledError): void {
    this.handleError(
      `Unhandled ${name} returned from content`,
      new Error(message),
    )
  }

  /**
   * This is a general-purpose function for handling errors.  It accepts an
   * error message and an optional error object.  With these it:
   *
   * 1. logs the error to rollbar, unless the error is considered un-actionable
   * 2. transitions to an error route
   *
   * @see {@link #errorIsActionable}
   */
  handleError(message: string, error?: unknown): void {
    this.logErrorIfActionable(message, error)

    if (isErrorLike(error)) {
      void this.router.transitionTo('application-error', error)
    } else {
      void this.router.transitionTo('application-error')
    }
  }

  /**
   * This is a general-purpose handler for any time that controller code
   * encounters an unrecoverable error.  This is any error that occurs in
   * re-client in the course of actioning a request from Content that
   * we know that the Content code has no means of recovering from. It:
   *
   * 1. logs the error to rollbar
   * 2. transitions to an error route
   */
  handleUnrecoverableError(error: unknown): void {
    let message: string
    if (isErrorLike(error)) {
      message = error.message
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = 'Unknown error'
    }
    this.handleError(`Unrecoverable error: ${message}`, error)
  }

  /**
   * This will log errors that are considered actionable.
   *
   * @see {@link #errorIsActionable}
   */
  logErrorIfActionable(message: string, error?: unknown): void {
    this.log.log(message)

    if (isActionableError(error)) {
      this.log.error(error)
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'error-handler': ErrorHandlerService
  }
}
