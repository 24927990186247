import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type Store from '@ember-data/store'
import { allowedDepartment } from 're-client/utils/department'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type ThemeService from 're-client/services/theme'
import type { ModelFor, ParamsFor } from 're-client/utils/route-model'
import type ShopDepartmentRoute from 're-client/routes/shop/department'
import type ShopRoute from 're-client/routes/shop'

export default class ShopDepartmentCatalogueRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare theme: ThemeService

  @service
  declare store: Store

  /**
   * The catalogue interactive is slightly special as we are loading multiple manifest files
   * The interactive is based on the `shopCatalogue` manifest and additionally augmented with
   * a month specific manifest file.
   *
   * @returns {Promise<{shopModel: *, interactive: *, department: *}>}
   */
  override async model() {
    const { department } = this.paramsFor(
      'shop.department',
    ) as ParamsFor<ShopDepartmentRoute>
    const validDepartment = allowedDepartment(department)
    const items = await this.store.findAll('item')
    const studentItemsEmberArray = items.map((item) => item.get('code'))
    const studentItems = [...studentItemsEmberArray]
    const theme = this.theme.currentTheme

    // variablesct the departments data from shop
    const shopModel = this.modelFor('shop') as ModelFor<ShopRoute>

    const departments: unknown =
      // @ts-expect-error we don't have a type for this
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      shopModel.interactiveConfig.manifest.content?.variables?.departments

    const variables = {
      student: this.user.student,
      departments,
      department: validDepartment,
      studentItems,
      theme: this.theme.currentTheme,
      timeToNextMonth: this.theme.timeToNextTheme(),
    }

    const manifests = [
      // theme specific manifest url
      this.urlMaker.urlForInteractive(
        'reading',
        `shop_catalogue_${theme}_${department}`,
      ),
      // base catalogue manifest url
      this.urlMaker.urlForInteractive('reading', 'shop_catalogue'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      shopModel,
      department,
      interactiveConfig,
    }
  }
}
