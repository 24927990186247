import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import preloadImages from 're-client/utils/preload-images'
import reggieImages from 're-client/theme-images'
import type ThemeService from 're-client/services/theme'

export default class IndexRoute extends Route {
  @service
  declare theme: ThemeService

  override async model() {
    // preload images
    const images = []

    const { currentMonth } = this.theme
    const reggieThemeImage = reggieImages.months[currentMonth]
    const reggieImage = reggieImages.default

    images.push(reggieImage)

    if (reggieThemeImage) {
      images.push(reggieThemeImage)
    }

    await preloadImages(...images)

    return {
      reggieThemeImage,
      reggieImage,
    }
  }
}
