export default [
  {
    id: 'teddy_bear',
    puzzle_name: 'Teddy bear',
    score: 76,
  },
  {
    id: 'blue_squares',
    puzzle_name: 'Blue Squares',
    score: 60,
  },
  {
    id: 'baby_animals',
    puzzle_name: 'Baby animals',
    score: 66,
  },
  {
    id: 'making_music',
    puzzle_name: 'Making music',
    score: 96,
  },
  {
    id: 'more_than_one',
    puzzle_name: 'More than One',
    score: 54,
  },
  {
    id: 'alphabet_match',
    puzzle_name: 'Alphabet Match',
    score: 38,
  },
  {
    id: 'colour_code',
    puzzle_name: 'Colour Code',
    score: 27,
  },
  {
    id: 'finish_the_alien',
    puzzle_name: 'Finish the alien',
    score: 14,
  },
  {
    id: 'opposites',
    puzzle_name: 'Opposites',
    score: 73,
  },
  {
    id: 'name_it',
    puzzle_name: 'Name it',
    score: 75,
  },
  {
    id: 'squares',
    puzzle_name: 'Squares',
    score: 59,
  },
  {
    id: 'describe_it',
    puzzle_name: 'Describe it',
    score: 107,
  },
  {
    id: 'both_ways',
    puzzle_name: 'Both Ways',
    score: 64,
  },
  {
    id: 'dressing_up',
    puzzle_name: 'Dressing up',
    score: 66,
  },
  {
    id: 'what_is_it',
    puzzle_name: 'What is it',
    score: 63,
  },
  {
    id: 'sense_it',
    puzzle_name: 'Sense it',
    score: 66,
  },
  {
    id: 'vegetables',
    puzzle_name: 'Vegetables',
    score: 65,
  },
  {
    id: 'animal_colours',
    puzzle_name: 'Animal Colours',
    score: 63,
  },
  {
    id: 'read_it',
    puzzle_name: 'Read it',
    score: 73,
  },
  {
    id: 'fingers',
    puzzle_name: 'Fingers',
    score: 15,
  },
  {
    id: 'animal_grid',
    puzzle_name: 'Animal grid',
    score: 36,
  },
  {
    id: 'red_squares',
    puzzle_name: 'Red Squares',
    score: 36,
  },
  {
    id: 'green_squares',
    puzzle_name: 'Green Squares',
    score: 36,
  },
  {
    id: 'hidden_words',
    puzzle_name: 'Hidden Words',
    score: 29,
  },
  {
    id: 'number_nuts',
    puzzle_name: 'Number Nuts',
    score: 12,
  },
  {
    id: 'do_you_know',
    puzzle_name: 'Do You Know',
    score: 35,
  },
  {
    id: 'do_it',
    puzzle_name: 'Do it',
    score: 46,
  },
  {
    id: 'arrows',
    puzzle_name: 'Arrows',
    score: 48,
  },
  {
    id: 'mazes',
    puzzle_name: 'Mazes',
    score: 19,
  },
  {
    id: 'song_lines',
    puzzle_name: 'Song Lines',
    score: 2,
  },
  {
    id: 'transport',
    puzzle_name: 'Transport',
    score: 66,
  },
  {
    id: 'animal_fun',
    puzzle_name: 'Animal fun',
    score: 54,
  },
]
