import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/lessons/lesson-activity-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/lessons/lesson-activity-game.hbs"}});
import Component from '@glimmer/component'

export default class LessonActivityGame extends Component {
  /**
   * Save lesson activity progress
   *
   * @instance
   * @memberOf LessonActivityComponent
   */
  async saveProgress(rewardStudent) {
    const response = await this.args.saveProgressAction(rewardStudent)
    this._handleNextableResponse(response)
  }

  /**
   * Transition to the next activity if there is one, or got to back to the map
   *
   * @instance
   * @memberOf LessonActivityComponent
   */

  next() {
    this.args.nextAction()
  }

  /**
   * Adds one to the student's eggs count
   */
  incrementScore(amount = 1) {
    this.args.incrementScoreAction(amount)
  }

  _handleNextableResponse(response) {
    const data = { reward: { eggs: 0 }, ...response }
    this.interactive.callInteractionMethod('nextable', data)
  }
}
