import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'

/**
 * The sample lesson route
 */
export default class SampleLessonActivityRoute extends Route {
  @service()
  caperLoader

  async model() {
    const lesson = this.modelFor('sample.lesson')
    const manifests = [lesson.findActivityManifest(1)]
    const variables = {}

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
