import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type CaperLoaderService from 're-client/services/caper-loader'

export default class CrittersCritterRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model({ critter_id: critterId }: Record<string, string>) {
    const variables = {
      student: this.user.student,
      skipSaveProgress: true,
    }

    const manifests = [
      this.urlMaker.urlForInteractive('reading/video', critterId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
