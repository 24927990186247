import Route from '@ember/routing/route'
import type { RouteInfoWithAttributes } from '@ember/routing/route-info'
import type Transition from '@ember/routing/transition'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import type MetricsService from 'ember-metrics'
import { isForbiddenAuthError } from '@blakeelearning/auth/utils/is-auth-error'
import type { AuthError } from '@blakeelearning/auth/utils/is-auth-error'
import { isNetworkError, isAuthorizationError } from 're-client/utils/errors'
import { runInDebug } from '@ember/debug'
import { isApolloError } from '@apollo/client/core'
import { graphql } from 're-client/graphql'
import type ApolloService from 're-client/services/apollo'
import type LoadingUiService from 're-client/services/loading-ui'
import type { Log } from '@blakeelearning/log'
import config from 're-client/config/environment'
import type ThemeService from 're-client/services/theme'
import type RouterService from '@ember/routing/router-service'
import type Refresher from '@blakeelearning/app-refresher/refresher/service'
import type OfflineScreenService from 're-client/services/offline-screen'
import type UserService from 're-client/services/user'
import type SessionService from 're-client/services/session'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type LongSessionKiller from '@blakeelearning/app-refresher/long-session-killer/service'
import type SessionTracker from '@blakeelearning/app-refresher/session-tracker/service'
import type TimeOnTaskService from 're-client/services/time-on-task'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'
import type ActivityService from 're-client/services/activity'
import type { FeatureService } from '@blakeelearning/features'
import type DebugModeService from 're-client/services/debug-mode'
import type DeviceNetwork from '@blakeelearning/device/device/network/service'
import type AssignmentsService from 're-client/services/assignments'
import type QuestService from 're-client/services/quest'
import type EssentialQuestService from 're-client/services/essential-quest'
import { bind } from 'bind-event-listener'

declare global {
  interface Window {
    CAPER_FEATURES?: string[]
    CAPER_MUSIC_ENABLED?: boolean | undefined
    environment?: string
  }
}

interface Params {
  qaMonth?: string
}

const initialAppQueryDocument = graphql(/* GraphQL */ `
  query GetInitialAppData {
    student {
      ...StudentUserFragment
      ...AssignmentTask
      ...StudentProgressFragment
      ...StudentQuestDataFragment
      ...QuestGoalEssentialDataFragment
    }
  }
  query ReadingActivities {
    readingActivities {
      ...ReadingActivityFragment
    }
  }
`)

function isGuestSession(transition: Transition) {
  return transition.targetName?.match(/^sample\.lesson/)
}

/**
 * The application router makes sure the session is loaded before sub-routes
 * are able to do things, so they and their controllers can all assume that a
 * session with a student model property will always exist.
 */
export default class ApplicationRoute extends Route<void, Params> {
  override queryParams = { qaMonth: { as: 'qa_month', refreshModel: true } }

  qaMonth = null

  @service
  declare apollo: ApolloService

  @service
  declare log: Log

  @service
  declare theme: ThemeService

  @service
  declare metrics: MetricsService

  @service
  declare router: RouterService

  @service
  declare refresher: Refresher

  @service
  declare offlineScreen: OfflineScreenService

  @service
  declare user: UserService

  @service
  declare session: SessionService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare longSessionKiller: LongSessionKiller

  @service
  declare sessionTracker: SessionTracker

  @service
  declare timeOnTask: TimeOnTaskService

  @service('device/detection')
  declare deviceDetection: DeviceDetection

  @service
  declare activity: ActivityService

  @service
  declare features: FeatureService

  @service
  declare debugMode: DebugModeService

  @service('device/network')
  declare network: DeviceNetwork

  @service
  declare loadingUi: LoadingUiService

  @service
  declare assignments: AssignmentsService

  @service
  declare quest: QuestService

  @service
  declare essentialQuest: EssentialQuestService

  removePinchToZoom = () => {}

  constructor(properties: Record<string, unknown>) {
    super(properties)

    this.router.on('routeWillChange', (transition) => {
      this._showLoadingScreen(transition)
    })

    this.router.on('routeDidChange', (transition) => {
      this._refreshAndTrack()
      this._hideLoadingScreen(transition)
    })

    if (this.deviceDetection.isIos) {
      this.removePinchToZoom = bind(document, {
        type: 'touchmove',
        listener(event) {
          if ('scale' in event && event.scale !== 1) {
            event.preventDefault()
          }
        },
        options: { passive: false },
      })
    }
  }

  override willDestroy() {
    this.removePinchToZoom()
  }

  _showLoadingScreen(transition: Transition) {
    const metadata = transition.to?.metadata
    let optOutLoadingScreen = false

    if (
      typeof metadata === 'object' &&
      metadata !== null &&
      'optOutLoadingScreen' in metadata &&
      typeof metadata.optOutLoadingScreen === 'boolean'
    ) {
      optOutLoadingScreen = metadata.optOutLoadingScreen
    }

    // Show the loading screen for all routes except those that specifically opt out or if debug mode is enabled
    if (optOutLoadingScreen || this.debugMode.enabled) return
    this.loadingUi.show()
  }

  _hideLoadingScreen(transition: Transition) {
    if (
      transition.to?.parent?.name === transition.from?.parent?.name &&
      transition.to?.parent?.name === 'shop.department'
    ) {
      runInDebug(() => {
        console.debug(
          `%c transitioning between shop departments, hiding loading screen.`,
          'color: red; font-size: 1.1rem',
        )
      })
      this.loadingUi.hide()
      return
    }

    const metadata = transition.to?.metadata
    let optOutLoadingScreen = false

    if (
      typeof metadata === 'object' &&
      metadata !== null &&
      'optOutLoadingScreen' in metadata &&
      typeof metadata.optOutLoadingScreen === 'boolean'
    ) {
      optOutLoadingScreen = metadata.optOutLoadingScreen
    }

    // Early return to avoid traversing the list of routeInfos when the loading screen shoudn't be enabled, hiding it anyway just in case.
    if (optOutLoadingScreen || this.debugMode.enabled) {
      this.loadingUi.hide()
      return
    }

    // Search the list of `routeInfo` objects from the top down using `find()` for a resolved model containing an `interactiveConfig` to determine if the loading screen should be hidden later once the content interactive is done loading. For Caper a `LOAD_COMPLETE` event is fired which we catch in the `caper-load-complete` modifer. For Jester, we setup a `readyForUserInput` action on the controller.
    const shouldWaitForContentInteractiveToLoad = transition.to?.find(
      (routeInfo) => {
        let willHideLoadingScreenManually = false

        if (
          typeof routeInfo.metadata === 'object' &&
          routeInfo.metadata !== null &&
          'willHideLoadingScreenManually' in routeInfo.metadata &&
          typeof routeInfo.metadata.willHideLoadingScreenManually === 'boolean'
        ) {
          willHideLoadingScreenManually =
            routeInfo.metadata.willHideLoadingScreenManually
        }

        if (willHideLoadingScreenManually) return true
        const resolvedModel = (routeInfo as RouteInfoWithAttributes).attributes
        if (!resolvedModel || typeof resolvedModel !== 'object') return false
        runInDebug(() => {
          if ('interactiveConfig' in resolvedModel) {
            console.debug(
              '%cFound "interactiveConfig" in model for %s route. Loading screen should be hidden when content is ready.',
              'color: red; font-size: 1.1rem',
              transition.to?.name,
            )
          }
        })
        return 'interactiveConfig' in resolvedModel
      },
    )
    // Don't hide the loading screen if we should wait for content to finish loading.
    if (shouldWaitForContentInteractiveToLoad) return

    this.loadingUi.hide()
  }

  override async beforeModel(transition: Transition) {
    if (isGuestSession(transition)) {
      await this.user.asGuest()
      this._setCaperMusicToggle(!this.user.student.backgroundMusicDisabled)
    }

    await this.session.setup(config.session)

    if (
      transition.to?.name === 'login' ||
      transition.to?.name === 'logout' ||
      transition.to?.name === 'unauthorised'
    ) {
      return
    }

    if (this.session.state.status === 'authenticated') {
      /**
       * This query combines all the data we need to setup the session, activities,
       * student-progress and assignment services which are initialized in the following Promise.all().
       * By running this query first we populate the apollo cache with all the data
       * in a single http request. The queries within in each service will then just hit the cache.
       */
      await this.apollo.query({
        query: initialAppQueryDocument,
      })
      await Promise.all([
        this.user.setup(),
        this.activity.setup(),
        this.assignments.setup(),
        this.quest.setup(),
        this.essentialQuest.setup(),
      ])
      this.releaseChecker.start()
      this.longSessionKiller.start()
      this.sessionTracker.start(this.user.student.remoteId)

      this._setCaperMusicToggle(!this.user.student.backgroundMusicDisabled)

      // TODO: This is a temporary fix for caper driving test showing cheat keys
      if (!this.features.isEnabled('debug_allowed')) {
        window.environment = 'production'
      }
    }

    this._addCaperFeatures()
  }

  override model(params: Params) {
    const qaMonth = Number(params.qaMonth)
    const date = new Date()

    if (!isNaN(qaMonth)) {
      date.setMonth(qaMonth)
    }

    this.theme.setDate(date)
  }

  override async afterModel(_model: unknown, transition: Transition) {
    if (
      isGuestSession(transition) ||
      transition.to?.name === 'login' ||
      transition.to?.name === 'logout' ||
      transition.to?.name === 'unauthorised'
    ) {
      return
    }

    if (this.session.state.status === 'authenticated') {
      if (this.assignments.hasTask) {
        await this.assignments.checkTransition(transition)
      } else if (this.essentialQuest.active) {
        this.essentialQuest.checkTransition(transition)
      }
    } else if (this.session.state.status === 'unauthenticated') {
      void this.router.replaceWith('login')
    }
  }

  _refreshAndTrack() {
    // We never want to refresh the page when we are offline - if we do so the app
    // will disappear and the browser offline screen is shown.
    // todo unfortunately safari (ios) has some timing issues and it can happen
    //      that the internet is gone but the offline event is not fired yet, which
    //      leads the following code to refresh which puts us in a state where the ios app is gone
    //      with no refresh button sight.
    if (this.network.status.isOffline) return
    const willRefresh = this.refresher.refreshIfSafeAndScheduled(
      this.router.currentRouteName,
    )
    if (!willRefresh) this._trackPage()
  }

  _trackPage() {
    const page = this.router.currentURL
    const title = this.router.currentRouteName || 'unknown'

    this.metrics.trackPage({ page, title })
    this.timeOnTask
      .pingTaskByRoute(this.router.currentRouteName)
      .catch((reason: unknown) => {
        this.log.error(reason as Error)
      })
  }

  @action
  override willTransition(transition: Transition) {
    if (this.session.state.status === 'unauthenticated') {
      this.router.replaceWith('unauthorised')
    } else if (this.assignments.hasTask) {
      void this.assignments.checkTransition(transition)
    } else if (this.essentialQuest.active) {
      this.essentialQuest.checkTransition(transition)
    }
  }

  // Route error handlers are called synchronously so cannot return a promise
  // which also means we can't use async/await
  @action
  override error(error: unknown, transition: Transition) {
    // check if the network is down
    if (this.network.status.isOffline || isNetworkError(error)) {
      // We can assume that the when an abort errors happens that we are offline
      // we cant really rely on events being fired timely, in safari for instance the offline
      // event is emitted delayed by 1 - 2 seconds. We do this here as the error action is executed
      // because the user has performed action which created an error. When we receive errors like
      // AdapterAbort errors then the application is broken at this point, and there are 2 ways to recover from that.
      // 1. if we are back online do a refresh of the page / url stays the same
      // 2. somehow transition to the same route and reload all model hooks.
      //
      // this solution simply reloads for now.
      this.offlineScreen.display()

      // return false wont trigger the error screen to be rendered, the offline-screen component
      // will show instead once the 2 conditions are met. (component is rendered on the layout template)
      // 1. this.offlineScreen.display()  is called
      // 2. the network offline event was fired. (this can be delayed)
      //
      // in case the network drops when the application route first loaded we want the application error
      // template to load. We can check the current transition if we have from location and if its null we know
      // that this is the initial load and therefor render the application-error template.
      return transition.from === null
    }

    if (
      isAuthorizationError(error) ||
      isForbiddenAuthError(error as AuthError)
    ) {
      if (transition.from) {
        // transition user to the unauthorised error page
        void this.router.replaceWith('unauthorised')
      } else {
        // transition user to login on if this is the initial load
        void this.router.replaceWith('login')
      }

      return false
    }

    // Do not log Apollo errors as they are already logged by the Apollo client
    if (!isApolloError(error as Error)) {
      this.log.error(error as Error)
    }

    return true
  }

  /**
   * Caper knows to look for this global variable when loading an activity or
   * variable and use it to control its self-resizing behaviour.
   */
  _addCaperFeatures() {
    // const client = this.features.getClient()
    // const features = client.getObjectValue<string[]>('caper-feature-flags', [])
    const features = []

    for (const flag of ['caper_self_resize', 'story_factory_swear_filter']) {
      if (this.features.isEnabled(flag)) {
        features.push(flag)
      }
    }

    window.CAPER_FEATURES = features
  }

  /**
   * Caper will check this global variable to determine if it should play music.
   */
  _setCaperMusicToggle(value: boolean) {
    window.CAPER_MUSIC_ENABLED = value
  }
}
