import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/play/play-room.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/play/play-room.hbs"}});
import Component from '@glimmer/component'

export default class PlayRoom extends Component {
  showBook(name) {
    this.args.showBookAction(name)
  }

  showGame(game, data) {
    this.args.showGameAction(game, data)
  }

  showVideo(name) {
    this.args.showVideoAction(name)
  }
}
