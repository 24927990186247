import type { FeatureService } from '@blakeelearning/features'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import type ReadingGoalsService from 're-client/services/reading-goals'

export default class MyReadingGoalsIndexRoute extends Route {
  @service declare features: FeatureService

  @service declare router: RouterService

  @service declare readingGoals: ReadingGoalsService

  override beforeModel() {
    if (!this.features.isEnabled('my_reading_goals')) {
      void this.router.transitionTo('/')
    }
  }

  override async model() {
    const quizTileList = []
    const milestoneList = []
    let lastCompletedQuizTile = null

    const quizList = await this.readingGoals.getQuizzes()
    const { summaries, minimumLevel, secondsToNextWeek } =
      await this.readingGoals.getRecord()

    for (const item of quizList) {
      const { __typename, ...quiz } = item

      // skip quizzes from other levels
      if (quiz.level !== minimumLevel) {
        continue
      }

      const quizSummary = summaries.get(quiz.id)

      const quizTile = {
        ...quiz,
        attemptsThisWeek: 0,
        eggsReward: 0,
        passed: false,
        secondsToPass: null,
        timestamp: '',
      }

      if (quiz.id === this.readingGoals.lastCompletedQuizId) {
        lastCompletedQuizTile = { ...quizTile, ...quizSummary }

        // clear lastCompletedQuizId after create the completed quiz tile
        this.readingGoals.lastCompletedQuizId = ''
      }

      if (quizSummary === undefined) {
        // untouched quizzes for reading goals list
        if (quizTileList.length < 4) {
          quizTileList.push(quizTile)
        }
      } else {
        if (quizSummary.passed) {
          // completed quizzes for milestones list
          milestoneList.push({ ...quizTile, ...quizSummary })
        } else {
          // uncompleted quizzes for reading goals list
          if (quizTileList.length < 4) {
            quizTileList.push({ ...quizTile, ...quizSummary })
          }
        }
      }
    }

    const variables = {
      playIntro: false,
      quizTileList,
      milestoneList,
      secondsToNextWeek,
      lastCompletedQuizTile,
    }

    const manifests = [['index', 'activities/mrg-menu']]

    return { variables, manifests }
  }
}
