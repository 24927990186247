import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

export default class SpellingFinishedReSpellingRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  override beforeModel() {
    if (this.studentProgress.isAreaLocked('spelling')) {
      void this.router.transitionTo('index')
    } else if (
      this.studentProgress.spellingCurrentMap <= config.APP.lastSpellingMap
    ) {
      void this.router.transitionTo(
        'spelling.map',
        this.studentProgress.spellingCurrentMap,
      )
    }
  }

  override async model() {
    const variables = { student: this.user.student }
    const manifests = [
      this.urlMaker.urlForInteractive('spelling', 'finished_skills_bank'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
