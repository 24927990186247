import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/lessons/finished-re-lessons.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/lessons/finished-re-lessons.hbs"}});
import Component from '@glimmer/component'

export default class FinishedReLessons extends Component {
  goToSkillsBank() {
    this.args.goToSkillsBankAction()
  }

  goToStorylands() {
    this.args.goToStorylandsAction()
  }

  goToIndex() {
    this.args.goToIndexAction()
  }

  goToReadingEggspress() {
    this.args.goToReadingEggspressAction()
  }

  goToPrevMap() {
    this.args.goToPrevMapAction()
  }
}
