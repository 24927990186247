import { inject as service } from '@ember/service'
import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

interface RouteModel {
  interactiveConfig: InteractiveConfig
  puzzleId: string
}

interface RouteParams {
  puzzle_id: string
}

export default class PuzzlesPuzzleRoute extends Route<RouteModel, RouteParams> {
  @service
  declare user: UserService

  @service
  declare store: Store

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model({ puzzle_id: puzzleId }: RouteParams) {
    const emberPuzzleScores = await this.store.findAll('puzzle-score')
    const puzzleScore = emberPuzzleScores.find(
      (value) => value.id === puzzleId,
    ) ?? { score: 0 }
    const variables = { highScore: puzzleScore.score }

    const manifests = [
      this.urlMaker.urlForInteractive('puzzle-park-puzzle', puzzleId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
      puzzleId,
    }
  }
}
