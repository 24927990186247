import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type LocationTrackerService from 're-client/services/location-tracker'
import ky from 'ky'

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class MyProgramRoute extends Route {
  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare locationTracker: LocationTrackerService

  override async model() {
    const response = await ky
      .get('books', {
        credentials: 'include',
        prefixUrl,
      })
      .json<{
        recommended_books: unknown
        books_read: unknown
      }>()

    const books = {
      recommendedBooks: response.recommended_books,
      booksRead: response.books_read,
    }

    return {
      books,
    }
  }

  override afterModel() {
    this.locationTracker.setCurrentRoute('my-program', [])
  }
}
