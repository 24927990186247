import { registerDestructor } from '@ember/destroyable'
import type Owner from '@ember/owner'
import { service } from '@ember/service'
import Modifier, { type ArgsFor, type PositionalArgs } from 'ember-modifier'
import { gsap, Sine, Linear } from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import type ActivitySidebarService from 're-client/services/activity-sidebar'
import type SoundsService from 're-client/services/sounds'

interface FlyingEggsAnimationSignature {
  Element: HTMLElement
  Args: {
    Positional: [eggsToAward: number[] | undefined]
  }
}

function cleanup(instance: FlyingEggsAnimationModifier) {
  instance.timeline.kill()
}

export default class FlyingEggsAnimationModifier extends Modifier<FlyingEggsAnimationSignature> {
  @service
  declare activitySidebar: ActivitySidebarService

  @service
  declare sounds: SoundsService

  element: HTMLElement | null = null

  canvasElement: HTMLElement | null = null

  timeline = gsap
    .timeline({
      paused: true,
      onComplete: () => {
        this.activitySidebar.close()
      },
    })
    .set(
      '.flying-points',
      {
        left: () => this.startPositionLeft,
        top: () => this.startPositionTop,
        autoAlpha: 1,
        height: 30,
        position: 'absolute',
        scale: 1,
        width: 25,
        zIndex: 2,
      },
      0.5,
    )
    .to(
      '.flying-points',
      {
        keyframes: [
          {
            duration: 1,
            motionPath: () => {
              const finalPoint = this.getFlightPath()
              return [
                { x: 0, y: 0 },
                { x: 0, y: finalPoint.y / 2 },
                { x: finalPoint.x / 2, y: finalPoint.y },
                finalPoint,
              ]
            },
            rotation: -360,
            ease: Sine.easeInOut,
            onComplete: () => {
              this.sounds.play('chaching')
            },
          },
          {
            duration: 0.25,
            scale: 1.5,
            autoAlpha: 0.25,
            ease: Linear.easeNone,
          },
          {
            duration: 0.25,
            scale: 0,
            autoAlpha: 0,
            ease: Linear.easeNone,
          },
        ],
        stagger: {
          each: 0.2,
          onComplete: () => {
            this.activitySidebar.incrementPoints()
          },
        },
      },
      0.5,
    )

  constructor(owner: Owner, args: ArgsFor<FlyingEggsAnimationSignature>) {
    super(owner, args)

    registerDestructor(this, cleanup)
  }

  get startPositionLeft() {
    let left = 0

    if (this.canvasElement) {
      left = this.canvasElement.offsetWidth / 2
    }

    return left
  }

  get startPositionTop() {
    let top = 0

    if (this.canvasElement) {
      top = this.canvasElement.offsetHeight / 2
    }

    return top
  }

  override modify(
    element: FlyingEggsAnimationSignature['Element'],
    [eggsToIncrement]: PositionalArgs<FlyingEggsAnimationSignature>,
  ) {
    cleanup(this)

    this.element = element
    this.canvasElement = document.querySelector('canvas')

    if (eggsToIncrement && eggsToIncrement.length > 0) {
      this.timeline.restart()
    }
  }

  getFlightPath(): gsap.Point2D {
    let path = { x: 0, y: 0 }

    if (this.canvasElement && this.element) {
      path = MotionPathPlugin.getRelativePosition(
        this.canvasElement,
        this.element,
        [0.5, 0.5],
        [0, -1],
      )
    }

    return path
  }
}
