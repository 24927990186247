import type { OperationVariables, WatchFragmentOptions } from '@apollo/client'
import { cell, resource, use } from 'ember-resources'

function Fragment<TFragmentData = unknown, TVariables = OperationVariables>(
  options: () => WatchFragmentOptions<TFragmentData, TVariables>,
) {
  return resource(({ owner, on }) => {
    const apollo = owner.lookup('service:apollo')
    const watchOptions = options()
    const { from, fragment } = watchOptions
    const query = apollo.watchFragment(watchOptions)

    const currentResult = cell<TFragmentData | undefined>()

    // Find the id of the object to read the initial fragment data
    const id = typeof from === 'string' ? from : apollo.cache.identify(from)

    if (id) {
      // Attempt to read the initial fragment data from the cache
      const initialResult = apollo.cache.readFragment({
        id,
        fragment: fragment,
      })

      if (initialResult) {
        // If there is initial data, set the current result
        currentResult.set(initialResult)
      }
    }

    const subscription = query.subscribe((result) => {
      if (result.complete) {
        currentResult.set(result.data)
      }
    })

    on.cleanup(() => {
      subscription.unsubscribe()
    })

    return currentResult
  })
}

/**
 * Watches the cache for changes to the provided frament.
 *
 * @returns a resource that will update with the latest fragment data.
 */
export function useFragment<
  TFragmentData = unknown,
  TVariables = OperationVariables,
>(
  parent: object,
  options: () => WatchFragmentOptions<TVariables, TFragmentData>,
) {
  return use(parent, Fragment(options))
}
