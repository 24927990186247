import ActiveModelAdapter from 'active-model-adapter'
import config from 're-client/config/environment'
import type ModelRegistry from 'ember-data/types/registries/model'

const {
  APP: { apiEndpoint, apiNamespace },
} = config

export default class ApplicationAdapter extends ActiveModelAdapter {
  override coalesceFindRequests = true

  // @ts-expect-error this can be undefined
  override get host() {
    return apiEndpoint
  }

  override get namespace() {
    return apiNamespace
  }

  override ajaxOptions(
    url: string,
    type: string,
    options: Record<string, unknown>,
  ) {
    return {
      ...super.ajaxOptions(url, type, options),
      credentials: 'include',
    }
  }

  override pathForType(modelName: keyof ModelRegistry) {
    switch (modelName) {
      case 'avatar':
        return 'avatar'
      case 'reading/book':
        return 'reading/book'
      default:
        return super.pathForType(modelName)
    }
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: ApplicationAdapter
  }
}
