import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type StorylandsService from 're-client/services/storylands'
import type { FeatureService } from '@blakeelearning/features'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import type StudentProgressService from 're-client/services/student-progress'
import type UrlMakerService from 're-client/services/url-maker'
import ky from 'ky'
import { cloneDeep } from 'lodash'
import type AssignmentsService from 're-client/services/assignments'
import type { Manifest } from '@blakeelearning/content-loader-core'

/**
 * Represents a lesson
 *
 * @class Lesson
 */
export class StorylandsLessonRouteModel {
  id: string
  activities: {
    lesson: StorylandsLessonRouteModel
    id: number
    json: Record<string, unknown>
  }[] = []

  get lessonId() {
    return this.id
  }

  constructor(lessonId: string, activities: Record<string, unknown>[]) {
    this.id = lessonId
    this.activities = activities.map((activityManifest, index) => ({
      lesson: this,
      id: index + 1,
      json: activityManifest,
    }))
  }

  /**
   * Find the next activity in the sequence, after the specified index
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the current activity index (1-based index)
   * @return {Object} A clone of the next activity object
   */
  nextActivity(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex + 1])
  }

  /**
   * Find an activity by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object at the specified index
   */
  findActivity(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex - 1])
  }

  /**
   * Find an activity manifest by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object's manifest at the specified index
   */
  findActivityManifest(activityIndex: number) {
    return this.findActivity(activityIndex)?.json as unknown as Manifest
  }
}

interface RouteParams {
  lesson_id: string
}

export default class StorylandsLessonRoute extends Route<
  StorylandsLessonRouteModel | undefined,
  RouteParams
> {
  @service
  declare router: RouterService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare assignments: AssignmentsService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare storylands: StorylandsService

  @service
  declare features: FeatureService

  @service
  declare user: UserService

  override async model({ lesson_id: lessonId }: RouteParams) {
    if (!this.ensureAccessAllowed(lessonId)) return

    const json = await ky
      .get(this.urlMaker.urlForInteractive('clinker-castle/lessons', lessonId))
      .json<{
        activities: {
          thumb:
            | {
                assetPath: string
              }
            | string
        }[]
      }>()

    // The `thumb` property of each activity is used by the activity sidebar
    for (const activity of json.activities) {
      if (
        activity.thumb &&
        typeof activity.thumb === 'object' &&
        'assetPath' in activity.thumb
      ) {
        const { assetPath } = activity.thumb
        activity.thumb = new URL(
          assetPath,
          config.contentLoader.caper.assetHosts.assets,
        ).toString()
      }
    }

    return new StorylandsLessonRouteModel(lessonId, json.activities)
  }

  /**
   * Access control to lesson based on the student's progress, executed after the model loads.
   *
   * Allows access if:
   *  - `shouldForceAccess()` evaluates to `true`
   *  - the authorisation policy allows access, based on student progress
   *
   * @instance afterModel
   * @memberOf LessonRoute
   * @param {Object} params The url params
   */

  ensureAccessAllowed(lessonId: string) {
    if (
      this.assignments.currentTask?.__typename === 'AssignmentTaskClinkerCastle'
    ) {
      return true
    }
    if (this.studentProgress.isAreaLocked('storylands')) {
      void this.router.transitionTo('index')
      return false
    }
    if (!this.storylands.canPlayLessonActivity(lessonId, 1)) {
      const { lastMap, lessonsPerMap } =
        config.studentProgress.progress.storylands
      const lastLesson = lastMap * lessonsPerMap
      const currentLesson =
        this.studentProgress.storylandsCurrentLesson > lastLesson
          ? lastLesson
          : this.studentProgress.storylandsCurrentLesson
      void this.router.transitionTo('storylands.lesson', currentLesson)
      return false
    }

    return true
  }
}
