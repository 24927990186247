import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

interface RouteParams {
  path: string
}

export default class PlayActivityRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model({ path }: RouteParams) {
    const variables = {
      student: this.user.student,
      skippable: false,
      show_end_buttons: false,
    }

    const [activityName, activityId] = path.split('/')

    const manifests = [
      this.urlMaker.urlForInteractive(
        `reading/activities/${activityName ?? path}`,
        activityId,
      ),
    ]
    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
