import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

export default class DrivingTestsRacingGameRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  override beforeModel() {
    if (!this.user.racingGameAvailable) {
      void this.router.replaceWith('driving-tests')
    }
    this.user.setRacingGameAvailability(false)
  }

  override async model() {
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('driving-tests', 'driving_test_game'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
