import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button type=\"button\" aria-label=\"Close\" {{on \"click\" @onClick}} ...attributes>\n  {{#if (has-block)}}\n    {{yield}}\n  {{else}}\n    <FaIcon @icon=\"xmark\" />\n  {{/if}}\n</button>", {"contents":"<button type=\"button\" aria-label=\"Close\" {{on \"click\" @onClick}} ...attributes>\n  {{#if (has-block)}}\n    {{yield}}\n  {{else}}\n    <FaIcon @icon=\"xmark\" />\n  {{/if}}\n</button>","moduleName":"re-client/components/dialog/close.hbs","parseOptions":{"srcName":"re-client/components/dialog/close.hbs"}});
import Component from '@glimmer/component'

interface DialogCloseSignature {
  Args: {
    onClick?: (event: MouseEvent) => void
  }
  Element: HTMLButtonElement
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class DialogCloseComponent extends Component<DialogCloseSignature> {}
