import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'

export interface StorylandsQuizData {
  currentQuestion: number
  correctAnswers: number[]
  incorrectAnswers: number[]
  score: number
}

/**
 * Service for holding the location state for the user.
 *
 * @method function
 * Route information is sent to the service via setCurrentRoute.
 * It is usually sent as part of the afterModel in routes.
 *
 * @method function
 * Route information is retrieved from the service via getPreviousRoute.
 * It returns both the saved route and the params.
 *
 * @method function
 * storylandsQuizValues is the value supplied to the storylands lesson quiz
 * caper activity. It is initially set to storylandsQuizDefaults but will be
 * updated when the caper activity calls setStorylandsQuizState which is after
 * each question answered in the lesson quiz.
 *
 * @class LocationTrackerService
 */
export default class LocationTrackerService extends Service {
  @tracked route = 'index'

  @tracked params: unknown[] = []

  @tracked storylandsQuizData: StorylandsQuizData | null = null

  get storylandsQuizValues() {
    return (
      this.storylandsQuizData ?? {
        currentQuestion: 1,
        correctAnswers: [],
        incorrectAnswers: [],
        score: 0,
      }
    )
  }

  setStorylandsQuizState(state: StorylandsQuizData | null) {
    this.storylandsQuizData = state
  }

  getStorylandsQuizState() {
    return this.storylandsQuizValues
  }

  getPreviousRoute() {
    return { route: this.route, params: this.params }
  }

  setCurrentRoute(route: string | undefined, params: unknown[]) {
    if (route) {
      this.route = route
      this.params = params
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'location-tracker': LocationTrackerService
  }
}
