import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'

export default class DrivingTestsRacingGameController extends Controller {
  @service
  declare operations: Operations

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @action
  async incrementScore(eggs = 1) {
    await this.operations.completeDrivingTestGame(
      { precinct: 'driving_tests' },
      { eggs },
    )
    await this.studentProgress.fetchProgress()
    this.user.incrementEggs(eggs)
  }

  @action
  exit() {
    this.router.transitionTo('driving-tests')
  }
}
