import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/driving-tests/quiz.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/driving-tests/quiz.hbs"}});
import Component from '@glimmer/component'

export default class Quiz extends Component {
  goToLobby() {
    this.args.goToLobbyAction()
  }

  completeDrivingTest(correctAnswers, incorrectAnswers) {
    this.args.completeDrivingTestAction(correctAnswers, incorrectAnswers)
  }

  startRace() {
    this.args.startRaceAction()
  }
}
