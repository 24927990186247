import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import type ModelRegistry from 'ember-data/types/registries/model'
import CaperAdapter from 're-client/adapters/caper'

const { assetHosts, releaseSha } = config.contentLoader.caper

/**
 * Adapter to build URLs for Caper activities.
 */
export default class CaperActivityAdapter extends CaperAdapter {
  @service declare user: UserService

  override get host() {
    return assetHosts.code
  }

  override get namespace() {
    return `readingeggs/releases/${releaseSha}/data/${this.user.locale}`
  }

  override pathForType(modelName: keyof ModelRegistry) {
    let path = super.pathForType(modelName)

    switch (modelName) {
      case 'reading/video':
        path = 'reading/activities/video'
        break
      case 'clinker-castle':
      case 'driving-tests':
      case 'story-factory':
      case 'reading':
      case 'spelling':
        path = `${path}/activities`
        break
    }

    return path
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'caper-activity': CaperActivityAdapter
  }
}
