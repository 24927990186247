import ENV from 're-client/config/environment'
import Service from '@ember/service'
import { waitFor } from '@ember/test-waiters'
import ky from 'ky'

const {
  APP: { apiNamespace, apiEndpoint, routeTaskIds },
} = ENV

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class TimeOnTaskService extends Service {
  /**
   * If a task id exists for the current route or one of its ancestors, post to re-student ToT endpoint
   * @param {String} routeName a period delimited String e.g. "storylands.lesson.activity"
   * @example
   *
   * // calling `pingTaskByRoute("storylands.lesson.activity")` will look for:
   * // 1. "storylands.lesson.activity"
   * // 2. "storylands.lesson"
   * // 3. "storylands"
   * // Because a task id exists for the "storylands" route, it will be sent in the post to re-student ToT endpoint
   */
  @waitFor
  async pingTaskByRoute(routeName: string) {
    const taskId = this._taskIdForRoute(routeName)

    if (taskId) {
      await ky.post('time_on_task', {
        credentials: 'include',
        json: { task: taskId, created_at: Date.now() },
        keepalive: true,
        retry: {
          limit: 10,
          methods: ['options', 'post'],
        },
        prefixUrl,
        timeout: false,
      })
    }
  }

  /**
   * Provide a route name and this will return the task id if it or a parent route is present in the routeTaskIds
   * mapping defined in the environment config.
   *
   * @param {String} routeName - the route to find a task id for
   * @returns {String} taskId - the task id associated with the route. Null if it or a parent route is not mapped.
   */
  _taskIdForRoute(routeName: string) {
    const flattenedRouteTaskIds = this._flattenedRouteTaskIds(routeTaskIds)

    if (!routeName) return null
    const routeNames = routeName.split('.')

    while (routeNames.length) {
      const key = routeNames.join('.')
      const taskId = flattenedRouteTaskIds[key]
      if (taskId) {
        return taskId
      }
      routeNames.pop()
    }
    return null
  }

  /**
   * Flatten the config structure to 'nested.route.name': 'taskId' for every route entry for easier parsing
   * flattened = {
   *   awards: 'awards',
   *   bookcase: 'house',
   *   'changing-room': 'avatar',
   *   house: 'house',
   *   ...
   * }
   * @param {Object} taskIds - task ids for each route
   * @returns {Object}
   */

  _flattenedRouteTaskIds(taskIds: Record<string, string[]>) {
    const flattened: Record<string, string> = {}

    Object.entries(taskIds).forEach(([taskId, routes]) => {
      routes.forEach((route) => {
        flattened[route] = taskId
      })
    })

    return flattened
  }
}

declare module '@ember/service' {
  interface Registry {
    'time-on-task': TimeOnTaskService
  }
}
