import { SessionService } from '@blakeelearning/auth'

export default SessionService

declare module '@blakeelearning/auth' {
  interface Session {
    expired_at: number | null
    issued_at: number | null
    ttl_seconds: number | null
    user_id: number | null
    user_roles: string[] | null
    user_type: string | null
    token: string | null
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService
  }
}
