import { helper } from '@ember/component/helper'
import podNames from 'ember-component-css/pod-names'

/**
 * A temporary solution for ember-component-css not working with glimmer components
 *
 * Allows manually adding the pod-styles class to glimmer components
 */
export function styleNamespace(params) {
  const [componentName] = params
  return typeof componentName === 'string' ? podNames[componentName] : ''
}

export default helper(styleNamespace)
