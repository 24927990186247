import Route from '@ember/routing/route'

interface RouteParams {
  book_id: string
}

export default class ReaderBookRoute extends Route<unknown, RouteParams> {
  override model(_: RouteParams) {
    // Ths empty model hook disables Ember's default behaviour, which
    // tries to load a model from the ember-data store based on the route's name
  }
}
