import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'

export default class HouseController extends Controller {
  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare store: Store

  get student() {
    return this.user.student
  }

  @action
  updateItem({ id, state }: { id: string; state: string }) {
    const itemRecord = this.store.peekRecord('item', id)

    if (itemRecord) {
      itemRecord.state = state
      itemRecord.save()
    }
  }

  @action
  showCritters() {
    this.router.transitionTo('critters')
  }

  @action
  showChangeRoom() {
    this.router.transitionTo('changing-room')
  }

  @action
  showBookCase() {
    this.router.transitionTo('bookcase')
  }

  @action
  showAwards() {
    this.router.transitionTo('awards')
  }

  @action
  showArcade() {
    this.router.transitionTo('games')
  }
}
