import { modifier } from 'ember-modifier'
import { bindAll } from 'bind-event-listener'
import gsap from 'gsap'

interface HoverWiggleSignature {
  Element: HTMLElement
  Args: {
    Positional: [
      /** Animates the selector element, or the element itself */
      selector?: string | undefined,
    ]
  }
}

function wiggleFocus(element: Element, selector?: string, scale = 1.05) {
  let target: Element = element

  if (selector) {
    target = element.querySelector(selector) ?? target
  }

  // slight wiggle by running 2 tweens of different length
  gsap.to(target, { duration: 1, scaleX: scale, ease: 'elastic.out' })
  gsap.to(target, {
    duration: 1.5,
    scaleY: scale,
    ease: 'elastic.out',
  })
}

function wiggleBlur(element: Element, selector?: string) {
  let target: Element = element

  if (selector) {
    target = element.querySelector(selector) ?? target
  }

  gsap.to(target, { duration: 2, scale: 1, ease: 'elastic.out' })
}

export default modifier<HoverWiggleSignature>(
  function hoverWiggle(element, positional) {
    if (element instanceof HTMLButtonElement && element.disabled) {
      return
    }

    const [selector] = positional

    return bindAll(element, [
      {
        type: 'focus',
        listener() {
          wiggleFocus(element, selector)
        },
      },
      {
        type: 'blur',
        listener() {
          wiggleBlur(element, selector)
        },
      },
      {
        type: 'mouseenter',
        listener() {
          wiggleFocus(element, selector)
        },
      },
      {
        type: 'mouseleave',
        listener() {
          wiggleBlur(element, selector)
        },
      },
    ])
  },
)
