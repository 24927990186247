import { getOwner } from '@ember/application'
import type LegacyJSONSerializer from 're-client/serializers/legacy-json'
import type Model from '@ember-data/model'
import type { Snapshot } from '@ember-data/store'

export interface ToJSONOptions {
  includeId?: boolean
}

// Implement our own version of the deprecated `toJSON` method for ember-data Models
// https://deprecations.emberjs.com/ember-data/v3.x/#toc_record-toJSON
// This method preserves the camelCase of the model, which is expected by Caper activities
export default function toJSON(
  record: Model,
  options: ToJSONOptions = {},
): Record<string, unknown> {
  const serializer = getOwner(record).lookup(
    'serializer:legacy-json',
  ) as LegacyJSONSerializer
  // ember-data doesn't expose a public API for snapshotting
  const snapshot = (
    record as Model & { _createSnapshot(): Snapshot }
  )._createSnapshot()
  return serializer.serialize(snapshot, options)
}
