import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>\n\n<DebugApp::ToggleDebug>\n  <ContentInteractive::Debug @contentActions={{this}} />\n</DebugApp::ToggleDebug>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>\n\n<DebugApp::ToggleDebug>\n  <ContentInteractive::Debug @contentActions={{this}} />\n</DebugApp::ToggleDebug>","moduleName":"re-client/components/content-interactives/plaza.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/plaza.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { service } from '@ember/service'
import type DebugModeService from 're-client/services/debug-mode'
import type WeeklyPollService from 're-client/services/weekly-poll'
import { debugAction } from 're-client/utils/debug'
import { fixtureFunctions } from 're-client/utils/weekly-poll/fake-data'
import type { FixtureFunctionKey } from 're-client/utils/weekly-poll/fake-data'
import { toDateString } from 're-client/utils/format-date'

const voteDataOptions: {
  label: string
  value: FixtureFunctionKey
}[] = [
  {
    label: 'First week voted',
    value: 'fixedResponseFirstWeekVoted',
  },
  {
    label: 'Last week voted',
    value: 'fixedResponseLastWeekVoted',
  },
  {
    label: 'Not voted',
    value: 'fixedResponseNotVoted',
  },
  {
    label: 'Zero results',
    value: 'fixedResponseZeroVotes',
  },
  {
    label: 'No previous week data',
    value: 'fixedResponseNoPreviousWeekData',
  },
]

export default class Plaza extends Component {
  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  @service
  declare debugMode: DebugModeService

  @service
  declare router: RouterService

  @service
  declare weeklyPoll: WeeklyPollService

  @action
  @debugAction({
    department: {
      type: 'select',
      options: [
        { label: 'Avatar', value: 'avatar' },
        { label: 'House', value: 'house' },
      ],
    },
  })
  gotoShopDepartment(department: string | { department: string }) {
    if (typeof department === 'object') {
      department = department.department
    }
    void this.router.transitionTo('shop.department', department)
  }

  @action
  @debugAction({
    day: {
      type: 'date',
      value: toDateString(new Date()),
    },
  })
  goToDay({ day }: { day: string }) {
    void this.router.transitionTo('plaza', { queryParams: { day } })
  }

  @action
  @debugAction({
    voteData: {
      type: 'select',
      options: voteDataOptions,
    },
  })
  setWeeklyVoteData({ voteData }: { voteData: FixtureFunctionKey }) {
    const fixture = fixtureFunctions[voteData]
    const response = fixture()

    this.interactive.callInteractionMethod('handlePollServerResponse', response)
  }

  @action
  @debugAction({
    dateVoted: {
      type: 'date',
      value: toDateString(new Date()),
    },
  })
  async resetWeeklyPoll({ dateVoted }: { dateVoted: string }) {
    const response = await this.weeklyPoll.resetWeeklyPoll(dateVoted)

    if (response)
      this.interactive.callInteractionMethod(
        'handlePollServerResponse',
        response,
      )
  }

  /**
   * Post weekly poll answer
   * @param {Number} pollId
   * @param {Number} answer
   * @returns {Object} returns a response with 3 results which feeds back into the results.
   */
  @action
  @debugAction({
    plazaPollId: {
      type: 'text',
    },
    votedAnswer: {
      type: 'number',
    },
  })
  async postAnswer(
    plazaPollId: string | { plazaPollId: string; votedAnswer: number },
    votedAnswer: number,
  ) {
    if (typeof plazaPollId === 'object') {
      votedAnswer = plazaPollId.votedAnswer
      plazaPollId = plazaPollId.plazaPollId
    }

    const response = await this.weeklyPoll.postWeeklyPoll({
      plazaPollId,
      votedAnswer,
    })

    if (response)
      this.interactive.callInteractionMethod(
        'handlePollServerResponse',
        response,
      )
  }

  /**
   * Update the weekly poll data
   * @returns {Object} returns a response with 3 results which feeds back into the results.
   */
  @action
  @debugAction()
  async getWeeklyPoll() {
    const response = await this.weeklyPoll.getWeeklyPoll()
    if (response)
      this.interactive.callInteractionMethod(
        'handlePollServerResponse',
        response,
      )
  }
}
