/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type { ModelFor } from 're-client/utils/route-model'
import type StorylandsMapRoute from 're-client/routes/storylands/map'

/**
 * storylands Map Controller
 *
 * @class StorylandsMapController
 */
export default class StorylandsMapController extends Controller {
  declare model: ModelFor<StorylandsMapRoute>

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @action
  playLesson(lessonId: string) {
    this.router.transitionTo('storylands.lesson', lessonId)
  }

  @action
  playBonusGame(gameId: string) {
    this.router.transitionTo('storylands.bonus-game', gameId)
  }

  @action
  playQuiz() {
    this.router.transitionTo('storylands.map.quiz', this.model!.mapId)
  }

  @action
  prevMap() {
    this.router.transitionTo('storylands.map', this.model!.previousMapId)
  }

  @action
  nextMap() {
    const mapId = this.model!.mapId
    const { lastMap } = config.studentProgress.progress.storylands
    if (
      mapId === lastMap &&
      this.studentProgress.storylandsCurrentMap > lastMap
    ) {
      this.router.transitionTo('storylands.finished-re-storylands')
    } else {
      this.router.transitionTo('storylands.map', this.model!.nextMapId)
    }
  }
}
