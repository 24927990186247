import Helper from '@ember/component/helper'
import { inject as service } from '@ember/service'
import translate from 're-client/utils/translate'

export default class TranslateHelper extends Helper {
  @service
  user

  compute([translationKey]) {
    const { locale } = this.user
    return translate(translationKey, locale)
  }
}
