import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'

export default class MysteryGiftController extends Controller {
  @service
  declare store: Store

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare router: RouterService

  @action
  next() {
    this.router.transitionTo(
      'spelling.map.index',
      this.studentProgress.spellingCurrentMap,
    )
  }

  /**
   * Method to call the re api to create a new free item for the current student
   * The records needs to be removed from the ember store as it will be loaded via the changing room again
   *
   * @param itemId
   * @returns {Promise<void>}
   */
  @action
  async grantMysteryItem(itemId: string) {
    const item = await this.store
      .createRecord('item', { code: itemId, free: 'true' })
      .save()
    item.unloadRecord()
    this.user.setMysteryGiftAvailability(false)
  }
}
