import progressionSequence from '@blakeelearning/progression-sequence-readingeggs/dist/progression.json'
import {
  ReadingGoalsQuizAreaEnum,
  type ReadingGoalsQuizFragmentFragment,
  ReadingGoalsQuizLevelEnum,
} from 're-client/graphql/graphql'

export default function* readingGaolsQuizzes() {
  for (const step of progressionSequence) {
    if (step.precinct === 'reading_goals') {
      let area: ReadingGoalsQuizAreaEnum
      let level: ReadingGoalsQuizLevelEnum

      switch (step.area) {
        case 'alphabet':
          area = ReadingGoalsQuizAreaEnum.Alphabet
          break

        case 'comprehension':
          area = ReadingGoalsQuizAreaEnum.Comprehension
          break

        case 'high_frequency_words':
          area = ReadingGoalsQuizAreaEnum.HighFrequencyWords
          break

        case 'phonics':
          area = ReadingGoalsQuizAreaEnum.Phonics
          break

        case 'phonological_awareness':
          area = ReadingGoalsQuizAreaEnum.PhonologicalAwareness
          break

        case 'print_concepts':
          area = ReadingGoalsQuizAreaEnum.PrintConcepts
          break

        case 'spelling':
          area = ReadingGoalsQuizAreaEnum.Spelling
          break

        case 'vocabulary':
          area = ReadingGoalsQuizAreaEnum.Vocabulary
          break

        default:
          throw new Error(`Unknown activity type: ${step.area}`)
      }

      switch (step.level) {
        case 'k':
          level = ReadingGoalsQuizLevelEnum.K
          break

        case '1':
          level = ReadingGoalsQuizLevelEnum.One
          break

        case '2':
          level = ReadingGoalsQuizLevelEnum.Two
          break

        default:
          throw new Error(`Unknown level: ${step.level}`)
      }

      yield {
        ...step,
        __typename: 'ReadingGoalsQuiz',
        area,
        level,
      } satisfies ReadingGoalsQuizFragmentFragment
    }
  }
}
