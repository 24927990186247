import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/play/play-activity.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/play/play-activity.hbs"}});
import Component from '@glimmer/component'

export default class PlayActivity extends Component {
  next() {
    this.args.nextAction()
  }

  async saveProgress() {
    this.interactive.callInteractionMethod('nextable', { reward: { eggs: 0 } })
  }
}
