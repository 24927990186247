export default {
  content: {
    variables: {
      presenting: {
        name: 'The Contest',
        end_date: '2024-05-30',
        winner: {
          author: 'Hugh L.',
          title: 'The Good Book',
          submitted: true,
          style: 5,
          pages: [
            {
              text: 'The Good Book',
              position: 0,
              page_position: 1,
            },
            {
              text: 'Some text',
              position: 1,
              page_position: 9,
            },
            {
              text: 'Some text',
              position: 2,
              page_position: 8,
            },
            {
              text: 'Some text',
              position: 3,
              page_position: 5,
            },
            {
              text: 'Some text',
              position: 4,
              page_position: 10,
            },
            {
              text: 'text here',
              position: 5,
              page_position: 2,
            },
            {
              text: 'Some text',
              position: 6,
              page_position: 4,
            },
          ],
        },
        state: 'presenting',
        words: [
          'ate',
          'barbeque',
          'carrot',
          'chickens',
          'eating',
          'farm',
          'fish',
          'food',
          'handful',
          'hungry',
          'lunch',
          'puppies',
          'rabbit',
          'sausages',
          'scatter',
          'sprinkle',
          'throw',
          'turtle',
          'yummy',
          'water',
        ],
        images: [
          'feed_the_animals_1',
          'feed_the_animals_2',
          'feed_the_animals_3',
          'feed_the_animals_4',
          'feed_the_animals_5',
          'feed_the_animals_6',
          'feed_the_animals_7',
          'feed_the_animals_8',
          'feed_the_animals_9',
          'feed_the_animals_10',
        ],
        shortlist: [
          {
            author: 'Vishan W.',
            title: 'John and Katie Feed the Farm!',
            submitted: true,

            style: 6,
            pages: [
              {
                text: 'Some text',
                position: 0,
                page_position: 6,
              },
              {
                text: 'Some text',
                position: 1,
                page_position: 4,
              },
              {
                text: 'Some text',
                position: 2,
                page_position: 8,
              },
              {
                text: 'Some text',
                position: 3,
                page_position: 5,
              },
              {
                text: 'Some text',
                position: 4,
                page_position: 10,
              },
              {
                text: 'Some text',
                position: 5,
                page_position: 9,
              },
              {
                text: 'Some text',
                position: 6,
                page_position: 7,
              },
            ],
          },
          {
            author: 'Ruby A.',
            title: "Hannah's First Day At The Farm",
            submitted: true,

            style: 6,
            pages: [
              {
                text: "Hannah's First Day At The Farm",
                position: 0,
                page_position: 4,
              },
              {
                text: 'Some text',
                position: 1,
                page_position: 9,
              },
              {
                text: 'Some text',
                position: 2,
                page_position: 6,
              },
              {
                text: 'Some text',
                position: 3,
                page_position: 8,
              },
              {
                text: 'Some text',
                position: 4,
                page_position: 10,
              },
              {
                text: 'Moar text',
                position: 5,
                page_position: 7,
              },
              {
                text: 'Some text',
                position: 6,
                page_position: 5,
              },
            ],
          },
        ],
      },
    },
    images: [
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_1-fp-c88859f7.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_2-fp-e2c2b873.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_3-fp-8e4ab5c1.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_4-fp-6214258a.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_5-fp-e315e137.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_6-fp-646f1c50.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_7-fp-12d03b49.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_8-fp-85595584.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_9-fp-95cf1bbc.jpg',
      'https://assets.static.readingeggs.com/story_factory/feed_the_animals/feed_the_animals_10-fp-3d0abb4d.jpg',
    ],
  },
}
