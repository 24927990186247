import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type LessonsService from 're-client/services/lessons'
import type { FeatureService } from '@blakeelearning/features'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ErrorLike } from 're-client/utils/errors'

export default class LessonsMapIndexController extends Controller {
  @service
  declare router: RouterService

  @service
  declare lessons: LessonsService

  @service
  declare features: FeatureService

  @service
  declare loadingUi: LoadingUiService

  @service
  declare errorHandler: ErrorHandlerService

  @action
  playLesson(args: number | Record<'lesson', number>) {
    let lessonId
    if (typeof args === 'number') {
      lessonId = args // CAPER
    } else {
      lessonId = args.lesson // JESTER
    }
    void this.router.transitionTo('lessons.lesson', lessonId)
  }

  @action
  playQuiz() {
    void this.router.transitionTo('lessons.map.quiz', this.lessons.currentMap)
  }

  @action
  prevMap() {
    void this.router.transitionTo(
      'lessons.map.index',
      this.lessons.previousMapId,
    )
  }

  @action
  nextMap() {
    void this.router.transitionTo('lessons.map.next')
  }

  @action
  goHome() {
    void this.router.transitionTo('/')
  }

  /**
   * Hide the full page loader when the content is loaded.
   * This is a Jester content action
   */
  @action
  readyForUserInput() {
    this.loadingUi.hide() // clear the loading screen
  }

  /**
   * error handler action
   * This is a Jester content action
   * @param UnhandledErrorMessage
   */
  @action
  unhandledError({ error }: { error: Partial<ErrorLike> }) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  get contentSpec() {
    return {
      goHome: {
        type: 'message',
      },
      playLesson: {
        type: 'message',
      },
      playQuiz: {
        type: 'message',
      },
      prevMap: {
        type: 'message',
      },
      nextMap: {
        type: 'message',
      },
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
    }
  }

  get isJesterMap() {
    return this.features.isEnabled('jester_map_enabled')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/map/index': LessonsMapIndexController
  }
}
