import Service from '@ember/service'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import { assert } from '@ember/debug'
import { useQuery } from 're-client/resources/query'

export const ReadingActivityFragment = graphql(/* GraphQL */ `
  fragment ReadingActivityFragment on ReadingActivity {
    precinct
    activityInLesson
    activityType
    framework
    id
    lessonInMap
    lessonInPrecinct
    manifestBundle
    manifestKey
    map
  }
`)

export type Activity = DocumentType<typeof ReadingActivityFragment>

const readingActivitiesQueryDocument = graphql(/* GraphQL */ `
  query ReadingActivities {
    readingActivities {
      ...ReadingActivityFragment
    }
  }
`)

export default class ActivityService extends Service {
  activitiesQuery = useQuery(this, () => ({
    query: readingActivitiesQueryDocument,
  }))

  get activities() {
    const activities = this.activitiesQuery.current.data?.readingActivities
    assert('activities is an array', Array.isArray(activities))
    return activities
  }

  readingActivitiesByLesson = new Map<string, Activity[]>()

  async setup() {
    const queryResult = await this.activitiesQuery.current.result()
    const activities = queryResult.data.readingActivities

    const lessonsInReadingPrecinct = new Set<number>()

    for (const { precinct, lessonInPrecinct } of activities) {
      if (precinct === 'reading') {
        lessonsInReadingPrecinct.add(lessonInPrecinct)
      }
    }

    for (const lessonInPrecinct of lessonsInReadingPrecinct) {
      const lessonId = lessonInPrecinct.toString()
      const lessonActivities = activities.filter(
        (a) => a.lessonInPrecinct === lessonInPrecinct,
      )
      lessonActivities.sort((a, b) => a.lessonInMap - b.lessonInMap)
      this.readingActivitiesByLesson.set(lessonId, lessonActivities)
    }
  }

  hasReadingActivitiesByLessonId(lessonId: string) {
    const activities = this.readingActivitiesByLesson.get(lessonId)
    return activities !== undefined && activities.length > 0
  }

  getReadingActivitiesByLessonId(lessonId: string) {
    const activitiesForLesson = this.readingActivitiesByLesson.get(lessonId)
    return activitiesForLesson ?? []
  }

  lastReadingActivityForLesson(lessonId: string): Activity {
    const activitiesForLesson = this.getReadingActivitiesByLessonId(lessonId)

    const lastActivity = activitiesForLesson.at(-1)

    assert('lastActivity is not undefined', lastActivity !== undefined)
    return lastActivity
  }

  nextReadingActivityForLesson(
    lessonId: string,
    activityId: number,
  ): Activity | undefined {
    const activitiesForLesson = this.getReadingActivitiesByLessonId(lessonId)

    return activitiesForLesson.find(
      ({ activityInLesson }) => activityInLesson === activityId + 1,
    )
  }
}

declare module '@ember/service' {
  interface Registry {
    activity: ActivityService
  }
}
