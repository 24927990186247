import { get } from '@ember/object'

const DEFAULT_LOCALE = 'en'

const TRANSLATIONS = {
  en: {
    program: 'Program',
  },

  'en-gb': {
    program: 'Programme',
  },
}

export default function translate(
  translationKey,
  blakeLocale = DEFAULT_LOCALE,
  translations = TRANSLATIONS,
) {
  const locale = _localeFromBlakeData(blakeLocale)
  const translationsForLocale = translations[locale]
  const defaultTranslations = translations[DEFAULT_LOCALE]

  const translated =
    (translationsForLocale && get(translationsForLocale, translationKey)) ??
    (defaultTranslations && get(defaultTranslations, translationKey))

  if (!translated) {
    throw new Error(
      `No translation for for locale '${locale}' and key '${translationKey}'`,
    )
  }

  return translated
}

// Ported from @blakeelearning/site-copy
// https://github.com/blake-education/frontend/blob/cb8c4a0a5b44bb37ae1c2453e7b8405aa2159835/packages/site-copy/addon/utils/language.ts
// This allows us to avoid depending on site-copy, which drags ~100KB of extra JS
// into our app bundle.
function _localeFromBlakeData(blakeLocale) {
  const locale = blakeLocale?.toLowerCase()
  const languageKey = locale || DEFAULT_LOCALE

  return (
    {
      en: 'en',
      in: 'en-gb',
      uk: 'en-gb',
      gb: 'en-gb',
    }[languageKey] ?? DEFAULT_LOCALE
  )
}
