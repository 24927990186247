import Service, { service } from '@ember/service'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import { QuestGoalStatusEnum } from 're-client/graphql/graphql'
import type { StudentQuestGoalAcknowledgeCompleteInput } from 're-client/graphql/graphql'
import type { FeatureService } from '@blakeelearning/features'
import type RouterService from '@ember/routing/router-service'
import { useQuery } from 're-client/resources/query'
import { useMutation } from 're-client/resources/mutation'
import { tracked } from '@glimmer/tracking'
import type { RouteModel } from '@ember/routing/router-service'

const _StudentQuestDataFragment = graphql(/* GraphQL */ `
  fragment StudentQuestDataFragment on Student {
    id
    quest {
      easyGoal {
        ... on QuestGoalEarnEggs {
          eggReward
          id
          progressCurrent
          progressTotal
          status
        }
        __typename
      }
      primaryGoal {
        ... on QuestGoalLesson {
          eggReward
          id
          lesson
          progressCurrent
          progressTotal
          status
        }
        ... on QuestGoalMap {
          eggReward
          id
          map
          complete
          status
        }
        __typename
      }
    }
  }
`)

const StudentQuestQueryDocument = graphql(/* GraphQL */ `
  query GetStudentQuest {
    student {
      ...StudentQuestDataFragment
    }
  }
`)

export const acknowledgeQuestGoalAlertMutationDocument = graphql(/* GraphQL */ `
  mutation AcknowledgeQuestGoalAlert(
    $input: StudentQuestGoalAcknowledgeCompleteInput!
  ) {
    studentQuestGoalAcknowledgeComplete(input: $input) {
      student {
        id
        eggs
        ...StudentQuestDataFragment
      }
    }
  }
`)
export default class QuestService extends Service {
  @service declare features: FeatureService

  @service declare router: RouterService

  @tracked showQuestAlert = false

  _studentQuestQuery = useQuery(this, () => {
    return {
      query: StudentQuestQueryDocument,
    }
  })

  _acknowledgeQuestGoalAlertMutation = useMutation(
    this,
    acknowledgeQuestGoalAlertMutationDocument,
  )

  get currentQuest() {
    return this._studentQuestQuery.current.data?.student?.quest
  }

  get incompleteEarnEggGoals() {
    return [this.currentQuest?.easyGoal].filter(
      (goal) => goal?.status === QuestGoalStatusEnum.Incomplete,
    )
  }

  get questGoalForNotification() {
    const easyGoal = this.currentQuest?.easyGoal
    const primaryGoal = this.currentQuest?.primaryGoal

    if (primaryGoal?.status === QuestGoalStatusEnum.ShowCompleteAlert)
      return primaryGoal
    if (easyGoal?.status === QuestGoalStatusEnum.ShowCompleteAlert)
      return easyGoal
    return undefined
  }

  get notificationMessage() {
    const goal = this.questGoalForNotification

    switch (goal?.__typename) {
      case 'QuestGoalLesson':
        return [
          `Congratulations!`,
          `By completing Lesson ${goal.lesson.toString()} you have also completed a quest goal.`,
          `Your reward is ${goal.eggReward.toString()} eggs!`,
        ]
      case 'QuestGoalMap':
        return [
          `Congratulations!`,
          `By completing Map ${goal.map.toString()} you have also completed a quest goal.`,
          `Your reward is ${goal.eggReward.toString()} eggs!`,
        ]
      case 'QuestGoalEarnEggs':
        return [
          `Congratulations!`,
          `You've earned enough eggs to complete a quest goal.`,
          `Your reward is ${goal.eggReward.toString()} eggs!`,
        ]
      default:
        return []
    }
  }

  get recommendedTargetRoute() {
    if (!this.currentQuest) return

    const primaryGoal = this.currentQuest.primaryGoal
    if (primaryGoal?.status === QuestGoalStatusEnum.Incomplete) {
      switch (primaryGoal.__typename) {
        case 'QuestGoalLesson':
          return [
            'lessons.lesson.activity',
            primaryGoal.lesson.toString(),
            (primaryGoal.progressCurrent + 1).toString(),
          ]
        case 'QuestGoalMap':
          return ['lessons.map.quiz', primaryGoal.map.toString()]
        default:
          return undefined
      }
    }
    return
  }

  async fetch() {
    await this._studentQuestQuery.current.refetch()
    await this.setup()
  }

  async setup() {
    await this._studentQuestQuery.current.result()
  }

  async acknowledgeQuestGoalAlert(
    input: StudentQuestGoalAcknowledgeCompleteInput,
  ) {
    await this._acknowledgeQuestGoalAlertMutation.current.mutate({
      variables: {
        input,
      },
    })
  }

  displayQuestAlertIfRequired(nextRoute?: [string] | [string, RouteModel]) {
    if (this.questGoalForNotification) {
      this.showQuestAlert = true
    } else {
      // @ts-expect-error router params type no bueno
      if (nextRoute) this.router.transitionTo(...nextRoute)
    }
  }

  incrementEggGoals(amount: number) {
    this._studentQuestQuery.current.updateQuery((data) => {
      if (!data.student?.quest?.easyGoal) return data

      return {
        __typename: 'Query',
        student: {
          ...data.student,
          quest: {
            ...data.student.quest,
            easyGoal: this._updateEggGoal(data.student.quest.easyGoal, amount),
          },
        },
      }
    })
  }

  _updateEggGoal(
    goal: NonNullable<
      NonNullable<
        DocumentType<typeof _StudentQuestDataFragment>['quest']
      >['easyGoal']
    >,
    amount: number,
  ) {
    if (goal.status !== QuestGoalStatusEnum.Incomplete) return goal

    const willCompleteGoal = goal.progressCurrent + amount >= goal.progressTotal

    if (willCompleteGoal) {
      return {
        ...goal,
        progressCurrent: goal.progressTotal,
        status: QuestGoalStatusEnum.ShowCompleteAlert,
      }
    }
    return {
      ...goal,
      progressCurrent: goal.progressCurrent + amount,
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    quest: QuestService
  }
}
