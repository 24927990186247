import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'

export default class CrittersIndexController extends Controller {
  @service
  declare router: RouterService

  @action
  playVideo(critterId: string) {
    this.router.transitionTo('critters.critter', critterId)
  }
}
