import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/spelling/mystery-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/spelling/mystery-game.hbs"}});
import Component from '@glimmer/component'

export default class MysteryGame extends Component {
  async grantMysteryItem(itemId) {
    await this.args.grantMysteryItemAction(itemId)
    this.interactive.callInteractionMethod('nextable')
  }

  next() {
    this.args.nextAction()
  }
}
