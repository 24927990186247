import type SessionService from 're-client/services/session'
import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'

const {
  APP: { loginWarning },
} = config

interface ModelForRoute {
  warning: string | undefined
}

export default class LogoutRoute extends Route<ModelForRoute> {
  @service
  declare session: SessionService

  override model() {
    return {
      warning: loginWarning,
    }
  }

  override redirect(): void {
    void this.session.signOut()
  }
}
