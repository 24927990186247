const { abs, ceil, floor } = Math

// This utility is a reimplementation of moment.js's `fromNow` and
// `toNow` functions, using the same buckets of time for translating
// relative dates to readable strings like: 'a few seconds ago', 'a year ago', etc
// https://momentjs.com/docs/#/displaying/fromnow/
// https://momentjs.com/docs/#/displaying/tonow/

// TODO: This util can be replaced with date-fns intlFormatDistance once we drop support for ios13

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24
const ONE_MONTH = ONE_DAY * 31
const ONE_YEAR = ONE_DAY * 365
const DIFF_SECONDS_FROM_NOW = ONE_SECOND * 45
const DIFF_MINUTE_FROM_NOW = ONE_SECOND * 90
const DIFF_MINUTES_FROM_NOW = ONE_MINUTE * 45
const DIFF_HOUR_FROM_NOW = ONE_MINUTE * 90
const DIFF_HOURS_FROM_NOW = ONE_HOUR * 22
const DIFF_DAY_FROM_NOW = ONE_HOUR * 36
const DIFF_DAYS_FROM_NOW = ONE_DAY * 26
const DIFF_MONTH_FROM_NOW = ONE_DAY * 45
const DIFF_MONTHS_FROM_NOW = ONE_DAY * 319
const DIFF_YEAR_FROM_NOW = ONE_DAY * 547
const DIFF_SECONDS_AGO = DIFF_SECONDS_FROM_NOW * -1
const DIFF_MINUTE_AGO = DIFF_MINUTE_FROM_NOW * -1
const DIFF_MINUTES_AGO = DIFF_MINUTES_FROM_NOW * -1
const DIFF_HOUR_AGO = DIFF_HOUR_FROM_NOW * -1
const DIFF_HOURS_AGO = DIFF_HOURS_FROM_NOW * -1
const DIFF_DAY_AGO = DIFF_DAY_FROM_NOW * -1
const DIFF_DAYS_AGO = DIFF_DAYS_FROM_NOW * -1
const DIFF_MONTH_AGO = DIFF_MONTH_FROM_NOW * -1
const DIFF_MONTHS_AGO = DIFF_MONTHS_FROM_NOW * -1
const DIFF_YEAR_AGO = DIFF_YEAR_FROM_NOW * -1

export default function dateFromNow(date: Date, now = Date.now()): string {
  const diff = date.getTime() - now

  if (diff <= DIFF_YEAR_AGO) {
    return `${_msToAbsolute(diff, 'years')} years ago`
  }
  if (diff > DIFF_YEAR_AGO && diff <= DIFF_MONTHS_AGO) {
    return 'a year ago'
  }
  if (diff > DIFF_MONTHS_AGO && diff <= DIFF_MONTH_AGO) {
    return `${_msToAbsolute(diff, 'months')} months ago`
  }
  if (diff > DIFF_MONTH_AGO && diff <= DIFF_DAYS_AGO) {
    return 'a month ago'
  }
  if (diff > DIFF_DAYS_AGO && diff <= DIFF_DAY_AGO) {
    return `${_msToAbsolute(diff, 'days')} days ago`
  }
  if (diff > DIFF_DAY_AGO && diff <= DIFF_HOURS_AGO) {
    return 'a day ago'
  }
  if (diff > DIFF_HOURS_AGO && diff <= DIFF_HOUR_AGO) {
    return `${_msToAbsolute(diff, 'hours')} hours ago`
  }
  if (diff > DIFF_HOUR_AGO && diff <= DIFF_MINUTES_AGO) {
    return 'an hour ago'
  }
  if (diff > DIFF_MINUTES_AGO && diff <= DIFF_MINUTE_AGO) {
    return `${_msToAbsolute(diff, 'minutes')} minutes ago`
  }
  if (diff > DIFF_MINUTE_AGO && diff <= DIFF_SECONDS_AGO) {
    return 'a minute ago'
  }
  if (diff > DIFF_SECONDS_AGO && diff < 0) {
    return 'a few seconds ago'
  }
  if (diff >= 0 && diff < DIFF_SECONDS_FROM_NOW) {
    return 'in seconds'
  }
  if (diff >= DIFF_SECONDS_FROM_NOW && diff < DIFF_MINUTE_FROM_NOW) {
    return 'in a minute'
  }
  if (diff >= DIFF_MINUTE_FROM_NOW && diff < DIFF_MINUTES_FROM_NOW) {
    return `in ${_msToAbsolute(diff, 'minutes')} minutes`
  }
  if (diff >= DIFF_MINUTES_FROM_NOW && diff < DIFF_HOUR_FROM_NOW) {
    return 'in an hour'
  }
  if (diff >= DIFF_HOUR_FROM_NOW && diff < DIFF_HOURS_FROM_NOW) {
    return `in ${_msToAbsolute(diff, 'hours')} hours`
  }
  if (diff >= DIFF_HOURS_FROM_NOW && diff < DIFF_DAY_FROM_NOW) {
    return 'in a day'
  }
  if (diff >= DIFF_DAY_FROM_NOW && diff < DIFF_DAYS_FROM_NOW) {
    return `in ${_msToAbsolute(diff, 'days')} days`
  }
  if (diff >= DIFF_DAYS_FROM_NOW && diff < DIFF_MONTH_FROM_NOW) {
    return 'in a month'
  }
  if (diff >= DIFF_MONTH_FROM_NOW && diff < DIFF_MONTHS_FROM_NOW) {
    return `in ${_msToAbsolute(diff, 'months')} months`
  }
  if (diff >= DIFF_MONTHS_FROM_NOW && diff < DIFF_YEAR_FROM_NOW) {
    return 'in a year'
  }
  if (diff >= DIFF_YEAR_FROM_NOW) {
    return `in ${_msToAbsolute(diff, 'years')} years`
  }
  return ''
}

type TimeUnit = 'minutes' | 'hours' | 'days' | 'months' | 'years'

function _msToAbsolute(ms: number, unit: TimeUnit): number {
  const conversionFactor = {
    minutes: ONE_MINUTE,
    hours: ONE_HOUR,
    days: ONE_DAY,
    months: ONE_MONTH,
    years: ONE_YEAR,
  }[unit]

  const converted = abs(ms / conversionFactor)
  return converted < 2 ? ceil(converted) : floor(converted)
}
