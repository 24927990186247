import { service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'

export default class CatchAllRoutesRoute extends Route {
  @service
  declare router: RouterService

  override redirect() {
    void this.router.replaceWith('index')
  }
}
