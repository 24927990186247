import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type SpellingService from 're-client/services/spelling'
import type RouterService from '@ember/routing/router-service'
import type UrlMakerService from 're-client/services/url-maker'
import type { FeatureService } from '@blakeelearning/features'
import type UserService from 're-client/services/user'
import ky from 'ky'
import type AssignmentsService from 're-client/services/assignments'
import { cloneDeep } from 'lodash'
import type { Manifest } from '@blakeelearning/content-loader-core'

/**
 * Represents a lesson
 */
export class SpellingLessonActivity {
  json?: { caper_module: string }

  constructor(activity: unknown) {
    Object.assign(this, activity)
  }

  isQuiz() {
    const caperModule = this.json?.caper_module
    const isQuizModule =
      caperModule === 'readingeggs/activities/spelling/spelling_test'
    return caperModule && isQuizModule
  }
}

/**
 * Represents a lesson
 *
 * @class Lesson
 */
export class SpellingLessonRouteModel {
  /**
   * List of activity objects for this lesson
   */
  activities: {
    lesson: SpellingLessonRouteModel
    id: number
    json: Record<string, unknown>
  }[] = []

  id: string

  get lessonId() {
    return this.id
  }

  constructor({
    id,
    activities,
  }: {
    id: string
    activities: Record<string, unknown>[]
  }) {
    this.id = id
    this.activities = activities.map((json, index) => ({
      lesson: this,
      id: index + 1,
      json,
    }))
  }

  /**
   * Find the next activity in the sequence, after the specified index
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the current activity index (1-based index)
   * @return {Object} A clone of the next activity object
   */
  nextActivity(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex + 1])
  }

  /**
   * Find an activity by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object at the specified index
   */
  findActivity(activityIndex: number) {
    const activity = cloneDeep(this.activities[activityIndex - 1])
    return new SpellingLessonActivity(activity)
  }

  /**
   * Find an activity manifest by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object's manifest at the specified index
   */
  findActivityManifest(activityIndex: number) {
    const activity = this.activities[activityIndex - 1]
    return cloneDeep(activity?.json) as unknown as Manifest
  }
}

interface RouteParams {
  lesson_id: string
}

/**
 * The lesson route
 *
 * @class LessonRoute
 */
export default class SpellingLessonRoute extends Route<
  SpellingLessonRouteModel,
  RouteParams
> {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare spelling: SpellingService

  @service
  declare assignments: AssignmentsService

  @service
  declare router: RouterService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare features: FeatureService

  @service
  declare user: UserService

  /**
   * Access control to lesson based on if the student needs to complete a placement test, executed before the model loads.
   *
   * Allows access if:
   *  - `shouldForceAccess()` evaluates to `true`
   *  - the student does not need to complete a placement test
   *
   * @instance beforeModel
   * @memberOf LessonRoute
   * @param {Object} params The url params
   */
  override beforeModel() {
    if (this.shouldForceAccess()) {
      return
    }

    if (this.studentProgress.showSpellingPlacementTest) {
      void this.router.transitionTo('spelling.placement-test')
      return
    }

    if (this.studentProgress.isAreaLocked('spelling')) {
      void this.router.transitionTo('index')
    }
  }

  /**
   * The lesson model for the route
   *
   * @instance model
   * @memberOf LessonRoute
   * @type Ember.Object
   * @param {Object} params The url params
   * @return {Ember.Object} The Lesson object
   */
  override async model(params: RouteParams) {
    const lessonId = params.lesson_id
    this.spelling.setCurrentLesson(lessonId)

    const json = await ky
      .get(this.urlMaker.urlForInteractive('spelling/lessons', lessonId))
      .json<{
        activities: {
          thumb?: { assetPath: string } | string
        }[]
      }>()

    // The 'thumb' property of each activity is used by the activity sidebar
    for (const activity of json.activities) {
      if (
        activity.thumb &&
        typeof activity.thumb === 'object' &&
        'assetPath' in activity.thumb
      ) {
        const { assetPath } = activity.thumb
        activity.thumb = new URL(
          assetPath,
          config.contentLoader.caper.assetHosts.assets,
        ).toString()
      }
    }

    return new SpellingLessonRouteModel({
      id: lessonId,
      activities: json.activities,
    })
  }

  /**
   * Access control to lesson based on the student's progress, executed after the model loads.
   *
   * Allows access if:
   *  - `shouldForceAccess()` evaluates to `true`
   *  - the authorisation policy allows access, based on student progress
   *
   * @instance afterModel
   * @memberOf LessonRoute
   * @param {Object} params The url params
   */

  override afterModel(lesson: SpellingLessonRouteModel) {
    if (this.shouldForceAccess()) {
      return
    }

    if (lesson.id !== this.spelling.currentLesson) {
      void this.router.transitionTo(
        'spelling.lesson',
        this.spelling.currentLesson,
      )
    }
  }

  shouldForceAccess() {
    return this.assignments.currentTask?.__typename === 'AssignmentTaskSpelling'
  }
}
