const departments = ['avatar', 'house']

/**
 * Simple helper to always return a valid department no matter what has been given
 * Useful when the value to test comes from user changeable source for instance a url-param
 * @param {String} departmentToTest
 * @returns {String} - either avatar or house
 */
export function allowedDepartment(departmentToTest = '') {
  return departments.includes(departmentToTest)
    ? departmentToTest
    : departments[0]
}
