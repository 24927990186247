const MIN = 1
const MAX = 48

/**
 * NOTE: Only used for staging and development purposes
 * This helper class creates an object which stores are range of number of a range 1 - 48
 * and provides next prev and current functions to retrieve a weekid
 *
 * Rules:
 * - week must be >= 1 and week must my <= 48
 *
 * @example
 *  const weekId = new WeekId()
 *  weekId.current() // 1
 *  weekId.next() // 2
 *  weekId.prev() // 1
 *  weekId.prev() // 48
 *
 * @class WeekId
 */
export class WeekId {
  /**
   *
   * @param current
   */
  constructor(current = 1) {
    this.max = MAX
    this.min = MIN

    if (current < this.min) current = MIN
    if (current > this.max) current = MAX

    this.currentIndex = current - 1

    const data = [...Array(this.max + 1).keys()]
    data.shift()
    this.data = data
  }

  current() {
    return String(this.data[this.currentIndex])
  }

  next() {
    this.currentIndex =
      this.currentIndex === this.max - 1 ? 0 : this.currentIndex + 1
    return this.current()
  }

  prev() {
    this.currentIndex =
      this.currentIndex === 0 ? this.max - 1 : this.currentIndex - 1
    return this.current()
  }
}
