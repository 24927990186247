import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'

export default class StoryFactoryIndexRoute extends Route {
  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  override async model() {
    const supportingAssets = [
      this.caperLoader.storyFactoryAssetRequest('current'),
      this.caperLoader.storyFactoryAssetRequest('previous'),
      this.caperLoader.storyFactoryAssetRequest('voting'),
      this.caperLoader.storyFactoryEntriesRequest(),
    ]

    const variables = { student: this.user.student }

    const storyFactoryUrl = this.urlMaker.urlForInteractive(
      'story-factory',
      'story_factory_lobby',
    )
    const manifests = [storyFactoryUrl, ...supportingAssets]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
