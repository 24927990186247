import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import type DebugModeService from 're-client/services/debug-mode'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import { debugAction } from 're-client/utils/debug'

const {
  APP: { product },
} = config

// eslint-disable-next-line ember/no-test-import-export
export default class PlacementTestController extends Controller {
  @service
  declare debugMode: DebugModeService

  @service
  declare operations: Operations

  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare router: RouterService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  get studentContext() {
    return {
      product,
      precinct: 'spelling',
      remoteId: this.user.student.remoteId,
    }
  }

  @action
  async placementTestComplete(lessonId: number) {
    await this.operations.completePlacement(this.studentContext, lessonId)
    await this.studentProgress.fetchProgress()
    this.interactive.callInteractionMethod('nextable')
  }

  @action
  next() {
    this.router.transitionTo(
      'spelling.map',
      this.studentProgress.spellingCurrentMap,
    )
  }

  @action
  @debugAction({
    lessonId: {
      type: 'number',
      value: '1',
    },
  })
  async completePlacementTest({ lessonId }: { lessonId: number }) {
    await this.placementTestComplete(lessonId)
    this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'spelling/placement-test': PlacementTestController
  }
}
