import type Transition from '@ember/routing/transition'
import type RouterService from '@ember/routing/router-service'
import Service, { inject as service } from '@ember/service'
import { isEqual } from 'lodash'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import transitionToArray from 're-client/utils/transition-to-array'
import { useQuery } from 're-client/resources/query'

export const AssignmentTask = graphql(/* GraphQL */ `
  fragment AssignmentTask on Student {
    assignmentTask {
      ... on AssignmentTaskLesson {
        activityId
        assignmentUuid
        endAt
        id
        lessonId
        thumbnailUrl
        title
        targetRouteParams @client
      }
      ... on AssignmentTaskSpelling {
        activityId
        assignmentUuid
        endAt
        id
        lessonId
        thumbnailUrl
        title
        targetRouteParams @client
      }
      ... on AssignmentTaskClinkerCastle {
        activityId
        assignmentUuid
        endAt
        id
        lessonId
        thumbnailUrl
        title
        targetRouteParams @client
      }
      ... on AssignmentTaskDrivingTests {
        assignmentUuid
        category
        endAt
        gradePosition
        id
        lessonId
        thumbnailUrl
        title
        targetRouteParams @client
      }
      ... on Error {
        message
      }
      __typename
    }
  }
`)

const getStudentCurrentTaskQueryDocument = graphql(/* GraphQL */ `
  query GetStudentCurrentTask {
    student {
      __typename
      id
      ...AssignmentTask
    }
  }
`)

export type Task = NonNullable<CurrentTask>

export type CurrentTask = Exclude<
  DocumentType<typeof AssignmentTask>['assignmentTask'],
  { __typename: 'Error' }
>

const FIVE_MINUTES = 5 * 60 * 1000

export default class AssignmentsService extends Service {
  @service declare router: RouterService

  get hasTask() {
    return Boolean(this.currentTask)
  }

  get currentTask() {
    const currentTask =
      this._assignmentQuery.current.data?.student?.assignmentTask

    /**
     * If we receive an error on the student.assignmentTask field then we pretend the student
     * has no assignment task by returning null. We don't need to log this error
     * since the backend will have already sent it to rollbar.
     */
    return currentTask?.__typename === 'Error' ? null : currentTask
  }

  _assignmentQuery = useQuery(this, () => ({
    query: getStudentCurrentTaskQueryDocument,
  }))

  _refetchAfter = 0

  async fetchTaskIfDue() {
    if (!this.currentTask && this._refetchAfter < Date.now()) {
      await this.fetch()
    }
  }

  async setup() {
    await this._assignmentQuery.current.result()
    this._refetchAfter = Date.now() + FIVE_MINUTES
  }

  async fetch() {
    await this._assignmentQuery.current.refetch()
    await this.setup()
  }

  /**
   * Checks if a transition is allowed given a student's current task. A transition is
   * allowed if:
   *
   * 1. They have no current task
   * 2. They have a current task and it is the index route
   * 3. They have a current task and it is the reader.book.page route
   * 3. They have a current task and it is the driving-tests.racing-game route
   * 4. They have a current task and it is the route for that task
   *
   * If not allowed we send the student to the index route
   *
   */
  async checkTransition(transition: Transition) {
    await this.fetchTaskIfDue()

    if (!this.hasTask) return

    if (transition.targetName === 'index') return

    // storylands quiz hint
    if (transition.targetName === 'reader.book.page') return

    // driving test minigame
    if (transition.targetName === 'driving-tests.racing-game') return

    if (
      isEqual(
        transitionToArray(transition),
        this.currentTask?.targetRouteParams,
      )
    ) {
      return
    }

    void this.router.replaceWith('index')
  }

  canCompleteLessonAssignmentTask(
    lessonId: number | string,
    activityId: number,
  ) {
    const { currentTask } = this
    if (!currentTask) return false
    if (currentTask.__typename !== 'AssignmentTaskLesson') return false
    if (currentTask.lessonId !== lessonId.toString()) return false
    if (currentTask.activityId !== activityId.toString()) return false
    return true
  }

  canCompleteSpellingAssignmentTask(
    lessonId: number | string,
    activityId: number,
  ) {
    const { currentTask } = this
    if (!currentTask) return false
    if (currentTask.__typename !== 'AssignmentTaskSpelling') return false
    if (currentTask.lessonId !== lessonId.toString()) return false
    if (currentTask.activityId !== activityId.toString()) return false
    return true
  }

  canCompleteStorylandsAssignmentTask(
    lessonId: number | string,
    activityId: number,
  ) {
    const { currentTask } = this
    if (!currentTask) return false
    if (currentTask.__typename !== 'AssignmentTaskClinkerCastle') return false
    if (currentTask.lessonId !== lessonId.toString()) return false
    if (currentTask.activityId !== activityId.toString()) return false
    return true
  }

  canCompleteDrivingTestAssignmentTask(lessonId: number, category: string) {
    const { currentTask } = this
    if (!currentTask) return false
    if (currentTask.__typename !== 'AssignmentTaskDrivingTests') return false
    if (currentTask.lessonId !== lessonId.toString()) return false
    if (currentTask.category !== category) return false
    return true
  }
}

declare module '@ember/service' {
  interface Registry {
    assignments: AssignmentsService
  }
}
