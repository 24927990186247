import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type { MinigameAuthService } from '@blakeelearning/minigame-auth'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

export default class GamesGameRoute extends Route {
  @service
  declare user: UserService

  @service
  declare minigameAuth: MinigameAuthService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare router: RouterService

  @service
  declare urlMaker: UrlMakerService

  override async model({ game_id: gameId }: Record<string, string>) {
    const isAuthorized = this.minigameAuth.claimGameToken(gameId)

    if (!isAuthorized) {
      return this.router.transitionTo('games.index')
    }

    const variables = { student: this.user.student }

    const manifests = [this.urlMaker.urlForInteractive('reading', gameId)]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      gameId,
      interactiveConfig,
    }
  }
}
