import { assert } from '@ember/debug'
import { modifier } from 'ember-modifier'
import fitty from 'fitty'
import type { FittyOptions } from 'fitty'

interface FitTextSignature {
  Args: {
    Named: FittyOptions
  }
  Element: Element
}

export default modifier<FitTextSignature>(function fitText(element, _, named) {
  assert('element must be a HTMLElement', element instanceof HTMLElement)
  const fit = fitty(element, named)

  return () => {
    fit.unsubscribe()
  }
})
