import Service, { service } from '@ember/service'
import config from 're-client/config/environment'
import { graphql } from 're-client/graphql'
import { useFragment } from 're-client/resources/fragment'
import { useQuery } from 're-client/resources/query'
import type UserService from 're-client/services/user'
import type { FeatureService } from '@blakeelearning/features'

const StudentProgressFragment = graphql(/* GraphQL */ `
  fragment StudentProgressFragment on Student {
    id
    progress {
      drivingTests {
        sightWords
        lettersAndSounds
        contentWords
      }
      lessons {
        currentLesson
        currentMap
        currentActivity
        showPlacementTest
      }
      spelling {
        currentLesson
        currentMap
        currentActivity
        showPlacementTest
      }
      clinkerCastle {
        currentLesson
        currentMap
        currentActivity
      }
    }
  }
`)

const StudentProgressQuery = graphql(/* GraphQL */ `
  query StudentProgress {
    student {
      ...StudentProgressFragment
    }
  }
`)

export default class StudentProgressService extends Service {
  @service
  declare user: UserService

  @service
  declare features: FeatureService

  /**
   * @deprecated Used to refetch progress when progress is changed without graphql
   *
   * Consider using `progressFragment` instead
   */
  progressQuery = useQuery(this, () => ({
    query: StudentProgressQuery,
  }))

  progressFragment = useFragment(this, () => ({
    fragment: StudentProgressFragment,
    from: this.user.student,
  }))

  get progress() {
    const progress = this.progressFragment.current?.progress
    if (!progress) throw new Error('progress has not been fetched')
    return progress
  }

  get lessons() {
    return this.progress.lessons
  }

  get lessonsCurrentMap() {
    return this.lessons.currentMap
  }

  get lessonsCurrentLesson() {
    return this.lessons.currentLesson
  }

  get lessonsCurrentLessonNumber() {
    if (this.lessonsCurrentLesson === 'quiz') {
      return (
        this.lessonsCurrentMap *
        config.studentProgress.progress.lessons.lessonsPerMap
      )
    }
    return parseInt(this.lessonsCurrentLesson, 10)
  }

  get lessonsCurrentActivity() {
    return this.lessons.currentActivity
  }

  get hasFinishedLessons() {
    return this.lessonsCurrentMap > this.lessonsLastMap
  }

  get lessonsLastMap() {
    return this.features.isEnabled('lessons_map_13_enabled') ? 13 : 12
  }

  get showLessonsPlacementTest() {
    return this.lessons.showPlacementTest
  }

  get spelling() {
    return this.progress.spelling
  }

  get spellingCurrentMap() {
    return this.spelling.currentMap
  }

  get spellingCurrentLesson() {
    return this.spelling.currentLesson
  }

  get spellingCurrentLessonNumber() {
    const { lessonsPerMap } = config.studentProgress.progress.spelling

    if (this.spellingCurrentLesson === 'quiz') {
      return this.spellingCurrentMap * lessonsPerMap
    }
    return parseInt(this.spellingCurrentLesson, 10)
  }

  get spellingCurrentActivity() {
    return this.spelling.currentActivity
  }

  get showSpellingPlacementTest() {
    return this.spelling.showPlacementTest
  }

  get storylands() {
    return this.progress.clinkerCastle
  }

  get storylandsCurrentMap() {
    return this.storylands.currentMap
  }

  get storylandsCurrentLesson() {
    return this.storylands.currentLesson
  }

  get drivingTests() {
    return this.progress.drivingTests
  }

  isAreaLocked(area: keyof typeof config.APP.minLevels): boolean {
    const lessonsLevelRequired = config.APP.minLevels[area]
    return this.lessonsCurrentLessonNumber < lessonsLevelRequired
  }

  /**
   * Performs the initial watchQuery for the student's progress on the first call and will refetch the query on subsequent calls
   */
  async fetchProgress() {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const { data } = await this.progressQuery.current.refetch()
    return data.student?.progress
  }
}

declare module '@ember/service' {
  interface Registry {
    'student-progress': StudentProgressService
  }
}
