
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AssignmentTask": [
      "AssignmentTaskClinkerCastle",
      "AssignmentTaskDrivingTests",
      "AssignmentTaskLesson",
      "AssignmentTaskSpelling",
      "Error"
    ],
    "AssignmentTaskDetails": [
      "AssignmentTaskClinkerCastle",
      "AssignmentTaskDrivingTests",
      "AssignmentTaskLesson",
      "AssignmentTaskSpelling"
    ],
    "QuestGoalEasy": [
      "QuestGoalEarnEggs"
    ],
    "QuestGoalEssential": [
      "QuestGoalLesson",
      "QuestGoalMap"
    ],
    "QuestGoalPrimary": [
      "QuestGoalLesson",
      "QuestGoalMap"
    ]
  }
};
      export default result;
    