import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import type ShopService from 're-client/services/shop'

export default class ShopController extends Controller {
  @service
  declare router: RouterService

  @service
  declare shop: ShopService

  /**
   * The template needs to render either the shop interactive or an outlet which
   * renders a catalogue interactive. For obvious reasons we dont want to render the 2 at once
   *
   * @property {Boolean}
   * @type {Boolean}
   */
  get isCatalogueRoute() {
    return this.router.currentRouteName === 'shop.department.catalogue'
  }

  @action
  async purchase(item: { id: string }) {
    return this.shop.purchase(item)
  }

  /**
   * Simply rewrite the url with the new department
   * @param department
   */
  @action
  changeDepartment(department: string) {
    this.router.transitionTo('shop.department', department)
  }

  /**
   * Transition to the department dependened catalogue.
   */
  @action
  gotoCatalogue() {
    this.router.transitionTo('shop.department.catalogue')
  }
}
