import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/transition'

export default class UnauthorisedRoute extends Route {
  @service
  declare router: RouterService

  override beforeModel(transition: Transition) {
    if (transition.from) {
      return
    }

    // redirect to login page if transition.from is empty, which means this is the initial load
    void this.router.replaceWith('login')
  }
}
