import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type Store from '@ember-data/store'
import { startCase } from 'lodash'
import type UserService from 're-client/services/user'
import type { ModelFor } from 're-client/utils/route-model'
import type PuzzlesPuzzleRoute from 're-client/routes/puzzles/puzzle'
import type PuzzleScoreModel from 're-client/models/puzzle-score'

export default class PuzzlesPuzzleController extends Controller {
  declare model: ModelFor<PuzzlesPuzzleRoute>

  @service
  declare router: RouterService

  @service
  declare store: Store

  @service
  declare user: UserService

  /**
   * Save the current score to the backend and update the store
   * @param {Number} score The score from the current attempt
   */
  @action
  async saveScore(score: number): Promise<PuzzleScoreModel[]> {
    const { puzzleId } = this.model

    let puzzleScore = this.store.peekRecord('puzzle-score', puzzleId)

    if (puzzleScore) {
      puzzleScore.score = score
    } else {
      puzzleScore = this.store.createRecord('puzzle-score', {
        id: puzzleId,
        puzzleName: startCase(puzzleId),
        score,
      })
    }

    await puzzleScore.save()
    this.user.incrementEggs(score)

    const scores = await this.store.findAll('puzzle-score')
    return scores.slice()
  }

  @action
  goBackToLobby() {
    void this.router.transitionTo('puzzles')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'puzzles/puzzle': PuzzlesPuzzleController
  }
}
