import type Store from '@ember-data/store'
import type { Snapshot } from '@ember-data/store'
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from 'ember-data'
import type ModelRegistry from 'ember-data/types/registries/model'
import ApplicationAdapter from 're-client/adapters/application'
import type RSVP from 'rsvp'

/**
 * Adapter for the PuzzleScore model is special
 * because it uses the create endpoint for updating records
 */
export default class PuzzleScoreAdapter extends ApplicationAdapter {
  override updateRecord<K extends keyof ModelRegistry>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>,
  ): RSVP.Promise<unknown> {
    return super.createRecord(store, type, snapshot)
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'puzzle-score': PuzzleScoreAdapter
  }
}
