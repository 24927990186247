import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<loading-screen\n  class=\"loading-screen-container\n    {{if this.loading 'show-loading'}}\n    {{unless this.loading 'fade-out'}}\"\n  data-test-loading-screen\n  ...attributes\n  {{caper-load-complete this.hideLoadingScreen}}\n  {{remove-initial-loading-screen}}\n>\n  <img\n    src=\"/assets/loading-eggs.svg\"\n    alt=\"Loading...\"\n    class=\"{{if this.loading 'fade-in'}}\"\n  />\n</loading-screen>", {"contents":"<loading-screen\n  class=\"loading-screen-container\n    {{if this.loading 'show-loading'}}\n    {{unless this.loading 'fade-out'}}\"\n  data-test-loading-screen\n  ...attributes\n  {{caper-load-complete this.hideLoadingScreen}}\n  {{remove-initial-loading-screen}}\n>\n  <img\n    src=\"/assets/loading-eggs.svg\"\n    alt=\"Loading...\"\n    class=\"{{if this.loading 'fade-in'}}\"\n  />\n</loading-screen>","moduleName":"re-client/components/loading-screen.hbs","parseOptions":{"srcName":"re-client/components/loading-screen.hbs"}});
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Component from '@glimmer/component'
import type LoadingUiService from 're-client/services/loading-ui'

export default class LoadingScreenComponent extends Component {
  @service declare loadingUi: LoadingUiService

  get loading() {
    return this.loadingUi.isLoading
  }

  @action
  hideLoadingScreen() {
    this.loadingUi.hide()
  }
}
