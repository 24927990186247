import Model, { attr } from '@ember-data/model'
import toJSON from 're-client/utils/to-json'
import type { ToJSONOptions } from 're-client/utils/to-json'

export default class BookCoverModel extends Model {
  @attr('string')
  declare coverUrl: string

  @attr('string')
  declare accent: string

  override toJSON(options?: ToJSONOptions): Record<string, unknown> {
    return toJSON(this, options)
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'book-cover': BookCoverModel
  }
}
