import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'

export default class PlayIndexController extends Controller {
  @service
  declare router: RouterService

  @action
  showBook(book: string) {
    this.router.transitionTo('reader.book', `reading_eggs_${book}`, {
      queryParams: { disableQuiz: true },
    })
  }

  @action
  showGame(game: string, { letter }: { letter?: string } = {}) {
    const gamePath = letter ? `${game}/${letter}` : game
    this.router.transitionTo('play.activity', gamePath)
  }

  @action
  showVideo(video: string) {
    this.router.transitionTo('play.activity', `playroom_video/${video}`)
  }
}
