import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'

export default class MyReadingGoalsMilestonesRoute extends Route {
  @service
  features

  @service
  router

  beforeModel() {
    if (!this.features.isEnabled('my_reading_goals')) {
      this.router.transitionTo('/')
    }
  }

  model() {
    const variables = {}

    const manifests = [['index', 'activities/mrg-milestones']]

    return { variables, manifests }
  }
}
