import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/spelling/map-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/spelling/map-game.hbs"}});
import Component from '@glimmer/component'

export default class MapGame extends Component {
  playLesson(...args) {
    this.args.playLessonAction(...args)
  }

  playQuiz(...args) {
    this.args.playQuizAction(...args)
  }

  prevMap() {
    this.args.prevMapAction()
  }

  nextMap() {
    this.args.nextMapAction()
  }
}
