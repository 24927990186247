import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type { ParamsFor } from 're-client/utils/route-model'
import type ReaderBookRoute from 're-client/routes/reader/book'
import type { GqlStudent } from 're-client/services/user'

export default class ReaderBookIndexRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override queryParams = {
    disableQuiz: {
      replace: true,
    },
  }

  override async model({ disableQuiz }: Record<string, string>) {
    const { book_id: bookId } = this.paramsFor(
      'reader.book',
    ) as ParamsFor<ReaderBookRoute>

    const variables: { quiz?: null; student: GqlStudent } = {
      student: this.user.student,
    }

    if (disableQuiz) {
      variables.quiz = null
    }

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'reading'),
      this.caperLoader.readingBookRequest(bookId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
