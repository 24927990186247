import { Howl, Howler } from 'howler'
import Service, { service } from '@ember/service'
import { isTesting, macroCondition } from '@embroider/macros'
import type { Log } from '@blakeelearning/log'

declare global {
  interface AudioContext {
    // Safari has an additional 'interrupted' state
    state: AudioContextState | 'interrupted'
  }
}

/**
 * Cache of sounds by name
 */
const sounds = new Map([
  ['snap', new Howl({ src: '/assets/audio/snap.mp3' })],
  ['chaching', new Howl({ src: '/assets/audio/chaching.mp3' })],
])

async function play(sound: Howl) {
  if (Howler.ctx.state === 'suspended' || Howler.ctx.state === 'interrupted') {
    await Howler.ctx.resume()
  }

  sound.play()
}

export default class SoundsService extends Service {
  @service
  declare log: Log

  /**
   * Plays a sound when found in the cache
   * @param name - id name of the sound
   */
  play(name: string) {
    const sound = sounds.get(name)
    if (macroCondition(isTesting())) {
      // Do not play sounds in test environment
    } else if (sound) {
      play(sound).catch((error: unknown) => {
        this.log.error(error as Error)
      })
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    sounds: SoundsService
  }
}
