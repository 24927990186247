import { modifier } from 'ember-modifier'
import gsap from 'gsap'

interface ReggieLoopAnimationSignature {
  Element: Element
}

export default modifier<ReggieLoopAnimationSignature>(
  function reggieLoopAnimation(element) {
    const loop = gsap.timeline({
      repeat: -1,
    })

    const [first, second] = element.children

    if (first && second) {
      gsap.set(second, { autoAlpha: 0, height: '85%' })

      loop
        .add(shrink(first), '+=5')
        .add(fadeOut(first), '<')
        .add(expand(second), '<')
        .add(fadeIn(second), '<')
        .add(shrink(second), '+=5')
        .add(fadeOut(second), '<')
        .add(expand(first), '<')
        .add(fadeIn(first), '<')
    }

    return () => {
      loop.kill()
    }
  },
)

function fadeIn(element: Element) {
  return gsap.to(element, {
    autoAlpha: 1,
    duration: 0.2,
  })
}

function fadeOut(element: Element) {
  return gsap.to(element, {
    autoAlpha: 0,
    duration: 0.2,
  })
}

function shrink(element: Element) {
  return gsap.to(element, {
    height: '85%',
    duration: 1,
    ease: 'elastic.out',
  })
}

function expand(element: Element) {
  return gsap.to(element, {
    height: '100%',
    duration: 1,
    ease: 'elastic.out',
  })
}
