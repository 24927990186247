import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'

export default class MyReadingGoalsQuizRoute extends Route {
  @service
  features

  @service
  router

  @service
  user

  beforeModel() {
    if (!this.features.isEnabled('my_reading_goals')) {
      this.router.transitionTo('/')
    }
  }

  model({ quiz_id: quizId }) {
    const manifests = [['index', `activities/${quizId}`]]

    const {
      student: { name, eggs },
    } = this.user

    const variables = {
      studentData: {
        name,
        eggs,
      },
      quizData: {
        attempts: 0,
      },
    }

    return { variables, manifests }
  }
}
