export default {
  content: {
    variables: {
      voting: {
        name: 'Fun Day',
        end_date: '2024-06-06',
        winner: {
          author: 'Hugh L.',
          title: 'Book A',
          submitted: true,
          style: 6,
          pages: [
            {
              text: 'Random page',
              position: 0,
              page_position: 10,
            },
            {
              text: 'Book A',
              position: 1,
              page_position: 1,
            },
            {
              text: 'Some Text',
              position: 2,
              page_position: 3,
            },
            {
              text: 'Some Text',
              position: 3,
              page_position: 9,
            },
            {
              text: 'Some Text',
              position: 4,
              page_position: 4,
            },
            {
              text: 'Some Text',
              position: 5,
              page_position: 8,
            },
            {
              text: 'Some Text',
              position: 6,
              page_position: 5,
            },
          ],
        },
        state: 'voting',
        words: [
          'beach',
          'draw',
          'excellent',
          'great',
          'happy',
          'hug',
          'jump',
          'knee',
          'play',
          'fall',
          'hurt',
          'icecream',
          'ouch',
          'pool',
          'read',
          'splash',
          'swim',
          'trampoline',
          'water',
          'write',
        ],
        images: [
          'fun_day_1',
          'fun_day_2',
          'fun_day_3',
          'fun_day_4',
          'fun_day_5',
          'fun_day_6',
          'fun_day_7',
          'fun_day_8',
          'fun_day_9',
          'fun_day_10',
        ],
        shortlist: Array(10)
          .fill(0)
          .map((_, i) => ({
            author: 'Hugh L.',

            title: `Book ${i}`,
            submitted: true,

            style: 2,
            pages: [
              {
                text: `Book ${i}`,
                position: 0,
                page_position: 8,
              },
              {
                text: 'Some Text',
                position: 1,
                page_position: 4,
              },
              {
                text: 'Some Text',
                position: 2,
                page_position: 3,
              },
              {
                text: 'Some Text',
                position: 3,
                page_position: 2,
              },
              {
                text: 'Some Text',
                position: 4,
                page_position: 6,
              },
              {
                text: 'Some Text',
                position: 5,
                page_position: 7,
              },
              {
                text: 'Some Text',
                position: 6,
                page_position: 5,
              },
            ],
            ratings: [
              {
                rating: 5,
              },
            ],
          })),
      },
    },
    images: [
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_1-fp-e51ce0dd.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_2-fp-d1c9bae0.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_3-fp-1526ca7c.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_4-fp-05cb919c.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_5-fp-cb8c24e1.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_6-fp-d28193ab.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_7-fp-20ad5606.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_8-fp-3cacd846.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_9-fp-6521af27.jpg',
      'https://assets.static.readingeggs.com/story_factory/fun_day/fun_day_10-fp-0b664487.jpg',
    ],
  },
}
