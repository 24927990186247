import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

export default class StorylandsBonusGameRoute extends Route {
  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override beforeModel() {
    if (this.studentProgress.isAreaLocked('storylands')) {
      void this.router.transitionTo('index')
    }
  }

  override async model({ game_id: gameId }: Record<string, string>) {
    const manifests = [
      this.urlMaker.urlForInteractive('clinker-castle', gameId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests)

    return { interactiveConfig }
  }
}
