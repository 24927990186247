import Route from '@ember/routing/route'

/**
 * Lookup object of valid things for the "no-access" page.
 * We don't want people able to pass random params to `no-access/garbage` and
 * have it show in the UI, so this lookup hash allows us defined what we
 * actually support.
 */
const validProps = new Map([
  ['games', 'Games'],
  ['play', 'Playroom'],
])

export default class NoAccessRoute extends Route {
  override model(params: { access_type: string }) {
    return validProps.get(params.access_type) ?? ''
  }
}
