import EmberRouter from '@ember/routing/router'
import config from 're-client/config/environment'

export default class Router extends EmberRouter {
  override location = config.locationType

  override rootURL = config.rootURL
}

Router.map(function () {
  this.route('application-error')
  this.route('unauthorised')

  this.route('login')
  this.route('logout')

  this.route('spelling', function () {
    this.route('game', { path: 'game/:game_id' })
    this.route('lesson', { path: 'lesson/:lesson_id' }, function () {
      this.route('activity', { path: '/:activity_id' })
    })
    this.route('map', { path: 'map/:map_id' }, function () {
      this.route('quiz')
    })
    this.route('placement-test')
    this.route('finished-re-spelling', { path: 'finished-spelling' })
    this.route('mystery-gift')
  })

  this.route('lessons', function () {
    this.route('game', { path: 'game/:game_id' })
    this.route('lesson', { path: 'lesson/:lesson_id' }, function () {
      this.route('activity', { path: '/:activity_id' })
    })
    this.route('map', { path: 'map/:map_id' }, function () {
      this.route('quiz')
      this.route('next')
    })
    this.route('certificate')
    this.route('placement-test')
    this.route('finished-re-lessons', { path: 'finished-lessons' })
  })

  this.route('storylands', function () {
    this.route('lesson', { path: 'lesson/:lesson_id' }, function () {
      this.route('activity', { path: '/:activity_id' })
    })
    this.route('map', { path: 'map/:map_id' })
    this.route('finished-re-storylands', { path: 'finished-storylands' })
    this.route('bonus-game', { path: 'game/:game_id' })
  })

  this.route('house')
  this.route('awards')
  this.route('plaza')
  this.route('shop', function () {
    this.route('department', { path: ':department' }, function () {
      this.route('catalogue')
    })
  })

  this.route('songs', function () {
    this.route('video', { path: '/:video_id' })
  })

  this.route('puzzles', function () {
    this.route('puzzle', { path: '/:puzzle_id' })
  })

  this.route('critters', function () {
    this.route('critter', { path: '/:critter_id' })
  })

  this.route('play', function () {
    this.route('activity', { path: '/*path' })
  })

  this.route('reader', function () {
    this.route('book', { path: '/:book_id' }, function () {
      this.route('page', { path: '/:page' })
    })
    this.route('result', { path: '/result/:result_id' })
  })
  this.route('story-factory', function () {
    this.route('showroom')
    this.route('floor')
  })

  this.route('games', function () {
    this.route('game', { path: '/:game_id' })
  })

  this.route('my-program')

  this.route('bookcase')
  this.route('bookshelf')
  this.route('changing-room')
  this.route('driving-tests', function () {
    this.route('quiz', { path: ':type/:quiz_id' })
    this.route('racing-game')
  })

  this.route('sample', function () {
    this.route('lesson', { path: 'lesson/:lesson_id' }, function () {
      this.route('activity', { path: '/:activity_id' })
      // backwards compatible route if we ever want to redirect v1
      this.route('activity', { path: 'activity/:activity_id' })
    })
  })

  this.route('no-access', { path: '/no-access/:access_type' })
  this.route('catch-all-routes', { path: '/*' })
  this.route('my-reading-goals', function () {
    this.route('quiz', { path: '/quiz/:quiz_id' })
    this.route('milestones')
  })
  this.route('recommended')
})
