import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/transition'
import { inject as service } from '@ember/service'
import type UserService from 're-client/services/user'
import type { FeatureService } from '@blakeelearning/features'
import type CertificateService from 're-client/services/certificate'
import type AssignmentsService from 're-client/services/assignments'

export default class LessonsRoute extends Route {
  @service declare certificate: CertificateService

  @service declare user: UserService

  @service declare router: RouterService

  @service declare assignments: AssignmentsService

  @service declare features: FeatureService

  override async beforeModel(transition: Transition) {
    // Don't redirect to the certificate route if the bypass is enabled
    if (this.features.isEnabled('bypass_certificate_save')) return

    // Don't redirect to the certificate route while the student is in assignments mode
    if (this.assignments.hasTask) return

    // Don't redirect to the certificate route if we are already heading there
    if (transition.to?.name === 'lessons.certificate') return

    const hasPendingCertificate = await this.certificate.hasPendingCertificate()

    if (hasPendingCertificate) {
      void this.router.replaceWith('lessons.certificate')
    }
  }
}
