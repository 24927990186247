/*
 * This file is required to ensure these URLs are fingerprinted in production
 * and staging builds, so they can be used to dynamically compose absolute URLs
 */
export default {
  default: '/assets/themes/reggies/reggie.png',
  months: [
    '/assets/themes/reggies/animals.png',
    '/assets/themes/reggies/explorers.png',
    '/assets/themes/reggies/sports.png',
    '/assets/themes/reggies/superhero.png',
    '/assets/themes/reggies/offtowork.png',
    '/assets/themes/reggies/underwater.png',
    '/assets/themes/reggies/medieval.png',
    '/assets/themes/reggies/space.png',
    '/assets/themes/reggies/redcarpet.png',
    '/assets/themes/reggies/circus.png',
    '/assets/themes/reggies/fancydress.png',
    '/assets/themes/reggies/celebration.png',
  ],
}
