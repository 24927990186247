import { assert } from '@ember/debug'

export default function formatDueDate(date: Date): string {
  const dateOfMonth = date.getDate()
  const dayOfWeek = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  }[date.getDay()]
  const month = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }[date.getMonth()]

  assert('dayOfWeek must be a string', typeof dayOfWeek === 'string')
  assert('month must be a string', typeof month === 'string')

  return `${dayOfWeek}, ${dateOfMonth} ${month}`
}
