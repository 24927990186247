import Service, { inject as service } from '@ember/service'
import { assert } from '@ember/debug'
import type Store from '@ember-data/store'
import type ThemeService from 're-client/services/theme'
import type UserService from 're-client/services/user'
import type { Log } from '@blakeelearning/log'
import ky from 'ky'

export default class ShopService extends Service {
  @service
  declare theme: ThemeService

  @service
  declare store: Store

  @service
  declare user: UserService

  @service
  declare log: Log

  get student() {
    return this.user.student
  }

  /**
   * The purchase method will call the api to purchase an item and after that we will reload the student
   * to update the eggs, this could be done through the backend by sending the current student
   * back with the response which would save us one round trip.
   * @method purchase
   * @param item - item object which has to have at least the id given like -> head_47
   * @returns - a promise that resolves to a result object
   */
  async purchase({ id }: { id: string }) {
    // add the item id as an ember model id to avoid error with the missing response from the server
    const item = this.store.createRecord('item', {
      code: id,
      studentId: this.student.id,
    })

    try {
      await item.save()
      return { ok: true }
    } catch (error) {
      this.log.error('[Shop][purchase] failed', error as Error)
      item.unloadRecord()
      const { name, message } = error as Error
      return { ok: false, error: { name, message } }
    } finally {
      await this.user.fetch()
    }
  }

  /**
   * PurchaseBundle will make a POST call to the backend with a payload of x items.
   * The method will generate the bundle_purchase url
   *
   * @method purchaseBundle
   * @param codes - make sure this and array of items like ["head_40", "arms_10"]
   * @returns - a promise that resolves to a result object
   */
  async purchaseBundle(codes: string[]) {
    assert('`codes` argument must be an array', Array.isArray(codes))

    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('bundle-items')

    try {
      await ky.post(url, {
        credentials: 'include',
        json: {
          items: codes.map((code) => ({ code })),
        },
      })
      return { ok: true }
    } catch (error) {
      this.log.error('[Shop][purchaseBundle] failed', error as Error)
      const { name, message } = error as Error
      return { ok: false, error: { name, message } }
    } finally {
      await this.user.fetch()
    }
  }

  /**
   * Returns the bundle price for given items
   *
   * @param items - make sure this an array of items like [{ id: "head_40" }]
   */
  bundlePrice(items: string[]) {
    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('bundle-items')
    const searchParams = new URLSearchParams()

    for (const item of items) {
      searchParams.append('items[]', item)
    }

    return ky.get(url, { credentials: 'include', searchParams }).json()
  }
}
