import { inject as service } from '@ember/service'
import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'

export default class PuzzlesIndexRoute extends Route {
  @service
  declare store: Store

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  /**
   * load the puzzle lobby interactive
   * @param {Object[]} puzzleScores
   * @param {String} puzzleScores[].id The ID of the puzzle (eg. 'number_nuts')
   * @param {String} puzzleScores[].puzzle_name The name of the puzzle (eg. 'Number Nuts')
   * @param {Number} puzzleScores[].score The current student's score for the puzzle (eg. 85)
   *
   */
  override async model() {
    const emberPuzzleScores = await this.store.findAll('puzzle-score')
    const puzzleScores = emberPuzzleScores.map((puzzleScore) =>
      puzzleScore.toJSON({ includeId: true }),
    )
    const variables = { student: this.user.student, puzzleScores }

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'puzzle_park_lobby'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
