import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import {
  isOnFinalLessonsMap,
  previousLessonMapId,
} from 're-client/utils/progress-tools'
import type AssignmentsService from 're-client/services/assignments'
import type { FeatureService } from '@blakeelearning/features'

export default class LessonsService extends Service {
  @service declare assignments: AssignmentsService

  @service declare studentProgress: StudentProgressService

  @service declare features: FeatureService

  @tracked
  private map: number | undefined = undefined

  @tracked
  private lesson: string | undefined = undefined

  @tracked
  private activity: number | undefined = undefined

  get currentMap() {
    return this.map ?? this.studentProgress.lessonsCurrentMap
  }

  setCurrentMap(mapId: number) {
    const { lessonsCurrentMap } = this.studentProgress
    this.map = Math.min(
      mapId,
      lessonsCurrentMap,
      this.features.isEnabled('lessons_map_13_enabled')
        ? 13
        : config.APP.lastMap,
    )
    this.lesson = undefined
    this.activity = undefined

    return this
  }

  get currentLesson() {
    if (this.assignments.currentTask?.__typename === 'AssignmentTaskLesson') {
      return this.assignments.currentTask.lessonId
    }

    return this.lesson ?? this.studentProgress.lessonsCurrentLesson
  }

  setCurrentLesson(lesson: string) {
    if (this.isWithinProgress(this.currentMap, lesson)) {
      this.lesson = lesson
      this.activity = undefined
    }

    return this
  }

  get currentActivity() {
    if (this.assignments.currentTask?.__typename === 'AssignmentTaskLesson') {
      return Number(this.assignments.currentTask.activityId)
    }

    if (this.activity) {
      return this.activity
    }

    if (this.studentProgress.lessonsCurrentLesson === this.currentLesson) {
      return this.studentProgress.lessons.currentActivity
    }

    return 1
  }

  setCurrentActivity(activity: number) {
    if (this.isWithinProgress(this.currentMap, this.currentLesson, activity)) {
      this.activity = activity
    }

    return this
  }

  get canPlayQuizForMap() {
    return this.isWithinProgress(this.currentMap, 'quiz')
  }

  canPlayActivity(activityId: number) {
    return this.isWithinProgress(
      this.currentMap,
      this.currentLesson,
      activityId,
    )
  }

  isWithinProgress(
    mapId = this.currentMap,
    lessonId = this.currentLesson,
    activityId = this.currentActivity,
  ) {
    const {
      lessonsCurrentActivity,
      lessonsCurrentLesson,
      lessonsCurrentLessonNumber,
      lessonsCurrentMap,
    } = this.studentProgress

    const lessonNumber = Number(lessonId)
    const isCurrentLessonQuiz = lessonsCurrentLesson === 'quiz'

    const isPreviousMap = mapId < lessonsCurrentMap
    const isPreviousLesson = isCurrentLessonQuiz
      ? lessonNumber <= lessonsCurrentLessonNumber
      : lessonNumber < lessonsCurrentLessonNumber

    const isCurrentMap = mapId === lessonsCurrentMap
    const isCurrentLesson = lessonId === lessonsCurrentLesson

    if (isCurrentMap && isCurrentLesson) {
      return activityId <= lessonsCurrentActivity
    }

    return (isCurrentMap && isPreviousLesson) || isPreviousMap
  }

  get isQuizComplete() {
    return this.currentMap < this.studentProgress.lessonsCurrentMap
  }

  get isOnFinalMap() {
    if (this.features.isEnabled('lessons_map_13_enabled')) {
      return isOnFinalLessonsMap(this.currentMap, 13)
    }
    return isOnFinalLessonsMap(this.currentMap)
  }

  get previousMapId() {
    if (this.features.isEnabled('lessons_map_13_enabled')) {
      return previousLessonMapId(this.currentMap, 13)
    }
    return previousLessonMapId(this.currentMap)
  }

  get nextMapId() {
    return this.currentMap + 1
  }
}

declare module '@ember/service' {
  interface Registry {
    lessons: LessonsService
  }
}
