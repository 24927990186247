import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type LocationTrackerService from 're-client/services/location-tracker'
import type RouterService from '@ember/routing/router-service'

export default class ReaderBookPageController extends Controller {
  @service
  declare locationTracker: LocationTrackerService

  @service
  declare router: RouterService

  @action
  next() {
    this.backToQuiz()
  }

  @action
  backToQuiz() {
    const { route, params } = this.locationTracker.getPreviousRoute()
    this.router.transitionTo(
      route,
      // @ts-expect-error router params type no bueno
      ...params,
    )
  }
}
