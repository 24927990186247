import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type UserService from 're-client/services/user'

export default class ApplicationController extends Controller {
  @service
  declare user: UserService

  get locale() {
    return this.user.locale
  }
}
