import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

export default class MysteryGiftRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  /**
   * We need to check if we are allow to reward gifts and redirect back to the current map
   * based on the students progress
   * @returns {Promise<void>}
   */
  override beforeModel() {
    const { mysteryGiftAvailable } = this.user

    if (!mysteryGiftAvailable) {
      void this.router.transitionTo(
        'spelling.map',
        this.studentProgress.spellingCurrentMap,
      )
      return
    }

    if (this.studentProgress.isAreaLocked('spelling')) {
      void this.router.transitionTo('index')
    }
  }

  override async model() {
    const variables = {}

    const manifests = [this.urlMaker.urlForInteractive('skills-bank', 'shop')]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
