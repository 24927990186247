import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import { service } from '@ember/service'
import type UserService from 're-client/services/user'

export default class GamesRoute extends Route {
  @service
  declare user: UserService

  @service
  declare router: RouterService

  override beforeModel() {
    if (!this.user.student.gamesAccess) {
      void this.router.transitionTo('no-access', 'games')
    }
  }
}
