import type Owner from '@ember/owner'
import { service } from '@ember/service'
import { bind } from 'bind-event-listener'
import Modifier, { type ArgsFor, type PositionalArgs } from 'ember-modifier'
import { registerDestructor } from '@ember/destroyable'
import type SoundsService from 're-client/services/sounds'

interface PlaySoundOnSignature {
  Element: HTMLButtonElement
  Args: {
    Positional: [on: string, sound: string]
  }
}

function cleanup(instance: PlaySoundOnModifier) {
  instance.unbind()
}

export default class PlaySoundOnModifier extends Modifier<PlaySoundOnSignature> {
  @service
  declare sounds: SoundsService

  unbind = () => {}

  constructor(owner: Owner, args: ArgsFor<PlaySoundOnSignature>) {
    super(owner, args)
    registerDestructor(this, cleanup)
  }

  override modify(
    element: PlaySoundOnSignature['Element'],
    [on, sound]: PositionalArgs<PlaySoundOnSignature>,
  ) {
    cleanup(this)

    this.unbind = bind(element, {
      type: on,
      listener: () => {
        this.sounds.play(sound)
      },
    })
  }
}
