import type Store from '@ember-data/store'
import JSONSerializer from '@ember-data/serializer/json'
import { inject as service } from '@ember/service'

// This serializer exists so it's registered with Ember, and we can use it
// elsewhere by looking it up in Ember's registry.
// We use it to reimplement the deprecated `model.toJSON` serialization of
// some of our models, to match the legacy expectations of our Caper activities.
// Without this registration, the serializer's method can fail when
// attempting Ember.getOwner(this).
export default class LegacyJSONSerializer extends JSONSerializer {
  @service
  declare store: Store
}
