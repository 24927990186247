import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'
import type AssignmentsService from 're-client/services/assignments'

export default class DrivingTestsIndexRoute extends Route {
  @service declare assignments: AssignmentsService
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  get drivingTestsLocked() {
    return (
      this.studentProgress.isAreaLocked('drivingTests') &&
      this.assignments.currentTask?.__typename !== 'AssignmentTaskDrivingTests'
    )
  }

  override beforeModel() {
    if (this.drivingTestsLocked) {
      void this.router.replaceWith('index')
    }
  }

  override async model() {
    const { sightWords, lettersAndSounds, contentWords } =
      this.studentProgress.drivingTests

    const drivingTestsPosition = {
      sight_words: sightWords,
      letters_and_sounds: lettersAndSounds,
      content_words: contentWords,
    }
    const variables = {
      student: this.user.student,
      drivingTestsPosition,
    }

    const manifests = [
      this.urlMaker.urlForInteractive('driving-tests', 'driving_test_lobby'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
