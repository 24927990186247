export default {
  recommended_books: [
    {
      id: 'funny_photo_vegetables_in_a_van',
      code: 'funny_photo_vegetables_in_a_van',
      cover_url:
        'https://books.static.readingeggs.com/covers/au/funny_photo/funny_photo_vegetables_in_a_van/preview_front_funny_photo_vegetables_in_a_van.jpg',
      accent: 'au',
    },
    {
      id: 'reading_eggs_three_seeds',
      code: 'reading_eggs_three_seeds',
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_three_seeds/preview_front_reading_eggs_three_seeds.jpg',
      accent: 'au',
    },
    {
      id: 'reading_eggs_vans',
      code: 'reading_eggs_vans',
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_vans/preview_front_reading_eggs_vans.jpg',
      accent: 'au',
    },
    {
      id: 'reading_eggs_i_like_vegetables',
      code: 'reading_eggs_i_like_vegetables',
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_i_like_vegetables/preview_front_reading_eggs_i_like_vegetables.jpg',
      accent: 'au',
    },
  ],
  books_read: 18,
}
