import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/songs/songs-lobby.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/songs/songs-lobby.hbs"}});
import Component from '@glimmer/component'

export default class SongsLobby extends Component {
  showVideo(video) {
    this.args.showVideoAction(video)
  }

  playVideo(video) {
    this.args.playVideoAction(video)
  }
}
