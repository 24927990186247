import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow'

setupDeprecationWorkflow({
  workflow: [
    { handler: 'silence', matchId: 'template-action' },
    { handler: 'silence', matchId: 'ember-string.add-package' },
    { handler: 'throw', matchId: 'routing.transition-methods' },
    { handler: 'throw', matchId: 'ember-test-waiters-legacy-module-name' },
  ],
})
