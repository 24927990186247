export type DebugActionParam =
  | {
      type: 'checkbox' | 'date' | 'number' | 'text'

      value?: string | undefined
    }
  | {
      type: 'select'

      options: { label: string; value: string }[]

      values?: Record<string, unknown>
    }

export type DebugAction = Record<string, DebugActionParam>

/**
 * Marks this property as a debug value.
 * This property will be displayed in the debug menu as JSON.
 *
 * @example
 * ```ts
 * @debugValue()
 * get someValue() {
 *   return { foo: 'bar', baz: true }
 * }
 * ```
 *
 * @returns a property decorator
 */
export function debugValue(): PropertyDecorator {
  return function (target, propertyKey) {
    const debug = getDebug(target)
    debug.values.add(propertyKey)
  }
}

/**
 * Marks this action as a debug action.
 * This action and it's params will be avaliable in the debug menu.
 *
 * Each param will be displayed as an input element with the provided type.
 * Default values can optionally be provided for type 'text' | 'number' | 'object'.
 *
 * @param params Debug action params
 * @returns a method decorator
 *
 * @example
 * ```ts
 * @action
 * @debugAction({ foo: {type: 'text', value: 'baz'}, bar: {type: 'date'} })
 * doSomething(foo: string, bar: Date) {
 *   // do something
 * }
 * ```
 */
export function debugAction(params?: DebugAction): MethodDecorator {
  return function (target, propertyKey) {
    const debug = getDebug(target)
    debug.actions.set(propertyKey, params)
  }
}

export function debugSubscription(): MethodDecorator {
  return function (target, propertyKey) {
    const debug = getDebug(target)
    debug.subscriptions.add(propertyKey)
  }
}

/**
 * Context for the content-interactive debug menu
 */
export class DebugContent {
  /**
   * Set of keys to lookup when debugging component values
   */
  values = new Set<string | symbol>()

  /**
   * Map of keys and config to lookup when debugging component actions
   */
  actions = new Map<string | symbol, DebugAction | undefined>()

  /**
   * Set of something
   */
  subscriptions = new Set<string | symbol>()
}

/** Unique symbol to lookup the DebugContent class */
export const DebugContentSymbol = Symbol.for('debug-content')

/**
 * Gets the DebugContent of an object. Creates a new DebugContent if one doesn't exists already.
 *
 * @param context object to apply the DebugContent too
 * @returns The DebugContent
 */
export function getDebug(context: object): DebugContent {
  const debug: unknown = Reflect.get(context, DebugContentSymbol)

  if (debug instanceof DebugContent) {
    return debug
  }

  return setDebug(context, new DebugContent())
}

/**
 * Sets the provided DebugContent on the context object
 *
 * @param context object to apply the DebugContent too
 * @param debug the DebugContent to apply to the context
 * @returns The DebugContent
 */
export function setDebug(context: object, debug: DebugContent): DebugContent {
  Reflect.defineProperty(context, DebugContentSymbol, {
    value: debug,
  })

  return debug
}
