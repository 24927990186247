import { modifier } from 'ember-modifier'
import { runInDebug } from '@ember/debug'

/**
 * Modifier which removes the initial loading screen (the one provided by the rails app) from the DOM
 */
export default modifier(() => {
  const initialLoadingScreen = document.getElementById('initial-loading-screen')
  if (initialLoadingScreen?.parentNode) {
    runInDebug(() => {
      console.debug(
        `%c[remove-initial-loading-screen modifier] removing #initial-loading-screen`,
        'color: red; font-size: 1.1rem',
      )
    })
    initialLoadingScreen.style.opacity = '0'
    setTimeout(() => {
      if (initialLoadingScreen.parentNode) {
        initialLoadingScreen.parentNode.removeChild(initialLoadingScreen)
      }
    }, 300)
  }

  // TODO: once re-student has been updated with the new loading screen (#initial-loading-screen) this can be removed.
  const svgContainer = document.getElementById('loading-svg')
  if (svgContainer?.parentNode) {
    runInDebug(() => {
      console.debug(
        `%c[remove-initial-loading-screen modifier] removing #loading-svg`,
        'color: red; font-size: 1.1rem',
      )
    })
    svgContainer.parentNode.removeChild(svgContainer)
  }
})
