import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

// eslint-disable-next-line ember/no-test-import-export
export default class LessonsPlacementTestRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  override beforeModel() {
    if (!this.studentProgress.showLessonsPlacementTest) {
      void this.router.transitionTo(
        'lessons.map',
        this.studentProgress.lessonsCurrentMap,
      )
    }
  }

  override async model() {
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('reading/placement-test'),
    ]
    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
