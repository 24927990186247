import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'

// eslint-disable-next-line ember/no-test-import-export
export default class PlacementTestRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  override beforeModel() {
    const { environment: env } = config
    const canAlwaysVisitPlacementTest = env === 'development'

    if (
      !this.studentProgress.showSpellingPlacementTest &&
      !canAlwaysVisitPlacementTest
    ) {
      void this.router.transitionTo(
        'spelling.map',
        this.studentProgress.spellingCurrentMap,
      )
      return
    }

    if (this.studentProgress.isAreaLocked('spelling')) {
      void this.router.transitionTo('index')
    }
  }

  override async model() {
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('spelling', 'placement_test'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
