import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type LessonsService from 're-client/services/lessons'

/**
 * The lesson index route
 */
export default class LessonsLessonIndexRoute extends Route {
  @service
  declare lessons: LessonsService

  @service
  declare router: RouterService

  /**
   * Transition to the current activity in the lesson, based on the student's progress, or the first activity for the lesson
   */
  override afterModel() {
    const { currentLesson, currentActivity } = this.lessons
    void this.router.transitionTo(
      'lessons.lesson.activity',
      currentLesson,
      currentActivity,
    )
  }
}
