import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/shop-game/catalogue.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/shop-game/catalogue.hbs"}});
import Component from '@glimmer/component'

export default class Catalogue extends Component {
  /**
   * Action to purchase a single item.
   *
   * @param {{id:string}} [item] - an object with an `id` property
   *   corresponding to the item to purchase.
   * @returns {undefined}
   */
  async purchaseItem(item) {
    const result = await this.args.purchaseAction(item)
    this.interactive.callInteractionMethod('completeItemPurchase', {
      id: item.id,
      result,
    })
  }

  /**
   * Action to purchase a bundle of items. The interactive sends an object with a bundleId
   * and a list of items which returns a response, which can include information the frontend / interactive
   * can use to make decisions, like errors.
   *
   * @param {Array} items
   * @param {String} bundleId
   * @returns {undefined}
   */
  async purchaseBundle({ items, bundleId }) {
    const result = await this.args.purchaseBundleAction(items)
    this.interactive.callInteractionMethod('completeBundlePurchase', {
      bundleId,
      result,
    })
  }

  /**
   * Action to request the bundle price based on the bundleId and items passed down
   * The price can change if a student has already purchased an item of the bundle
   *
   * @param {Array} items
   * @param {String} bundleId
   * @returns {undefined}
   */
  async bundlePrice({ items, bundleId }) {
    const response = await this.args.bundlePriceAction(items)
    response.bundleId = bundleId
    this.interactive.callInteractionMethod('completeBundlePrice', response)
  }

  /**
   * Returns to the currently active department
   * @returns {undefined}
   */
  backToShop() {
    this.args.backToShopAction()
  }

  /**
   * This can be used to retrieve the time to next month which returns
   * an object with days and hours.
   * @returns {{ days: Number, hours: Number }}
   */
  timeToNextMonth() {
    return this.args.timeToNextMonthAction()
  }
}
