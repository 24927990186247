import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'

export default class SongsVideoRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model({ video_id: videoId }: Record<string, string>) {
    const variables = {
      showCloseButton: true,
      skippable: true,
      show_end_buttons: false,
      skipSaveProgress: true,
    }

    const manifests = [
      this.urlMaker.urlForInteractive('reading/video', videoId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
