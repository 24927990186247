import { http, HttpResponse } from 'msw'
import type { RequestHandler } from 'msw'
import {
  BadgeColourEnum,
  mockAcknowledgeQuestGoalAlertMutation,
  mockCreateReadingGoalsQuizResultMutation,
  mockGetCertificatesQuery,
  mockGetInitialAppDataQuery,
  mockGetMyAwardsQuery,
  mockGetPendingCertificateQuery,
  mockGetPendingCertificateUploadUrlQuery,
  mockGetPlazaPollsQuery,
  mockGetReadingGoalsQuizRecordQuery,
  mockGetReadingGoalsQuizzesQuery,
  mockGetStudentCurrentTaskQuery,
  mockGetStudentEssentialQuestQuery,
  mockGetStudentQuery,
  mockGetStudentQuestQuery,
  mockReadingActivitiesQuery,
  mockSaveCertificateMutation,
  mockSaveReadingLessonActivityResultMutation,
  mockSaveSkillsQuizResultMutation,
  mockStudentProgressQuery,
  QuestGoalStatusEnum,
} from 're-client/graphql/graphql'
import { db } from './db'

export const scenarios: Record<string, RequestHandler[]> = {
  unauthenticated: [
    http.get('/auth/session', () => {
      return HttpResponse.json({
        data: {},
      })
    }),
    mockGetInitialAppDataQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: null,
        },
      })
    }),
    mockReadingActivitiesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingActivities: db.getReadingActivities(),
        },
      })
    }),
  ],
  default: [
    mockGetInitialAppDataQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockReadingActivitiesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingActivities: db.getReadingActivities(),
        },
      })
    }),
    mockStudentProgressQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentQuestQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentCurrentTaskQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentEssentialQuestQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetCertificatesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetMyAwardsQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPlazaPollsQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          plazaPolls: db.getPlazaPolls(),
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateUploadUrlQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          getPendingCertificate: db.getPendingCertificateUploadUrl(),
        },
      })
    }),
    mockCreateReadingGoalsQuizResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }
      const { answers, quizId } = variables.input
      const passed =
        answers.filter((answer) => answer.correct).length / answers.length >=
        0.85
      const eggsReward = passed ? 10 : 0

      student.eggs += eggsReward

      db.createReadingGoalsQuizSummary({
        attemptsThisWeek: 1,
        eggsReward,
        id: quizId,
        passed,
        secondsToPass: 0,
        timestamp: new Date().toISOString(),
      })

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingGoalsQuizResultCreate: {
            __typename: 'ReadingGoalsQuizResultCreateOutput',
            student,
            readingGoalsQuizResult: {
              __typename: 'ReadingGoalsQuizResult',
              eggsReward,
              passed,
            },
          },
        },
      })
    }),
    mockGetReadingGoalsQuizzesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingGoalsQuizzes: db.getReadingGoalQuizzes(),
        },
      })
    }),
    mockAcknowledgeQuestGoalAlertMutation(({ variables }) => {
      const goal = db.acknowledgeQuestGoal(variables.input.goalId)

      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      if (goal) {
        student.eggs += goal.eggReward
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          studentQuestGoalAcknowledgeComplete: {
            __typename: 'StudentQuestGoalAcknowledgeCompletePayload',
            student,
          },
        },
      })
    }),
    mockGetReadingGoalsQuizRecordQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockSaveCertificateMutation(({ variables }) => {
      const certificate = db.saveCertificate(variables.input)
      const student = db.getStudent()

      if (!student || !certificate) {
        return HttpResponse.json({
          data: null,
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          certificateSave: {
            __typename: 'CertificateSavePayload',
            student,
            certificate,
          },
        },
      })
    }),
    mockSaveSkillsQuizResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { answers, assignment, lessonInPrecinct } = variables.input
      const correctCount = answers.filter((answer) => answer.isCorrect).length
      const passed = correctCount >= 8

      /**
       * For now these values are hardcoded here.
       * We could create some quiz result records in the mirage db to enable us to react to multiple attempts like the real backend.
       * But since the logic around attempts and auto passing is a bit complex this is good enough for now.
       */
      const attempt = 1
      const autoPassed = false
      const eggsEarned = passed ? 10 : 0

      if (assignment) {
        const { assignmentTaskId } = assignment

        db.deleteAssignmentTask(assignmentTaskId.toFixed())
      } else {
        // Update quest goal progress
        const questGoal = student.quest?.primaryGoal

        if (questGoal?.__typename === 'QuestGoalLesson') {
          questGoal.progressCurrent = questGoal.progressCurrent + 1
        }
      }

      if (passed) {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          lessons: {
            ...previous.lessons,
            currentActivity: previous.lessons.currentActivity + 1,
          },
        }))

        // Lets work out the badges :)
        const badgeColourScores = new Map([
          [BadgeColourEnum.Bronze, 8],
          [BadgeColourEnum.Silver, 9],
          [BadgeColourEnum.Gold, 10],
        ])

        const bestPreviousBadge = db.findBadge(
          (badge) => badge.lesson === lessonInPrecinct,
        )
        const bestPreviousBadgeColour = bestPreviousBadge?.colour

        let bestPreviousScore = 0

        if (bestPreviousBadgeColour) {
          bestPreviousScore =
            badgeColourScores.get(bestPreviousBadgeColour) ?? 0
        }

        // we only need to make a badge and update the student if the new score is better
        if (correctCount > bestPreviousScore) {
          let colour
          for (const [badgeColor, score] of badgeColourScores) {
            if (correctCount === score) {
              colour = badgeColor
            }
          }

          if (colour) {
            db.createBadge({
              colour,
              lesson: lessonInPrecinct,
            })
          }
        }
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingEndOfLessonQuizResultCreate: {
            __typename: 'ReadingEndOfLessonQuizResultCreateOutput',
            student,
            readingEndOfLessonQuizResult: {
              __typename: 'ReadingEndOfLessonQuizResult',
              attempt,
              autoPassed,
              passed,
              eggsEarned,
              lessonInPrecinct,
            },
          },
        },
      })
    }),
    mockSaveReadingLessonActivityResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { assignment, lessonInPrecinct, activityInLesson } = variables.input

      if (assignment) {
        const { assignmentTaskId } = assignment

        db.deleteAssignmentTask(assignmentTaskId.toFixed())
      } else {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          lessons: {
            ...previous.lessons,
            currentActivity: previous.lessons.currentActivity + 1,
          },
        }))

        const questGoal = student.quest?.primaryGoal

        if (
          questGoal?.__typename === 'QuestGoalLesson' &&
          questGoal.lesson === lessonInPrecinct &&
          questGoal.progressCurrent < activityInLesson
        ) {
          const status =
            activityInLesson === questGoal.progressTotal
              ? QuestGoalStatusEnum.ShowCompleteAlert
              : QuestGoalStatusEnum.Incomplete
          db.updateQuestGoal(questGoal, {
            progressCurrent: activityInLesson,
            status,
          })
        }
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingLessonActivityResultCreate: {
            __typename: 'ReadingLessonActivityResultCreateOutput',
            student,
            readingLessonActivityResult: {
              __typename: 'ReadingLessonActivityResult',
              reward: {
                __typename: 'ReadingLessonActivityReward',
                eggs: 10,
              },
            },
          },
        },
      })
    }),
  ],
}
