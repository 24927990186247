import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type { ParamsFor } from 're-client/utils/route-model'
import type ReaderBookRoute from 're-client/routes/reader/book'

export default class ReaderBookPageRoute extends Route {
  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override async model({ page }: Record<string, string>) {
    const { student } = this.user
    const { book_id: bookId } = this.paramsFor(
      'reader.book',
    ) as ParamsFor<ReaderBookRoute>
    const variables = {
      student,
      pageNumber: page,
      clinkerCastleHint: true,
      quiz: null,
    }

    const manifests = [
      // The book reader activity manifest, without any of the book data
      this.urlMaker.urlForInteractive('reading', 'reading'),
      // re-student endpoint will return a manifest only containing the specific book variables data
      this.caperLoader.readingBookRequest(bookId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
