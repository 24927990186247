import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import type { ModelFor } from 're-client/utils/route-model'
import type SpellingMapIndexRoute from 're-client/routes/spelling/map/index'

/**
 * Spelling Map Controller
 *
 * @class SpellingMapController
 */
export default class IndexController extends Controller {
  declare model: ModelFor<SpellingMapIndexRoute>
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare router: RouterService

  @action
  playLesson(lessonId: string) {
    return this.router.transitionTo('spelling.lesson', lessonId)
  }

  @action
  playQuiz() {
    this.router.transitionTo('spelling.map.quiz', this.model.mapId)
  }

  @action
  prevMap() {
    this.router.transitionTo('spelling.map.index', this.model.previousMapId)
  }

  @action
  nextMap() {
    if (this.model.isOnFinalMap) {
      this.router.transitionTo('spelling.finished-re-spelling')
    } else {
      this.router.transitionTo('spelling.map.index', this.model.nextMapId)
    }
  }
}
