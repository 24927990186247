import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type SpellingService from 're-client/services/spelling'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'

/**
 * Faux-model for the SpellingLessonsMapRouter
 *
 * @class SpellingLessonsMapObject
 * @property {Number} mapId The map id
 */
export interface MapRouteModel {
  mapId: number
}

interface RouteParams {
  map_id: string
}

/**
 * Loads student progress and checks to see if the map can be played according
 * to the progress loaded.
 *
 * @class SpellingMapRoute
 */
export default class MapRoute extends Route<MapRouteModel, RouteParams> {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare spelling: SpellingService

  @service
  declare router: RouterService

  @service
  declare user: UserService

  override beforeModel() {
    if (this.studentProgress.showSpellingPlacementTest) {
      void this.router.transitionTo('spelling.placement-test')
      return
    }

    if (this.studentProgress.isAreaLocked('spelling')) {
      void this.router.transitionTo('index')
    }
  }

  override model(params: RouteParams) {
    const mapId = parseInt(params.map_id, 10)
    this.spelling.setCurrentMap(mapId)
    return { mapId }
  }

  override afterModel(model: MapRouteModel) {
    // playability *only* accounts for if the currentMap is greater than the
    // map asked for in the route, it does not care about overflows ie asking for map 1000
    if (model.mapId !== this.spelling.currentMap) {
      void this.router.transitionTo('spelling.map', this.spelling.currentMap)
    }
  }
}
