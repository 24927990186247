/**
 * Preload given urls
 * Kindly taken from https://gist.github.com/chrism/c35756037f5feaac5023
 * @param urls
 * @returns {Promise}
 */
export default function preloadImages(...urls: string[]) {
  const promises = urls.map(
    (url) =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = url
      }),
  )

  return Promise.all(promises)
}
