import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type { MyAwardsContentActions } from '@blakeelearning/content-specs-readingeggs'
import { myAwardsContentSpec } from '@blakeelearning/content-specs-readingeggs'
import type { ContentSpec } from '@blakeelearning/messenger'
import type ErrorHandlerService from 're-client/services/error-handler'
import { debug } from '@ember/debug'
import type { PrintMessage } from 're-client/controllers/lessons/certificate'
import { contentError } from 're-client/utils/errors'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'
import type { ModelFor } from 're-client/utils/route-model'
import { debugAction, debugValue } from 're-client/utils/debug'
import type LoadingUiService from 're-client/services/loading-ui'
import printImage from 're-client/utils/print-image'
import type AwardsRoute from 're-client/routes/awards'

export default class AwardsController
  extends Controller
  implements MyAwardsContentActions
{
  @debugValue()
  declare model: ModelFor<AwardsRoute>

  @service declare router: RouterService

  @service declare errorHandler: ErrorHandlerService

  @service('device/detection') declare deviceDetection: DeviceDetection

  @service declare loadingUi: LoadingUiService

  get currentMap() {
    if (!('variables' in this.model))
      throw new Error('No currentMap in V1 model variables')
    return this.model.variables.currentMap
  }

  get contentSpec(): ContentSpec {
    return myAwardsContentSpec
  }

  @action
  readyForUserInput() {
    debug(`[CertificateController] readyForUserInput()`)
    this.loadingUi.hide() // clear the loading screen
  }

  @action
  unhandledError({ error }: { error: { name?: string; message?: string } }) {
    debug(`[CertificateController] unhandledError()`)
    this.errorHandler.handleContentUnhandledError(error)
  }

  @debugAction({
    certificateUrl: {
      type: 'text',
      value:
        'https://certificates.blake-staging.com/re/legacy/WzEsImdvbGQiLDEwMCwiU2FtcGxlIEMuIiwxNjc5NDYyNzc0NTQ1XQ.svg', // TODO: this value is temporary to assist with native app testing
    },
  })
  @action
  async print({ certificateUrl }: PrintMessage): Promise<void> {
    debug(`[AwardsController] print()`)

    if (this.deviceDetection.isNativeMobile) {
      const nativeMobilePrintUrl = new URL(certificateUrl)
      // The prescence of this param will result in an SVG element with `width=100% height=auto` attributes. The mobile apps require this for print layout.
      nativeMobilePrintUrl.searchParams.set('fitpage', '')
      // Mobile apps will intercept these calls and take care of printing from there.
      window.open(nativeMobilePrintUrl.href, '_blank')
      return
    }
    try {
      await printImage(certificateUrl)
    } catch (error) {
      throw contentError('PrintFailed', error)
    }
  }

  @debugAction()
  @action
  goHome() {
    debug(`[CertificateController] goHome()`)
    void this.router.transitionTo('/')
  }

  @debugAction()
  @action
  goNext() {
    debug(`[CertificateController] goNext()`)
    if (this.currentMap) {
      void this.router.transitionTo('lessons.map.next', this.currentMap)
    }
  }

  @action
  goToHouse() {
    void this.router.transitionTo('house')
  }
}
