import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import { correctSpellingMap } from 're-client/utils/progress-tools'
import { action } from '@ember/object'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type OfflineScreenService from 're-client/services/offline-screen'
import type DeviceNetwork from '@blakeelearning/device/device/network/service'
import type RouterService from '@ember/routing/router-service'

export default class LessonsFinishedReLessonsController extends Controller {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare offlineScreen: OfflineScreenService

  @service('device/network')
  declare network: DeviceNetwork

  @service
  declare router: RouterService

  @action
  goToSkillsBank() {
    this.router.transitionTo(
      'spelling.map',
      correctSpellingMap(this.studentProgress.spellingCurrentMap),
    )
  }

  @action
  goToStorylands() {
    this.router.transitionTo(
      'storylands.map',
      this.studentProgress.storylandsCurrentMap,
    )
  }

  @action
  goToIndex() {
    this.router.transitionTo('index')
  }

  @action
  goToReadingEggspress() {
    this.redirectToReadingEggspress()
  }

  @action
  redirectToReadingEggspress(location = window.location) {
    if (this.network.status.isOffline) {
      this.offlineScreen.display()
      return
    }

    const base = config.APP.apiEndpoint ?? location.origin
    const rexLoginUrl = new URL('rex', base)
    location.assign(rexLoginUrl)
  }

  @action
  goToPrevMap() {
    this.router.transitionTo('lessons.map', this.studentProgress.lessonsLastMap)
  }
}
