import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li\n  class=\"activity-item {{this.status}}\"\n  data-item={{this.id}}\n  data-status={{this.status}}\n  role=\"button\"\n  ...attributes\n>\n  <img src={{this.thumbnail}} alt=\"activity item\" />\n  <span class=\"activity-item-number\">{{this.id}}</span>\n  <span class=\"activity-item-status\">\n    <FaIcon @icon={{this.icon}} />\n  </span>\n</li>", {"contents":"<li\n  class=\"activity-item {{this.status}}\"\n  data-item={{this.id}}\n  data-status={{this.status}}\n  role=\"button\"\n  ...attributes\n>\n  <img src={{this.thumbnail}} alt=\"activity item\" />\n  <span class=\"activity-item-number\">{{this.id}}</span>\n  <span class=\"activity-item-status\">\n    <FaIcon @icon={{this.icon}} />\n  </span>\n</li>","moduleName":"re-client/components/activity-sidebar/activity-item.hbs","parseOptions":{"srcName":"re-client/components/activity-sidebar/activity-item.hbs"}});
import Component from '@glimmer/component'

interface Args {
  activity: SideBarActivityData
}

export interface SideBarActivityData {
  id: number
  status: 'unlocked' | 'locked' | 'complete'
  thumbnail: string | undefined
}

export default class ActivityItem extends Component<Args> {
  get activity() {
    return this.args.activity
  }

  get id() {
    return this.activity.id
  }

  get status() {
    return this.activity.status
  }

  get icon() {
    if (this.status === 'unlocked') return 'lock-open'
    if (this.status === 'complete') return 'check'
    return 'lock'
  }

  get thumbnail() {
    return this.activity.thumbnail
  }
}
