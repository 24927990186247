import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\">\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\">\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/changing-room.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/changing-room.hbs"}});
import Component from '@glimmer/component'

export default class ChangingRoom extends Component {
  async saveAvatar(DNA, image) {
    await this.args.saveAvatarAction(DNA, image)
    this.interactive.callInteractionMethod('nextable')
  }
}
