import { assert } from '@ember/debug'

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export function getMonth(monthIndex: number) {
  const month = MONTHS[monthIndex]
  assert(`Month ${monthIndex} not found in MONTHS map`, month)
  return month
}

/**
 * Converts a Date object to a date string
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#date_strings | Date and time formats}
 *
 * @param input the input date
 * @returns a date string
 */
export function toDateString(input: Date) {
  const [date] = input.toISOString().split('T')
  assert(`Invalid date: ${input.toString()}`, date)
  return date
}

export function isValidDate(date: Date) {
  try {
    const time = date.getTime()
    return !Number.isNaN(time)
  } catch {
    return false
  }
}
