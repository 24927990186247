import { inject as service } from '@ember/service'
import { get, set } from '@ember/object'
import { camelize } from '@ember/string'
import ApplicationSerializer from 're-client/serializers/application'

// This class was extracted from ember-data, which provides it as a Mixin.
// https://github.com/emberjs/data/blob/669361954b96f622b1f04ec3150717b6a7f80081/packages/serializer/addon/-private/embedded-records-mixin.js#L135
// Mixins are antithetical to the Octane programming model, which prefers native classes,
// and are under discussion for removal in Ember 4.0 or 5.0.
// Embedded API records are a legacy use case, and we don't intend to use
// it for any new features.
// Furthermore, this app only deserializes embedded records at the time this
// class was extracted (May 2021), so support for serializing records has been
// intentionally neutered to prevent expanded usage of this legacy functionality.
// This file may need updating by adapting from the ember-data repo, if bugfixes are
// contributed there that we want.
export default class ApplicationEmbeddedRecordsSerializer extends ApplicationSerializer {
  @service
  store

  serialize() {
    throw new Error(
      'ApplicationEmbeddedRecordsSerializer does not support serializing records, only deserializing / normalizing.',
    )
  }

  /**
    Normalize the record and recursively normalize/extract all the embedded records
    while pushing them into the store as they are encountered

    A payload with an attr configured for embedded records needs to be extracted:

    ```js
    {
      "post": {
        "id": "1"
        "title": "Rails is omakase",
        "comments": [{
          "id": "1",
          "body": "Rails is unagi"
        }, {
          "id": "2",
          "body": "Omakase O_o"
        }]
      }
    }
    ```
    @method normalize
    @param {Model} typeClass
    @param {Object} hash to be normalized
    @param {String} prop the hash has been referenced by
    @return {Object} the normalized hash
  */
  normalize(typeClass, hash, prop) {
    const normalizedHash = super.normalize(typeClass, hash, prop)
    return this._extractEmbeddedRecords(
      this,
      this.store,
      typeClass,
      normalizedHash,
    )
  }

  keyForRelationship(key, typeClass, method) {
    if (method === 'deserialize' && this.hasDeserializeRecordsOption(key)) {
      return this.keyForAttribute(key, method)
    }
    return super.keyForRelationship(key, typeClass, method) || key
  }

  // checks config for attrs option to embedded (always) - serialize and deserialize
  hasEmbeddedAlwaysOption(attr) {
    const option = this.attrsOption(attr)
    return option && option.embedded === 'always'
  }

  // checks config for attrs option to deserialize records
  // a defined option object for a resource is treated the same as
  // `deserialize: 'records'`
  hasDeserializeRecordsOption(attr) {
    const alwaysEmbed = this.hasEmbeddedAlwaysOption(attr)
    const option = this.attrsOption(attr)
    return alwaysEmbed || (option && option.deserialize === 'records')
  }

  attrsOption(attr) {
    const { attrs } = this
    return attrs && (attrs[camelize(attr)] || attrs[attr])
  }

  /**
     @method _extractEmbeddedRecords
     @private
    */
  _extractEmbeddedRecords(serializer, store, typeClass, partial) {
    typeClass.eachRelationship((key, relationship) => {
      if (serializer.hasDeserializeRecordsOption(key)) {
        if (relationship.kind === 'hasMany') {
          this._extractEmbeddedHasMany(store, key, partial, relationship)
        }
        if (relationship.kind === 'belongsTo') {
          this._extractEmbeddedBelongsTo(store, key, partial, relationship)
        }
      }
    })
    return partial
  }

  /**
     @method _extractEmbeddedHasMany
     @private
    */
  _extractEmbeddedHasMany(store, key, hash, relationshipMeta) {
    const relationshipHash = get(hash, `data.relationships.${key}.data`)

    if (!relationshipHash) {
      return
    }

    const hasMany = new Array(relationshipHash.length)

    for (let i = 0; i < relationshipHash.length; i++) {
      const item = relationshipHash[i]
      const { data, included } = this._normalizeEmbeddedRelationship(
        store,
        relationshipMeta,
        item,
      )
      hash.included = hash.included || []
      hash.included.push(data)
      if (included) {
        hash.included.push(...included)
      }

      hasMany[i] = { id: data.id, type: data.type }
    }

    const relationship = { data: hasMany }
    set(hash, `data.relationships.${key}`, relationship)
  }

  /**
     @method _extractEmbeddedBelongsTo
     @private
    */
  _extractEmbeddedBelongsTo(store, key, hash, relationshipMeta) {
    const relationshipHash = get(hash, `data.relationships.${key}.data`)
    if (!relationshipHash) {
      return
    }

    const { data, included } = this._normalizeEmbeddedRelationship(
      store,
      relationshipMeta,
      relationshipHash,
    )
    hash.included = hash.included || []
    hash.included.push(data)
    if (included) {
      hash.included.push(...included)
    }

    const belongsTo = { id: data.id, type: data.type }
    const relationship = { data: belongsTo }

    set(hash, `data.relationships.${key}`, relationship)
  }

  /**
     @method _normalizeEmbeddedRelationship
     @private
    */
  _normalizeEmbeddedRelationship(store, relationshipMeta, relationshipHash) {
    let modelName = relationshipMeta.type
    if (relationshipMeta.options.polymorphic) {
      modelName = relationshipHash.type
    }
    const modelClass = store.modelFor(modelName)
    const serializer = store.serializerFor(modelName)

    return serializer.normalize(modelClass, relationshipHash, null)
  }
}
