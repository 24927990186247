import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/puzzles/puzzle-lobby-game.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/puzzles/puzzle-lobby-game.hbs"}});
import Component from '@glimmer/component'

export default class PuzzleLobbyGame extends Component {
  /**
   * Activity has signalled to load a puzzle
   * @param {String} puzzleName The name of the puzzle to load
   */
  showPuzzle(puzzleName) {
    this.args.showPuzzleAction(puzzleName)
  }
}
