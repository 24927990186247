import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"offline-screen-component\"\n  {{did-update (action \"updateOnlineCalled\") this.network.status.isOnline}}\n  {{did-update\n    (action \"showOfflineScreen\")\n    this.network.status.isOnline\n    this.transition\n  }}\n  {{did-insert (action \"setupRouterListener\")}}\n  {{will-destroy (action \"teardownRouterListener\")}}\n></div>\n\n{{#if this.offlineScreen.canShowOfflineScreen}}\n  <div class=\"{{style-namespace 'offline-screen'}}\">\n    <FullPageAlert>\n      <h2>\n        Your internet-connection appears to be down\n      </h2>\n    </FullPageAlert>\n  </div>\n{{/if}}", {"contents":"<div\n  class=\"offline-screen-component\"\n  {{did-update (action \"updateOnlineCalled\") this.network.status.isOnline}}\n  {{did-update\n    (action \"showOfflineScreen\")\n    this.network.status.isOnline\n    this.transition\n  }}\n  {{did-insert (action \"setupRouterListener\")}}\n  {{will-destroy (action \"teardownRouterListener\")}}\n></div>\n\n{{#if this.offlineScreen.canShowOfflineScreen}}\n  <div class=\"{{style-namespace 'offline-screen'}}\">\n    <FullPageAlert>\n      <h2>\n        Your internet-connection appears to be down\n      </h2>\n    </FullPageAlert>\n  </div>\n{{/if}}","moduleName":"re-client/components/offline-screen.hbs","parseOptions":{"srcName":"re-client/components/offline-screen.hbs"}});
import { inject as service } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

/**
 * The OfflineScreenComponent is a component which is on the screen at all times.
 * It is generally rendered on the application templates and takes inputs from the network service
 * to determine if we are still in a loading state when waiting for caper interactives to finish their loading.
 *
 * @class OfflineScreenComponent
 */
export default class OfflineScreen extends Component {
  @service
  offlineScreen

  @service('device/network')
  network

  @service
  router

  @tracked transition = null

  /**
   * router handler so we can destroy it properly
   * @param t
   */
  routerHandler(t) {
    this.transition = null
    if (!t.isAborted) this.transition = t
  }

  /**
   * This render modifier uses the network service to listen to changes of the network status.
   * @param element
   * @param isOnline
   */
  @action
  updateOnlineCalled(_element, [isOnline]) {
    if (isOnline) this.offlineScreen.onlineCalled = true
  }

  /**
   * Modifier which determines whether to display the offline screen when the network status or transition changes
   *
   * @param {HTMLElement} element
   * @param {boolean} networkStatus - true for online false for offline
   * @param {Transition} transition - set in the didInsertElement method
   */
  @action
  showOfflineScreen(_element, [networkStatus, transition]) {
    const isFinishedTransition = transition !== null
    const isOffline = networkStatus === false

    // if the online state becomes offline after the transition has finished and is still loading assets
    // then we can set the offlineScreen service to go offline which will show the offline template
    // upon restoration of the connectivity the page will refresh on the very same page.
    if (isOffline && isFinishedTransition) {
      this.offlineScreen.display()
    }
  }

  @action
  setupRouterListener() {
    const routerHandler = this.routerHandler.bind(this)
    this.routerHandlerReference = routerHandler
    this.router.on('routeDidChange', routerHandler)
  }

  @action
  teardownRouterListener() {
    this.router.off('routeDidChange', this.routerHandlerReference, false)
  }
}
