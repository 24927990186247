import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import type ThemeService from 're-client/services/theme'
import type ShopService from 're-client/services/shop'

export default class ShopDepartmentCatalogueController extends Controller {
  @service
  declare router: RouterService

  @service
  declare theme: ThemeService

  @service
  declare shop: ShopService

  @action
  async purchase(item: { id: string }) {
    return this.shop.purchase(item)
  }

  @action
  async purchaseBundle(items: string[]) {
    return this.shop.purchaseBundle(items)
  }

  @action
  async bundlePrice(items: string[]) {
    return this.shop.bundlePrice(items)
  }

  @action
  backToShop() {
    this.router.transitionTo('shop.department')
  }

  @action
  timeToNextMonth() {
    return this.theme.timeToNextTheme()
  }
}
