export default {
  content: {
    images: [
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/decal_27_decal-fp-9da031cf.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_arms_2_arms_arm1-fp-1219d68e.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_arms_2_arms_arm2-fp-de924a32.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_head_4_head-fp-25dc6da2.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_legs_1_legs_leg1-fp-4dca48b7.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_legs_1_legs_leg2-fp-aea52f29.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/default_sunnies_2_sunnies-fp-fd9446c1.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/egg_88_egg-fp-d0830847.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/extra_10_extra-fp-5cf79b03.png',
      'https://assets.static.readingeggs.com/avatar/readingeggs/images/hat_13_hat-fp-e7413bdc.png',
    ],
    variables: {
      avatar: {
        decal_27: {
          decal: {
            x: 0,
            y: 0,
          },
        },
        default_arms_2: {
          arms: {
            arm1: {
              x: 0,
              y: 0,
            },
            arm2: {
              x: 0,
              y: 0,
            },
          },
        },
        default_head_4: {
          head: {
            x: 0,
            y: 0,
          },
        },
        default_legs_1: {
          legs: {
            leg1: {
              x: 0,
              y: 0,
            },
            leg2: {
              x: 0,
              y: 0,
            },
          },
        },
        default_sunnies_2: {
          sunnies: {
            x: 0,
            y: 0,
          },
        },
        egg_88: {
          egg: {
            x: 0,
            y: 0,
          },
        },
        extra_10: {
          extra: {
            x: 0,
            y: 0,
          },
        },
        hat_13: {
          hat: {
            x: 0,
            y: 0,
          },
        },
      },
    },
  },
}
