import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"theme shop nav-item-simple !w-[87px] text-center\">\n  <button\n    data-test-nav-item-wrapper\n    class=\"nav-item-wrapper\"\n    type=\"button\"\n    aria-label=\"{{@title}}\"\n    disabled={{@disabled}}\n    {{hover-wiggle \"svg\"}}\n    {{nav-shop-animation}}\n    {{on \"click\" @onClick}}\n    {{play-sound-on \"click\" \"snap\"}}\n  >\n    {{svg-jar this.themeSvgName}}\n    <div class=\"label-back\">{{@title}}</div>\n  </button>\n</div>", {"contents":"<div class=\"theme shop nav-item-simple !w-[87px] text-center\">\n  <button\n    data-test-nav-item-wrapper\n    class=\"nav-item-wrapper\"\n    type=\"button\"\n    aria-label=\"{{@title}}\"\n    disabled={{@disabled}}\n    {{hover-wiggle \"svg\"}}\n    {{nav-shop-animation}}\n    {{on \"click\" @onClick}}\n    {{play-sound-on \"click\" \"snap\"}}\n  >\n    {{svg-jar this.themeSvgName}}\n    <div class=\"label-back\">{{@title}}</div>\n  </button>\n</div>","moduleName":"re-client/components/home-nav/nav-item-shop.hbs","parseOptions":{"srcName":"re-client/components/home-nav/nav-item-shop.hbs"}});
import Component from '@glimmer/component'

interface NavItemShopSignature {
  Args: {
    disabled?: boolean
    itemName: string
    title: string
    theme: string
  }
  Element: HTMLDivElement
}

export default class NavItemShop extends Component<NavItemShopSignature> {
  get themeSvgName() {
    return `shop-${this.args.theme}`
  }
}
